import React, { useState } from 'react';
import { Undo2 } from 'lucide-react';
import { RestoreDealModal } from './modals/RestoreDealModal';

interface RestoreDealButtonProps {
  dealId: string;
}

export const RestoreDealButton: React.FC<RestoreDealButtonProps> = ({ dealId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="inline-flex items-center px-2 py-1 text-xs font-medium text-indigo-600 hover:text-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
        title="Restore Deal"
      >
        <Undo2 className="w-4 h-4 mr-1" />
        Restore
      </button>

      <RestoreDealModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        dealId={dealId}
      />
    </>
  );
};
