import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "../../lib/firebase";
import { WorkflowDocument, WorkflowTrigger } from "../../types/Workflow";
import { useAuthStore } from '../../store/authStore';
import { usePipeline } from '../../hooks/usePipeline';
import { useTeamStore } from '../../store/teamStore';
import { Button } from '../../components/ui';
import { cn } from '../../lib/utils';
import { User2 } from 'lucide-react';

export const WorkflowEdit: React.FC = () => {
  const [workflow, setWorkflow] = useState<WorkflowDocument | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { user } = useAuthStore();
  const { stages, loading: stagesLoading } = usePipeline();
  const { members, loading: membersLoading, subscribeToMembers } = useTeamStore();

  useEffect(() => {
    const unsubscribeMembers = subscribeToMembers();
    return () => unsubscribeMembers();
  }, [subscribeToMembers]);

  useEffect(() => {
    const fetchWorkflow = async () => {
      // Check user permissions
      if (!user) {
        setError('You must be logged in to edit workflows');
        setLoading(false);
        return;
      }

      if (!id) {
        setError('No workflow ID provided');
        setLoading(false);
        return;
      }

      try {
        const workflowRef = doc(db, 'workflows', id);
        const workflowSnap = await getDoc(workflowRef);

        if (!workflowSnap.exists()) {
          setError('Workflow not found');
          setLoading(false);
          return;
        }

        const workflowData = workflowSnap.data() as WorkflowDocument;

        // Check if user has permission to edit this workflow
        if (workflowData.organizationId !== user.organizationId) {
          setError('You do not have permission to edit this workflow');
          setLoading(false);
          return;
        }

        setWorkflow({
          id: workflowSnap.id,
          ...workflowData
        });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching workflow:', err);
        setError('Failed to fetch workflow');
        setLoading(false);
      }
    };

    fetchWorkflow();
  }, [id, user]);

  const handleSave = async () => {
    if (!workflow || !user) return;

    try {
      const workflowRef = doc(db, 'workflows', workflow.id);
      await updateDoc(workflowRef, {
        ...workflow,
        updatedAt: Timestamp.now()
      });

      alert('Workflow updated successfully');
      navigate('/workflows');
    } catch (error) {
      console.error('Error updating workflow:', error);
      alert('Failed to update workflow');
    }
  };

  const updateTriggerStage = (stageId: string | null) => {
    if (!workflow) return;

    // Update or create triggers based on selected stage
    const updatedTriggers: WorkflowTrigger[] = stageId 
      ? [{
          id: 'stage-trigger',
          type: 'deal_stage_change',
          conditions: [{
            sourcePipelineStageId: stageId
          }]
        }]
      : [];

    setWorkflow({
      ...workflow,
      dealStageId: stageId || undefined,
      triggers: updatedTriggers
    });
  };

  const addTask = () => {
    if (!workflow) return;
    setWorkflow({
      ...workflow,
      tasks: [...(workflow.tasks || []), {
        name: '',
        relativeDay: 0,
        assigneeId: '',
        priority: 'medium'
      }]
    });
  };

  const updateTask = (index: number, field: 'name' | 'relativeDay' | 'assigneeId' | 'priority', value: string | number) => {
    if (!workflow) return;
    const updatedTasks = [...(workflow.tasks || [])];
    updatedTasks[index] = {
      ...updatedTasks[index],
      [field]: value
    };
    setWorkflow({
      ...workflow,
      tasks: updatedTasks
    });
  };

  const removeTask = (index: number) => {
    if (!workflow) return;
    const updatedTasks = [...(workflow.tasks || [])];
    updatedTasks.splice(index, 1);
    setWorkflow({
      ...workflow,
      tasks: updatedTasks
    });
  };

  if (loading || stagesLoading || membersLoading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!workflow) return <div>No workflow found</div>;

  return (
    <div className="min-h-screen bg-navy-950 px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-navy-900 rounded-lg shadow-xl border border-navy-700">
          <div className="px-6 py-4 border-b border-navy-700">
            <h1 className="text-xl font-semibold text-white">Edit Workflow</h1>
          </div>
          
          <div className="p-6 space-y-6">
            <div className="form-group">
              <label 
                htmlFor="workflowName" 
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Workflow Name
              </label>
              <input
                id="workflowName"
                type="text"
                value={workflow.name}
                onChange={(e) => setWorkflow(prev => prev ? {...prev, name: e.target.value} : null)}
                className="w-full px-4 py-2 bg-navy-800 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-500"
              />
            </div>

            <div className="form-group">
              <label 
                htmlFor="triggerStage" 
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Trigger Deal Stage (Optional)
              </label>
              <select
                id="triggerStage"
                value={workflow.dealStageId || ''}
                onChange={(e) => updateTriggerStage(e.target.value || null)}
                className="w-full px-4 py-2 bg-navy-800 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">Select a stage (optional)</option>
                {stages.map(stage => (
                  <option key={stage.id} value={stage.id}>
                    {stage.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <div className="flex justify-between items-center mb-4">
                <label className="block text-sm font-medium text-gray-300">
                  Workflow Tasks
                </label>
                <Button
                  onClick={addTask}
                  variant="secondary"
                  size="sm"
                  className="flex items-center gap-2 bg-navy-700 hover:bg-navy-600 text-white"
                >
                  <span>Add Task</span>
                </Button>
              </div>
              
              <div className="space-y-3">
                {workflow.tasks?.map((task, index) => (
                  <div 
                    key={index} 
                    className="flex items-center gap-4 p-4 bg-navy-800 rounded-lg border border-navy-600"
                  >
                    <div className="flex-1">
                      <input
                        type="text"
                        value={task.name}
                        onChange={(e) => updateTask(index, 'name', e.target.value)}
                        placeholder="Task name"
                        className="w-full px-4 py-2 bg-navy-700 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent placeholder-gray-500"
                      />
                    </div>
                    <div className="w-32">
                      <input
                        type="number"
                        value={task.relativeDay}
                        onChange={(e) => updateTask(index, 'relativeDay', parseInt(e.target.value))}
                        className="w-full px-4 py-2 bg-navy-700 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                    <div className="w-48">
                      <select
                        value={task.assigneeId || ''}
                        onChange={(e) => updateTask(index, 'assigneeId', e.target.value)}
                        className="w-full px-4 py-2 bg-navy-700 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      >
                        <option value="">Select Assignee</option>
                        {members.map(member => (
                          <option key={member.uid} value={member.uid}>
                            {member.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-32">
                      <select
                        value={task.priority || 'medium'}
                        onChange={(e) => updateTask(index, 'priority', e.target.value)}
                        className={cn(
                          "w-full px-4 py-2 bg-navy-700 text-white rounded-md border border-navy-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent",
                          {
                            'text-red-400': task.priority === 'high',
                            'text-amber-400': task.priority === 'medium',
                            'text-emerald-400': task.priority === 'low',
                          }
                        )}
                      >
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </select>
                    </div>
                    <Button
                      onClick={() => removeTask(index)}
                      variant="destructive"
                      size="sm"
                      className="flex-shrink-0 bg-red-900/50 hover:bg-red-900 text-red-200"
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                {(!workflow.tasks || workflow.tasks.length === 0) && (
                  <p className="text-gray-500 text-sm py-4 text-center">No tasks added yet</p>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-4 pt-4 border-t border-navy-700">
              <Button
                onClick={() => navigate('/workflows')}
                variant="outline"
                className="border-navy-600 text-gray-300 hover:bg-navy-800"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                variant="default"
                className="bg-blue-600 hover:bg-blue-500 text-white"
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
