import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  collection, 
  getDocs, 
  deleteDoc, 
  doc, 
  query, 
  where,
  Timestamp
} from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { WorkflowDocument } from '../../types/Workflow';
import { useAuthStore } from '../../store/authStore';
import { Edit as EditIcon, Trash2 as TrashIcon, Plus as PlusIcon } from 'lucide-react';

// Helper function to convert various date formats to a readable string
const formatDate = (dateOrTimestamp: any): string => {
  if (dateOrTimestamp instanceof Timestamp) {
    return dateOrTimestamp.toDate().toLocaleDateString();
  }
  
  if (dateOrTimestamp instanceof Date) {
    return dateOrTimestamp.toLocaleDateString();
  }
  
  if (typeof dateOrTimestamp === 'string') {
    const date = new Date(dateOrTimestamp);
    return !isNaN(date.getTime()) ? date.toLocaleDateString() : 'Invalid Date';
  }
  
  return 'N/A';
};

export const WorkflowList: React.FC = () => {
  const [workflows, setWorkflows] = useState<WorkflowDocument[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuthStore();

  useEffect(() => {
    const fetchWorkflows = async () => {
      if (!user) {
        setError('No user found');
        setLoading(false);
        return;
      }

      try {
        const workflowsRef = collection(db, 'workflows');
        const q = query(
          workflowsRef, 
          where('organizationId', '==', user.organizationId)
        );

        const querySnapshot = await getDocs(q);
        const fetchedWorkflows = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as WorkflowDocument));

        setWorkflows(fetchedWorkflows);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching workflows:', err);
        setError('Failed to fetch workflows');
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, [user]);

  const deleteWorkflow = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this workflow?')) {
      try {
        await deleteDoc(doc(db, 'workflows', id));
        setWorkflows(workflows.filter(workflow => workflow.id !== id));
        alert('Workflow deleted successfully');
      } catch (error) {
        console.error('Error deleting workflow:', error);
        alert('Failed to delete workflow');
      }
    }
  };

  if (loading) return <div className="text-white">Loading workflows...</div>;
  if (error) return <div className="text-red-400">Error: {error}</div>;

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">Workflows</h1>
        <button 
          onClick={() => navigate('/workflows/create')} 
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <PlusIcon className="mr-2" size={20} /> Create Workflow
        </button>
      </div>

      {workflows.length === 0 ? (
        <div className="text-center text-gray-400">
          No workflows found. Create your first workflow!
        </div>
      ) : (
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {workflows.map(workflow => (
            <div 
              key={workflow.id} 
              className="bg-navy-800/50 border border-navy-700 rounded-lg p-4 hover:bg-navy-700/50 transition-colors"
            >
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-semibold text-white">{workflow.name}</h2>
                <div className="flex space-x-2">
                  <button 
                    onClick={() => navigate(`/workflows/edit/${workflow.id}`)}
                    className="text-blue-400 hover:text-blue-300 transition-colors"
                  >
                    <EditIcon size={20} />
                  </button>
                  <button 
                    onClick={() => deleteWorkflow(workflow.id)}
                    className="text-red-400 hover:text-red-300 transition-colors"
                  >
                    <TrashIcon size={20} />
                  </button>
                </div>
              </div>
              <p className="text-gray-400">
                Created: {formatDate(workflow.createdAt)}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
