import React, { useState, useEffect, useMemo } from 'react';
import { useKanbanStore } from '../../store/kanbanStore';
import { useTasksStore } from '../../store/tasksStore';
import { useActivitiesStore } from '../../store/activitiesStore';
import { useTeamStore } from '../../store/teamStore';
import { useAuthStore } from '../../store/authStore';
import { format, subDays, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { BarChart3, Clock, AlertCircle, TrendingUp, Filter, X, Activity, CheckCircle2 } from 'lucide-react';
import { Deal, DealActivity, DealTask } from '../../types/deal';

type DateRange = '7' | '30' | '90' | 'all';

interface DealInsight {
  dealId: string;
  dealTitle: string;
  dealOwner: string;
  stage: string;
  value: number;
  activityCount: number;
  completedTaskCount: number;
  incompleteTaskCount: number;
  lastActivityDate: Date | null;
  engagementScore: number;
}

export const ActivityInsights = () => {
  const [dateRange, setDateRange] = useState<DateRange>('30');
  const [selectedOwnerId, setSelectedOwnerId] = useState<string>('all');
  const { user } = useAuthStore();
  const { members } = useTeamStore();
  const { deals, subscribeToDeals } = useKanbanStore();
  const { tasks, subscribeToAllTasks } = useTasksStore();
  const { activities, subscribeToAllActivities } = useActivitiesStore();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const unsubscribeDeals = subscribeToDeals();
    const unsubscribeTasks = subscribeToAllTasks();
    const unsubscribeActivities = subscribeToAllActivities();
    return () => {
      unsubscribeDeals();
      unsubscribeTasks();
      unsubscribeActivities();
    };
  }, [subscribeToDeals, subscribeToAllTasks, subscribeToAllActivities]);

  const filteredData = useMemo(() => {
    if (!user?.organizationId) return [];

    const startDate = dateRange !== 'all' 
      ? startOfDay(subDays(new Date(), parseInt(dateRange)))
      : null;
    const endDate = dateRange !== 'all' ? endOfDay(new Date()) : null;

    const filteredDeals = deals.filter(deal => {
      if (selectedOwnerId !== 'all' && deal.ownerId !== selectedOwnerId) return false;
      return true;
    });

    const dealInsights: DealInsight[] = filteredDeals.map(deal => {
      const dealTasks = tasks.filter(task => task.dealId === deal.id);
      const dealActivities = activities.filter(activity => activity.dealId === deal.id);

      // Filter by date range if specified
      const filteredTasks = dateRange !== 'all'
        ? dealTasks.filter(task => {
            const taskDate = task.createdAt instanceof Date 
              ? task.createdAt 
              : new Date(task.createdAt);
            return isWithinInterval(taskDate, { start: startDate!, end: endDate! });
          })
        : dealTasks;

      const filteredActivities = dateRange !== 'all'
        ? dealActivities.filter(activity => {
            if (!activity.createdAt) return false;
            const activityDate = activity.createdAt instanceof Date 
              ? activity.createdAt 
              : new Date(activity.createdAt);
            return isWithinInterval(activityDate, { start: startDate!, end: endDate! });
          })
        : dealActivities;

      const completedTasks = filteredTasks.filter(task => task.status === 'completed');
      const incompleteTasks = filteredTasks.filter(task => task.status !== 'completed');
      
      // Sort activities by date and get the most recent one
      const sortedActivities = filteredActivities
        .filter(activity => activity.createdAt)
        .sort((a, b) => {
          const dateA = a.createdAt instanceof Date ? a.createdAt : new Date(a.createdAt);
          const dateB = b.createdAt instanceof Date ? b.createdAt : new Date(b.createdAt);
          return dateB.getTime() - dateA.getTime();
        });

      const lastActivity = sortedActivities[0];
      const lastActivityDate = lastActivity?.createdAt 
        ? (lastActivity.createdAt instanceof Date 
            ? lastActivity.createdAt 
            : new Date(lastActivity.createdAt))
        : null;

      // Calculate engagement score (weighted sum of activities and tasks)
      const engagementScore = 
        filteredActivities.length * 2 + // Each activity counts as 2 points
        completedTasks.length * 3 +     // Each completed task counts as 3 points
        incompleteTasks.length * 1;     // Each incomplete task counts as 1 point

      return {
        dealId: deal.id,
        dealTitle: deal.title,
        dealOwner: deal.ownerName || 'Unassigned',
        stage: deal.stage,
        value: deal.value,
        activityCount: filteredActivities.length,
        completedTaskCount: completedTasks.length,
        incompleteTaskCount: incompleteTasks.length,
        lastActivityDate,
        engagementScore
      };
    });

    // Sort by engagement score (descending)
    return dealInsights.sort((a, b) => b.engagementScore - a.engagementScore);
  }, [deals, tasks, activities, dateRange, selectedOwnerId, user?.organizationId]);

  const summaryMetrics = useMemo(() => {
    const totalDeals = filteredData.length;
    const totalActivities = filteredData.reduce((sum, deal) => sum + deal.activityCount, 0);
    const totalCompletedTasks = filteredData.reduce((sum, deal) => sum + deal.completedTaskCount, 0);
    const totalIncompleteTasks = filteredData.reduce((sum, deal) => sum + deal.incompleteTaskCount, 0);
    const totalTasks = totalCompletedTasks + totalIncompleteTasks;
    const taskCompletionRate = totalTasks > 0 
      ? (totalCompletedTasks / totalTasks) * 100 
      : 0;

    return {
      totalDeals,
      totalActivities,
      totalCompletedTasks,
      totalIncompleteTasks,
      taskCompletionRate
    };
  }, [filteredData]);

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Activity Insights</h1>
        <p className="text-gray-400">Monitor deal engagement and task completion across your pipeline</p>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-8">
        <select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value as DateRange)}
          className="bg-navy-700 text-white border border-navy-600 rounded-md px-4 py-2"
        >
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
          <option value="all">All Time</option>
        </select>

        <select
          value={selectedOwnerId}
          onChange={(e) => setSelectedOwnerId(e.target.value)}
          className="bg-navy-700 text-white border border-navy-600 rounded-md px-4 py-2"
        >
          <option value="all">All Deal Owners</option>
          {members.map(member => (
            <option key={member.uid} value={member.uid}>
              {member.displayName || member.email}
            </option>
          ))}
        </select>
      </div>

      {/* Summary Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-6 mb-8">
        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <BarChart3 className="w-5 h-5 text-blue-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Total Deals</h3>
          </div>
          <p className="text-3xl font-bold text-white">{summaryMetrics.totalDeals}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <Activity className="w-5 h-5 text-purple-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Activities</h3>
          </div>
          <p className="text-3xl font-bold text-white">{summaryMetrics.totalActivities}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Completed Tasks</h3>
          </div>
          <p className="text-3xl font-bold text-white">{summaryMetrics.totalCompletedTasks}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <Clock className="w-5 h-5 text-orange-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Pending Tasks</h3>
          </div>
          <p className="text-3xl font-bold text-white">{summaryMetrics.totalIncompleteTasks}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <TrendingUp className="w-5 h-5 text-teal-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Task Completion</h3>
          </div>
          <p className="text-3xl font-bold text-white">
            {summaryMetrics.taskCompletionRate.toFixed(1)}%
          </p>
        </div>
      </div>

      {/* Deal Insights Table */}
      <div className="bg-navy-800 rounded-lg border border-navy-600 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-navy-700">
                <th className="px-6 py-3 text-left text-sm font-semibold text-white">Deal</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-white">Owner</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-white">Stage</th>
                <th className="px-6 py-3 text-right text-sm font-semibold text-white">Value</th>
                <th className="px-6 py-3 text-center text-sm font-semibold text-white">Activities</th>
                <th className="px-6 py-3 text-center text-sm font-semibold text-white">Tasks (Done/Total)</th>
                <th className="px-6 py-3 text-left text-sm font-semibold text-white">Last Activity</th>
                <th className="px-6 py-3 text-center text-sm font-semibold text-white">Engagement</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-navy-600">
              {filteredData.map((deal) => (
                <tr key={deal.dealId} className="hover:bg-navy-700/50">
                  <td className="px-6 py-4 text-white">{deal.dealTitle}</td>
                  <td className="px-6 py-4 text-gray-300">{deal.dealOwner}</td>
                  <td className="px-6 py-4 text-gray-300">{deal.stage}</td>
                  <td className="px-6 py-4 text-right text-gray-300">
                    ${deal.value.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 text-center text-gray-300">{deal.activityCount}</td>
                  <td className="px-6 py-4 text-center text-gray-300">
                    {deal.completedTaskCount}/{deal.completedTaskCount + deal.incompleteTaskCount}
                  </td>
                  <td className="px-6 py-4 text-gray-300">
                    {deal.lastActivityDate 
                      ? format(deal.lastActivityDate, 'MMM d, yyyy')
                      : 'No activity'}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <span 
                      className={`px-2 py-1 rounded text-sm font-medium
                        ${deal.engagementScore >= 15 ? 'bg-green-500/20 text-green-500' :
                          deal.engagementScore >= 8 ? 'bg-blue-500/20 text-blue-500' :
                          deal.engagementScore >= 4 ? 'bg-yellow-500/20 text-yellow-500' :
                          'bg-red-500/20 text-red-500'}`}
                    >
                      {deal.engagementScore}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
