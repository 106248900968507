import React, { useMemo } from 'react';
import { KanbanCard } from './KanbanCard';
import { useDroppable } from '@dnd-kit/core';
import { 
  SortableContext, 
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Deal } from '../../types/deals';
import { Stage } from '../../types/pipeline';

interface KanbanColumnProps {
  stage: Stage;
  deals: Deal[];
}

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ stage, deals }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: stage.id,
    data: { 
      stage: stage.id,
      type: 'column'
    },
  });

  const totalValue = deals.reduce((sum, deal) => sum + (deal.value || 0), 0);

  // Sort deals by their order property if it exists, otherwise maintain current order
  const sortedDeals = useMemo(() => {
    return [...deals].sort((a, b) => {
      const orderA = a.order ?? Number.MAX_SAFE_INTEGER;
      const orderB = b.order ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }, [deals]);

  console.log('Column Deals:', {
    stageId: stage.id,
    stageName: stage.name,
    originalDeals: deals.map(deal => ({
      id: deal.id,
      title: deal.title,
      stageId: deal.stageId,
      stage: deal.stage,
      stageName: deal.stageName,
      order: deal.order
    })),
    sortedDeals: sortedDeals.map(deal => ({
      id: deal.id,
      title: deal.title,
      stageId: deal.stageId,
      stage: deal.stage,
      stageName: deal.stageName,
      order: deal.order
    }))
  });

  return (
    <div 
      ref={setNodeRef}
      className={`flex flex-col w-[300px] min-w-[300px] h-[600px] transition-all duration-150 ${
        isOver ? 'ring-2 ring-blue-500 ring-opacity-100 bg-navy-700/20' : ''
      }`}
    >
      <div className="mb-3 px-3 py-2 bg-navy-800 rounded-lg flex items-center justify-between shadow-sm">
        <div className="flex items-center space-x-2 min-w-0">
          <h3 
            className="font-semibold text-white text-[15px] truncate"
            title={stage.name}
          >
            {stage.name}
          </h3>
          <div className="flex-shrink-0 flex items-center justify-center min-w-[24px] h-6 px-2 bg-navy-700/50 rounded-md">
            <span className="text-xs font-medium text-gray-300">{deals.length} deals</span>
          </div>
        </div>
        <span className="flex-shrink-0 text-sm font-medium text-indigo-300">${totalValue.toLocaleString()}</span>
      </div>
      <SortableContext items={sortedDeals.map(deal => deal.id)} strategy={verticalListSortingStrategy}>
        <div className="flex-1 flex flex-col gap-2 p-2 bg-navy-800 rounded-lg overflow-y-auto">
          {sortedDeals.map((deal) => (
            <KanbanCard 
              key={deal.id} 
              deal={deal} 
              stage={stage.id}
              index={deal.order ?? sortedDeals.indexOf(deal)}
              deals={sortedDeals}
            />
          ))}
          {sortedDeals.length === 0 && (
            <div className={`h-full flex items-center justify-center border-2 border-dashed ${
              isOver ? 'border-blue-500 bg-navy-700/50' : 'border-navy-600'
            } rounded-lg transition-all duration-150`}>
              <span className="text-sm text-gray-400">Drop deals here</span>
            </div>
          )}
        </div>
      </SortableContext>
    </div>
  );
};