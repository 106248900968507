import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';

interface UpdateNote {
  id: string;
  date: string;
  title: string;
  description: string;
  details: string[];
}

const updateNotes: UpdateNote[] = [
  {
    id: '9d09f66',
    date: 'January 2, 2025',
    title: 'Notification System and Task Management',
    description: 'Comprehensive notification system and task improvements',
    details: [
      'Implemented user mention notifications in deal notes',
      'Added notification system for user mentions and activities',
      'Enhanced task creation in both deals and activity feed',
      'Made tasks work independently of deals for better flexibility',
      'Fixed task creation issues and improved error handling',
      'Added proper date formatting for task due dates'
    ]
  },
  {
    id: '3541833',
    date: 'December 31, 2024',
    title: 'Landing Page and UI Enhancements',
    description: 'Major updates to landing page and overall UI improvements',
    details: [
      'Added comprehensive pricing page with modern design',
      'Enhanced hero section layout with improved animations',
      'Updated Tailwind configuration for better styling',
      'Added CountUp animations for dynamic number displays',
      'Improved overall landing page user experience'
    ]
  },
  {
    id: 'c305395',
    date: 'December 31, 2024',
    title: 'Reports and Store Enhancements',
    description: 'Improved reporting system and store functionality',
    details: [
      'Added new report components for better analytics',
      'Enhanced store functionality with improved state management',
      'Updated workflow trigger system',
      'Improved deal reordering with explicit order management',
      'Fixed UI component import paths for better stability',
      'Enhanced settings UI with dark theme optimizations'
    ]
  },
  {
    id: '488146e',
    date: 'December 31, 2024',
    title: 'Pipeline Stage Management',
    description: 'Fixed permission issues and improved loading UX',
    details: [
      'Fixed permission issues in Firestore rules',
      'Added write permissions for pipelines collection',
      'Allows organization members to modify pipeline stages',
      'Removed default stages flash on initial load',
      'Added loading state management in pipelineStore',
      'Added loading placeholder in KanbanBoard'
    ]
  },
  {
    id: '2eeba5a',
    date: 'December 28, 2024',
    title: 'Legal Pages and Public Layout',
    description: 'Added legal pages and improved public layout',
    details: [
      'Added Terms of Service and Privacy Policy pages',
      'Created reusable PublicLayout component',
      'Updated routing configuration for public pages',
      'Added legal links to sign-up form and footer',
      'Implemented navy color scheme with proper contrast',
      'Added backdrop blur effects for modern look'
    ]
  },
  {
    id: 'd35ff8a',
    date: 'December 24, 2024',
    title: 'Deal Management Features',
    description: 'Added Top Deals section and sharing functionality',
    details: [
      'Added Top Deals section showing high-value opportunities',
      'Implemented deal sharing via URL functionality',
      'Added share button to deal cards and details modal',
      'Added visual feedback for share actions',
      'Improved deal card interactions and hover effects'
    ]
  },
  {
    id: '29aa84d',
    date: 'December 24, 2024',
    title: 'Workflow Management System',
    description: 'Added comprehensive workflow management',
    details: [
      'Added workflow pages for list, create, and edit',
      'Implemented workflow trigger service',
      'Added workflow navigation and UI components',
      'Created workflow types and interfaces',
      'Updated navigation icons for better clarity'
    ]
  },
  {
    id: '9eda04b',
    date: 'December 24, 2024',
    title: 'Task Organization',
    description: 'Enhanced task management system',
    details: [
      'Added task categories: Overdue, Today, This Week, Upcoming',
      'Improved task completion handling',
      'Enhanced date handling in task forms',
      'Added task sorting by date and priority',
      'Implemented collapsible task groups'
    ]
  }
];

export const AboutTab: React.FC = () => {
  const [expandedNotes, setExpandedNotes] = useState<string[]>([updateNotes[0].id]);

  const toggleNote = (noteId: string) => {
    setExpandedNotes(prev => 
      prev.includes(noteId)
        ? prev.filter(id => id !== noteId)
        : [...prev, noteId]
    );
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-white mb-2">About CRM MVP</h2>
        <p className="text-gray-400">Version 1.3.0</p>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-white">Recent Updates</h3>
        <div className="space-y-2">
          {updateNotes.map((note) => (
            <div
              key={note.id}
              className="bg-navy-800 rounded-lg overflow-hidden"
            >
              <button
                onClick={() => toggleNote(note.id)}
                className="w-full px-4 py-3 flex items-center justify-between hover:bg-navy-700 transition-colors"
              >
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-white">
                      {note.title}
                    </span>
                    <span className="text-xs text-gray-400 ml-2">
                      {note.date}
                    </span>
                  </div>
                  <p className="text-sm text-gray-400 text-left mt-1">
                    {note.description}
                  </p>
                </div>
                {expandedNotes.includes(note.id) ? (
                  <ChevronDown className="w-5 h-5 text-gray-400 ml-4 flex-shrink-0" />
                ) : (
                  <ChevronRight className="w-5 h-5 text-gray-400 ml-4 flex-shrink-0" />
                )}
              </button>
              {expandedNotes.includes(note.id) && (
                <div className="px-4 pb-3">
                  <ul className="list-disc list-inside space-y-1">
                    {note.details.map((detail, index) => (
                      <li key={index} className="text-sm text-gray-400">
                        {detail}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTab;
