import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Mail, 
  Calendar, 
  MessageSquare, 
  FileText, 
  Lock,
  ExternalLink,
  PlusCircle,
  Copy,
  RefreshCw,
  Code
} from 'lucide-react';
import { auth } from '../../lib/firebase';
import { toast } from 'react-hot-toast';

interface Integration {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  status: 'connected' | 'not_connected';
  isComingSoon?: boolean;
}

const integrations: Integration[] = [
  {
    id: 'gmail',
    name: 'Gmail',
    description: 'Sync your emails and track conversations with clients',
    icon: <Mail className="w-6 h-6" />,
    status: 'not_connected',
    isComingSoon: true
  },
  {
    id: 'gcal',
    name: 'Google Calendar',
    description: 'Schedule meetings and sync your calendar events',
    icon: <Calendar className="w-6 h-6" />,
    status: 'not_connected',
    isComingSoon: true
  },
  {
    id: 'slack',
    name: 'Slack',
    description: 'Get notifications and updates in your Slack channels',
    icon: <MessageSquare className="w-6 h-6" />,
    status: 'not_connected',
    isComingSoon: true
  },
  {
    id: 'docusign',
    name: 'DocuSign',
    description: 'Send and track documents for electronic signatures',
    icon: <FileText className="w-6 h-6" />,
    status: 'not_connected',
    isComingSoon: true
  }
];

export const IntegrationsSettings = () => {
  const [apiToken, setApiToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showToken, setShowToken] = useState(false);

  const generateNewToken = async () => {
    try {
      setIsLoading(true);
      const token = await auth.currentUser?.getIdToken(true);
      setApiToken(token || '');
      toast.success('New API token generated');
    } catch (error) {
      toast.error('Failed to generate token');
      console.error('Error generating token:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  useEffect(() => {
    generateNewToken();
  }, []);

  return (
    <div className="flex-1 space-y-6">
      <div>
        <h1 className="text-2xl font-semibold text-white mb-1">Integrations</h1>
      </div>

      <div className="space-y-6">
        {/* Available Integrations */}
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Lock className="w-5 h-5 text-gray-400" />
            <h2 className="text-lg font-medium text-white">Available Integrations</h2>
          </div>
          <p className="text-sm text-gray-400">
            Connect your favorite tools to enhance your workflow
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {integrations.map((integration) => (
            <div
              key={integration.id}
              className="bg-white/5 backdrop-blur-sm rounded-lg p-6 relative group"
            >
              <div className="absolute top-4 right-4">
                <span className="px-2 py-1 text-xs font-medium text-amber-300 bg-amber-500/20 rounded-full">
                  Coming Soon
                </span>
              </div>
              <div className="flex items-start gap-4">
                <div className="p-2 bg-white/10 rounded-lg">
                  {integration.icon}
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-medium text-white flex items-center gap-2">
                    {integration.name}
                    <ExternalLink className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </h3>
                  <p className="mt-1 text-sm text-gray-400">
                    {integration.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
          <div className="text-center">
            <h3 className="text-lg font-medium text-white">Need a different integration?</h3>
            <p className="mt-1 text-sm text-gray-400">
              We're constantly adding new integrations. Let us know what you need!
            </p>
            <Link
              to="#"
              className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Request Integration
            </Link>
          </div>
        </div>

        {/* API Token Section */}
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Lock className="w-5 h-5 text-gray-400" />
            <h2 className="text-lg font-medium text-white">API Token</h2>
          </div>
          <p className="text-sm text-gray-400">
            Use this token to authenticate your API requests
          </p>
        </div>

        <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6 space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-base font-medium text-white">Your API Token</h3>
              <p className="text-sm text-gray-400">
                Keep this token secure and never share it publicly
              </p>
            </div>
            <button
              onClick={generateNewToken}
              disabled={isLoading}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              <RefreshCw className={`w-4 h-4 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
              Regenerate Token
            </button>
          </div>

          <div className="flex items-center gap-2">
            <code className="flex-1 p-3 bg-navy-800/50 rounded-lg text-gray-300 font-mono text-sm break-all">
              {showToken ? apiToken || 'Loading...' : '••••••••••••••••••••••••••••••'}
            </code>
            <button
              onClick={() => setShowToken(!showToken)}
              className="p-2 text-gray-400 hover:text-white"
            >
              {showToken ? 'Hide' : 'Show'}
            </button>
            <button
              onClick={() => copyToClipboard(apiToken)}
              className="p-2 text-gray-400 hover:text-white"
              title="Copy to clipboard"
            >
              <Copy className="w-4 h-4" />
            </button>
          </div>

          {/* API Documentation Preview */}
          <div className="mt-6 space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-base font-medium text-white">Example Request</h3>
                <p className="text-sm text-gray-400">
                  Here's how to use your API token in requests
                </p>
              </div>
            </div>

            <div className="bg-navy-800/50 rounded-lg p-4">
              <div className="space-y-2">
                <div>
                  <h4 className="text-sm font-medium text-gray-400">Headers:</h4>
                  <pre className="mt-1 text-sm text-gray-300 font-mono">
{`{
  "Authorization": "Bearer YOUR_API_TOKEN",
  "Content-Type": "application/json"
}`}
                  </pre>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-400">Body Example:</h4>
                  <pre className="mt-1 text-sm text-gray-300 font-mono">
{`{
  "customFields": [
    {
      "fieldId": "custom_field_id",
      "value": "field value"
    }
  ]
}`}
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* API Documentation Link */}
        <div className="bg-white/5 backdrop-blur-sm rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-medium text-white">API Documentation</h3>
              <p className="mt-1 text-sm text-gray-400">
                View our comprehensive API documentation to learn how to integrate with our API.
              </p>
            </div>
            <Link
              to="/settings/api-docs"
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Code className="w-4 h-4 mr-2" />
              View API Documentation
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsSettings;
