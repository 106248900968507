import { create } from 'zustand';
import type { Notification, NotificationType } from '../types/notification';
import { collection, query, where, orderBy, onSnapshot, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from './authStore';
import toast from 'react-hot-toast';
import { createElement } from 'react';
import { NotificationToast } from '../components/notifications/NotificationToast';
import { notificationSound } from '../services/notificationSound';

interface NotificationsState {
  notifications: Notification[];
  unreadCount: number;
  loading: boolean;
  error: string | null;
  soundEnabled: boolean;
  browserNotificationsEnabled: boolean;
  subscribeToNotifications: () => void;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  createNotification: (notification: Omit<Notification, 'id' | 'createdAt' | 'read'>) => Promise<void>;
  toggleSound: () => void;
  toggleBrowserNotifications: () => void;
}

export const useNotificationsStore = create<NotificationsState>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  loading: false,
  error: null,
  soundEnabled: true,
  browserNotificationsEnabled: false,

  toggleSound: () => {
    set(state => ({ soundEnabled: !state.soundEnabled }));
  },

  toggleBrowserNotifications: async () => {
    const { browserNotificationsEnabled } = get();
    if (!browserNotificationsEnabled) {
      const permission = await Notification.requestPermission();
      set({ browserNotificationsEnabled: permission === 'granted' });
    } else {
      set({ browserNotificationsEnabled: false });
    }
  },

  subscribeToNotifications: () => {
    const userId = useAuthStore.getState().user?.uid;
    if (!userId) return () => {};

    set({ loading: true });

    try {
      const q = query(
        collection(db, 'notifications'),
        where('mentionedUser', '==', userId),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(
        q,
        {
          next: (snapshot) => {
            const notifications = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt?.toDate() || new Date(),
            })) as Notification[];

            // Show toast for new notifications
            snapshot.docChanges().forEach((change) => {
              if (change.type === 'added') {
                const notification = {
                  id: change.doc.id,
                  ...change.doc.data(),
                  createdAt: change.doc.data().createdAt?.toDate() || new Date(),
                } as Notification;
                
                // Only show toast for new notifications, not initial load
                if (Date.now() - notification.createdAt.getTime() < 1000) {
                  // Show toast notification
                  toast.custom((t) => 
                    createElement(NotificationToast, {
                      notificationType: notification.type,
                      title: notification.title,
                      message: notification.message,
                      visible: t.visible,
                    })
                  );

                  // Play sound if enabled
                  const state = get();
                  if (state.soundEnabled) {
                    notificationSound.play().catch(console.error);
                  }

                  // Show browser notification if enabled
                  if (state.browserNotificationsEnabled) {
                    try {
                      new Notification(notification.title, {
                        body: notification.message,
                        icon: '/notification-icon.png'
                      });
                    } catch (error) {
                      console.error('Browser notification failed:', error);
                    }
                  }
                }
              }
            });

            const unreadCount = notifications.filter(n => !n.read).length;

            set({
              notifications,
              unreadCount,
              loading: false,
              error: null,
            });
          },
          error: (error) => {
            console.error('Notification subscription error:', error);
            set({ error: error.message, loading: false });
          },
        }
      );

      return () => {
        try {
          unsubscribe();
        } catch (error) {
          console.error('Error unsubscribing from notifications:', error);
        }
      };
    } catch (error) {
      console.error('Error setting up notification subscription:', error);
      set({ error: (error as Error).message, loading: false });
      return () => {};
    }
  },

  markAsRead: async (notificationId: string) => {
    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      await updateDoc(notificationRef, { read: true });
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },

  markAllAsRead: async () => {
    try {
      const { notifications } = get();
      const unreadNotifications = notifications.filter(n => !n.read);
      
      await Promise.all(
        unreadNotifications.map(notification =>
          updateDoc(doc(db, 'notifications', notification.id), { read: true })
        )
      );
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },

  createNotification: async (notification) => {
    try {
      await addDoc(collection(db, 'notifications'), {
        ...notification,
        createdAt: new Date(),
        read: false,
      });
    } catch (error) {
      set({ error: (error as Error).message });
    }
  },
}));
