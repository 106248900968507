import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, User, DollarSign, Activity } from 'lucide-react';
import { useKanbanStore } from '../../store/kanbanStore';
import { useTeamStore } from '../../store/teamStore';
import { useActivitiesStore } from '../../store/activitiesStore';

interface SearchResult {
  id: string;
  type: 'deal' | 'contact' | 'activity';
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  path: string;
}

export const SearchBar: React.FC = () => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const deals = useKanbanStore(state => state.deals) || [];
  const team = useTeamStore(state => state.members) || [];
  const activities = useActivitiesStore(state => state.activities) || [];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (!query) {
      setResults([]);
      return;
    }

    const lowerQuery = query.toLowerCase();
    const searchResults: SearchResult[] = [];

    // Search deals (cards) and their contacts
    deals.forEach(deal => {
      const matchTitle = deal.title?.toLowerCase().includes(lowerQuery);
      const matchContact = deal.contact?.name?.toLowerCase().includes(lowerQuery) ||
                         deal.contact?.email?.toLowerCase().includes(lowerQuery);
      const matchCompany = deal.company?.toLowerCase().includes(lowerQuery);

      if (matchTitle || matchContact || matchCompany) {
        searchResults.push({
          id: deal.id,
          type: 'deal',
          title: deal.title,
          subtitle: matchContact 
            ? `${deal.contact.name} - ${deal.stage}`
            : matchCompany
            ? `${deal.company} - ${deal.stage}`
            : deal.stage,
          icon: <DollarSign className="w-4 h-4 text-green-400" />,
          path: `/deals/${deal.id}`
        });
      }
    });

    // Search team members (contacts)
    team.forEach(member => {
      if (member.name?.toLowerCase().includes(lowerQuery) ||
          member.email?.toLowerCase().includes(lowerQuery)) {
        searchResults.push({
          id: member.id,
          type: 'contact',
          title: member.name,
          subtitle: member.email,
          icon: <User className="w-4 h-4 text-blue-400" />,
          path: `/team/${member.id}`
        });
      }
    });

    // Search activities
    activities.forEach(activity => {
      if (activity.title?.toLowerCase().includes(lowerQuery)) {
        searchResults.push({
          id: activity.id,
          type: 'activity',
          title: activity.title,
          subtitle: activity.date ? new Date(activity.date).toLocaleDateString() : undefined,
          icon: <Activity className="w-4 h-4 text-purple-400" />,
          path: `/activity/${activity.id}`
        });
      }
    });

    // Sort results to prioritize exact matches and group by type
    const sortResults = (a: SearchResult, b: SearchResult) => {
      const aTitle = a.title?.toLowerCase() || '';
      const bTitle = b.title?.toLowerCase() || '';
      const aType = a.type || '';
      const bType = b.type || '';

      // Exact matches first
      const aExact = aTitle === lowerQuery;
      const bExact = bTitle === lowerQuery;
      if (aExact && !bExact) return -1;
      if (!aExact && bExact) return 1;

      // Then by type priority (deals first, then contacts, then activities)
      const typePriority = { deal: 0, contact: 1, activity: 2 };
      const aPriority = typePriority[aType] ?? 999;
      const bPriority = typePriority[bType] ?? 999;
      if (aPriority !== bPriority) {
        return aPriority - bPriority;
      }

      // Then by string match position
      const aIndex = aTitle.indexOf(lowerQuery);
      const bIndex = bTitle.indexOf(lowerQuery);
      if (aIndex !== bIndex) {
        return aIndex - bIndex;
      }

      // Finally by title length (shorter = better match)
      return aTitle.length - bTitle.length;
    };

    setResults(searchResults.sort(sortResults).slice(0, 8)); // Limit to 8 results
  }, [query, deals, team, activities]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prev => Math.min(prev + 1, results.length - 1));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prev => Math.max(prev - 1, -1));
    } else if (e.key === 'Enter' && selectedIndex >= 0) {
      e.preventDefault();
      const selected = results[selectedIndex];
      if (selected) {
        navigate(selected.path);
        setIsOpen(false);
        setQuery('');
      }
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleResultClick = (result: SearchResult) => {
    navigate(result.path);
    setIsOpen(false);
    setQuery('');
  };

  return (
    <div ref={searchRef} className="relative">
      <div className="relative">
        <input
          type="text"
          value={query}
          onChange={e => {
            setQuery(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder="Search..."
          className="w-48 px-3 py-1.5 pl-8 text-sm bg-navy-700 text-white rounded-md border border-white/10 focus:outline-none focus:border-indigo-500 focus:w-64 transition-all"
        />
        <Search className="absolute left-2.5 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
      </div>

      {/* Results Dropdown */}
      {isOpen && results.length > 0 && (
        <div className="absolute top-full left-0 w-72 mt-2 py-1 bg-navy-800 rounded-md shadow-lg border border-white/10 z-50">
          {results.map((result, index) => (
            <div
              key={`${result.type}-${result.id}`}
              className={`px-3 py-1.5 flex items-center gap-2 cursor-pointer ${
                index === selectedIndex
                  ? 'bg-navy-700'
                  : 'hover:bg-navy-700'
              }`}
              onClick={() => handleResultClick(result)}
            >
              {result.icon}
              <div className="flex-1 min-w-0">
                <div className="text-sm text-white truncate">
                  {result.title}
                </div>
              </div>
              <div className="text-xs text-gray-400 capitalize">
                {result.type}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* No Results */}
      {isOpen && query && results.length === 0 && (
        <div className="absolute top-full left-0 w-72 mt-2 py-2 bg-navy-800 rounded-md shadow-lg border border-white/10 z-50">
          <div className="text-sm text-gray-400 text-center">
            No results found
          </div>
        </div>
      )}
    </div>
  );
};
