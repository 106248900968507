import React from 'react';
import { useNotificationsStore } from '../../store/notificationsStore';
import { Bell, Volume2, VolumeX } from 'lucide-react';

export const NotificationSettings: React.FC = () => {
  const { 
    soundEnabled, 
    browserNotificationsEnabled, 
    toggleSound, 
    toggleBrowserNotifications 
  } = useNotificationsStore();

  return (
    <div className="p-4 space-y-4">
      <h2 className="text-lg font-semibold text-white mb-4">Notification Settings</h2>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {soundEnabled ? <Volume2 className="w-5 h-5 text-blue-500" /> : <VolumeX className="w-5 h-5 text-gray-500" />}
            <span className="text-white">Notification Sound</span>
          </div>
          <button
            onClick={toggleSound}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
              soundEnabled ? 'bg-blue-500' : 'bg-gray-700'
            }`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                soundEnabled ? 'translate-x-6' : 'translate-x-1'
              }`}
            />
          </button>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Bell className={`w-5 h-5 ${browserNotificationsEnabled ? 'text-blue-500' : 'text-gray-500'}`} />
            <span className="text-white">Browser Notifications</span>
          </div>
          <button
            onClick={toggleBrowserNotifications}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
              browserNotificationsEnabled ? 'bg-blue-500' : 'bg-gray-700'
            }`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                browserNotificationsEnabled ? 'translate-x-6' : 'translate-x-1'
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
