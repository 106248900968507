import React, { useState, useRef, useEffect } from 'react';
import { Bell, X, Settings2 } from 'lucide-react';
import { useNotificationsStore } from '../../store/notificationsStore';
import { formatDistanceToNow } from 'date-fns';
import { NotificationSettings } from './NotificationSettings';
import { useNavigate } from 'react-router-dom';

export const NotificationBell: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { notifications, subscribeToNotifications, markAsRead, markAllAsRead } = useNotificationsStore();
  const unreadCount = notifications.filter(n => !n.read).length;

  useEffect(() => {
    const unsubscribe = subscribeToNotifications();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setShowSettings(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleNotificationClick = (notification: { id: string; dealId?: string }) => {
    markAsRead(notification.id);
    setIsOpen(false);
    
    // Navigate to deal if dealId exists
    if (notification.dealId) {
      navigate(`/deals/${notification.dealId}`);
    }
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'mention':
        return '👋';
      case 'task_due':
        return '⏰';
      default:
        return '📢';
    }
  };

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-1.5 rounded-md hover:bg-navy-700 transition-colors"
      >
        <Bell className="w-5 h-5 text-gray-300" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 w-4 h-4 text-xs flex items-center justify-center bg-red-500 text-white rounded-full">
            {unreadCount > 9 ? '9+' : unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-80 bg-navy-800 rounded-md shadow-lg border border-white/10 z-50 max-h-[80vh] flex flex-col">
          <div className="px-4 py-2 border-b border-white/10 flex items-center justify-between">
            <h3 className="text-sm font-medium text-white">Notifications</h3>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setShowSettings(!showSettings)}
                className="p-1 hover:bg-navy-700 rounded-md transition-colors"
              >
                <Settings2 className="w-4 h-4 text-gray-400" />
              </button>
              {unreadCount > 0 && (
                <button
                  onClick={() => markAllAsRead()}
                  className="text-xs text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>

          {showSettings ? (
            <NotificationSettings />
          ) : (
            <>
              <div className="overflow-y-auto flex-1">
                {notifications.length === 0 ? (
                  <div className="p-4 text-center text-gray-400 text-sm">
                    No notifications yet
                  </div>
                ) : (
                  <div className="divide-y divide-white/10">
                    {notifications.map((notification) => (
                      <div
                        key={notification.id}
                        onClick={() => handleNotificationClick(notification)}
                        className={`p-3 hover:bg-navy-700 cursor-pointer transition-colors ${
                          !notification.read ? 'bg-navy-700/50' : ''
                        }`}
                      >
                        <div className="flex items-start space-x-3">
                          <div className="flex-shrink-0 text-xl">
                            {getNotificationIcon(notification.type)}
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className={`text-sm ${!notification.read ? 'font-medium text-white' : 'text-gray-300'}`}>
                              {notification.title}
                            </p>
                            <p className="text-sm text-gray-400 line-clamp-2">
                              {notification.message}
                            </p>
                            <p className="text-xs text-gray-500 mt-1">
                              {formatDistanceToNow(notification.createdAt, { addSuffix: true })}
                            </p>
                          </div>
                          {!notification.read && (
                            <div className="w-2 h-2 bg-blue-500 rounded-full flex-shrink-0 mt-1.5" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {notifications.length > 0 && (
                <div className="p-2 border-t border-white/10">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(false);
                      navigate('/notifications');
                    }}
                    className="text-xs text-center w-full text-gray-400 hover:text-white transition-colors"
                  >
                    View all notifications
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};
