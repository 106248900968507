import React from 'react';
import { Outlet, NavLink, useLocation } from 'react-router-dom';
import { LineChart, TrendingUp, Activity } from 'lucide-react';

export const ReportsLayout = () => {
  const location = useLocation();
  
  const getNavLinkClass = (path: string) => {
    const isActive = location.pathname === path;
    return `flex items-center px-4 py-2 rounded-md transition-colors ${
      isActive 
        ? 'bg-navy-700 text-white' 
        : 'text-gray-400 hover:bg-navy-700/50 hover:text-white'
    }`;
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center space-x-4 p-4 border-b border-navy-700">
        <NavLink 
          to="/reports/performance" 
          className={getNavLinkClass('/reports/performance')}
        >
          <LineChart className="w-5 h-5 mr-2" />
          Performance
        </NavLink>
        
        <NavLink 
          to="/reports/task-analytics" 
          className={getNavLinkClass('/reports/task-analytics')}
        >
          <TrendingUp className="w-5 h-5 mr-2" />
          Task Analytics
        </NavLink>

        <NavLink 
          to="/reports/activity-insights" 
          className={getNavLinkClass('/reports/activity-insights')}
        >
          <Activity className="w-5 h-5 mr-2" />
          Activity Insights
        </NavLink>
      </div>
      
      <div className="flex-1 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};