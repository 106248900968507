import React, { createContext, useState, ReactNode } from 'react';
import { User } from '../hooks/useAuth';

// Define the shape of the AuthContext
interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

// Create the context with a default value
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  // Placeholder login function - will be replaced with actual Firebase authentication
  const login = async (email: string, password: string) => {
    // TODO: Implement Firebase authentication
    console.log('Login attempted', email);
  };

  // Placeholder logout function
  const logout = async () => {
    // TODO: Implement Firebase logout
    setUser(null);
  };

  const value = {
    user,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
