import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  LayoutDashboard,
  BarChart2,
  Plus,
  Activity,
  LineChart
} from 'lucide-react';
import { NotificationBell } from '../notifications/NotificationBell';
import { SearchBar } from '../search/SearchBar';
import { ProfileMenu } from '../profile/ProfileMenu';

interface TopNavProps {
  onAddDeal: () => void;
  onSignOut: () => void;
}

export const TopNav: React.FC<TopNavProps> = ({
  onAddDeal,
  onSignOut,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <div className="fixed top-0 left-0 right-0 h-14 bg-navy-800 border-b border-white/10 z-50">
      <div className="h-full px-4 flex items-center gap-4">
        {/* Left Section - Logo */}
        <div 
          className="flex items-center gap-2 cursor-pointer mr-8" 
          onClick={() => navigate('/dashboard')}
        >
          <svg className="w-7 h-7 text-indigo-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 9L12 4L21 9L12 14L3 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 14L12 19L21 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span className="text-lg font-bold text-white">NextClient</span>
        </div>

        {/* Center Section - Main Navigation */}
        <div className="flex items-center gap-1">
          <Link
            to="/dashboard"
            className={`flex items-center px-3 py-1.5 text-sm text-gray-300 rounded-md transition-colors ${
              isActive('/dashboard') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
            }`}
          >
            <LayoutDashboard className="w-4 h-4 mr-2" />
            Dashboard
          </Link>

          <Link
            to="/activity"
            className={`flex items-center px-3 py-1.5 text-sm text-gray-300 rounded-md transition-colors ${
              isActive('/activity') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
            }`}
          >
            <Activity className="w-4 h-4 mr-2" />
            Activity
          </Link>

          <Link
            to="/workflows"
            className={`flex items-center px-3 py-1.5 text-sm text-gray-300 rounded-md transition-colors ${
              isActive('/workflows') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
            }`}
          >
            <BarChart2 className="w-4 h-4 mr-2" />
            Workflows
          </Link>

          <Link
            to="/reports"
            className={`flex items-center px-3 py-1.5 text-sm text-gray-300 rounded-md transition-colors ${
              isActive('/reports') ? 'bg-navy-700 text-white' : 'hover:bg-navy-700 hover:text-white'
            }`}
          >
            <LineChart className="w-4 h-4 mr-2" />
            Reports
          </Link>
        </div>

        {/* Right Section - Actions */}
        <div className="flex items-center gap-3 ml-auto">
          {/* Search */}
          <SearchBar />

          {/* Add Deal Button */}
          <button
            onClick={onAddDeal}
            className="flex items-center px-3 py-1.5 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors"
          >
            <Plus className="w-4 h-4 mr-1" />
            Add Deal
          </button>

          {/* Notifications */}
          <div className="px-1">
            <NotificationBell />
          </div>

          {/* Profile Menu */}
          <ProfileMenu onSignOut={onSignOut} />
        </div>
      </div>
    </div>
  );
};
