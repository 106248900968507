import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from "../../lib/firebase";
import { WorkflowDocument, WorkflowTask } from '../../types/Workflow';
import { useAuthStore } from '../../store/authStore';
import { usePipeline } from '../../hooks/usePipeline';
import { TaskService } from '../../components/activity/services/TaskService';

export const WorkflowCreate: React.FC = () => {
  const [name, setName] = useState('');
  const [selectedStageId, setSelectedStageId] = useState<string | null>(null);
  const [tasks, setTasks] = useState<WorkflowTask[]>([]);
  const [teamMembers, setTeamMembers] = useState<Array<{ id: string; name: string; photoURL?: string }>>([]);
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { stages, loading: stagesLoading } = usePipeline();

  useEffect(() => {
    const loadTeamMembers = async () => {
      if (user?.organizationId) {
        const members = await TaskService.fetchAssignees(user.organizationId);
        setTeamMembers(members);
      }
    };
    loadTeamMembers();
  }, [user?.organizationId]);

  const addTask = () => {
    setTasks([...tasks, {
      name: '',
      relativeDay: 0,
      type: 'call',
      priority: 'medium'
    }]);
  };

  const updateTask = (index: number, field: keyof WorkflowTask, value: string | number) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = {
      ...updatedTasks[index],
      [field]: value
    };
    setTasks(updatedTasks);
  };

  const removeTask = (index: number) => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1);
    setTasks(updatedTasks);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) {
      alert('You must be logged in to create a workflow');
      return;
    }

    if (!['owner', 'admin', 'member'].includes(user.role || '')) {
      alert('You do not have permission to create workflows');
      return;
    }

    if (tasks.some(task => !task.name.trim())) {
      alert('All tasks must have a name');
      return;
    }

    try {
      const workflowData: Omit<WorkflowDocument, 'id'> = {
        name,
        organizationId: user.organizationId,
        createdBy: user.uid,
        createdAt: Timestamp.now(),
        isActive: true,
        dealStageId: selectedStageId || undefined,
        tasks,
        steps: [],
        triggers: [{
          id: 'default-trigger',
          type: 'deal_stage_change',
          conditions: selectedStageId ? [{
            sourcePipelineStageId: selectedStageId
          }] : []
        }]
      };

      await addDoc(collection(db, 'workflows'), workflowData);
      navigate('/workflows');
    } catch (err) {
      console.error('Error creating workflow:', err);
      alert('Failed to create workflow');
    }
  };

  if (stagesLoading) {
    return <div>Loading pipeline stages...</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-navy-900/50 rounded-lg border border-navy-700">
      <h1 className="text-2xl font-bold mb-6 text-white">Create New Workflow</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label 
            htmlFor="workflowName" 
            className="block text-sm font-medium text-gray-300 mb-2"
          >
            Workflow Name
          </label>
          <input
            id="workflowName"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full bg-navy-800 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter workflow name"
          />
        </div>

        <div className="mb-4">
          <label 
            htmlFor="triggerStage" 
            className="block text-sm font-medium text-gray-300 mb-2"
          >
            Trigger Deal Stage (Optional)
          </label>
          <select
            id="triggerStage"
            value={selectedStageId || ''}
            onChange={(e) => setSelectedStageId(e.target.value || null)}
            className="w-full bg-navy-800 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a stage (optional)</option>
            {stages.map(stage => (
              <option key={stage.id} value={stage.id}>
                {stage.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-6">
          <div className="flex justify-between items-center mb-2">
            <label className="block text-sm font-medium text-gray-300">
              Workflow Tasks
            </label>
            <button
              type="button"
              onClick={addTask}
              className="bg-green-500 hover:bg-green-600 text-white text-sm font-medium py-1 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Add Task
            </button>
          </div>
          
          {tasks.map((task, index) => (
            <div key={index} className="mb-4 p-4 bg-navy-800 rounded-lg border border-navy-600">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    value={task.name}
                    onChange={(e) => updateTask(index, 'name', e.target.value)}
                    placeholder="Task name"
                    required
                    className="w-full bg-navy-700 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    <input
                      type="number"
                      value={task.relativeDay}
                      onChange={(e) => updateTask(index, 'relativeDay', parseInt(e.target.value) || 0)}
                      className="w-full bg-navy-700 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-400 mt-1 block">Days after trigger</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => removeTask(index)}
                    className="text-red-400 hover:text-red-300"
                  >
                    ×
                  </button>
                </div>
                <div>
                  <select
                    value={task.type || 'call'}
                    onChange={(e) => updateTask(index, 'type', e.target.value)}
                    className="w-full bg-navy-700 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="call">Call</option>
                    <option value="email">Email</option>
                    <option value="demo">Demo</option>
                    <option value="meeting">Meeting</option>
                    <option value="follow_up">Follow Up</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div>
                  <select
                    value={task.priority || 'medium'}
                    onChange={(e) => updateTask(index, 'priority', e.target.value)}
                    className="w-full bg-navy-700 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="low">Low Priority</option>
                    <option value="medium">Medium Priority</option>
                    <option value="high">High Priority</option>
                  </select>
                </div>
                <div className="md:col-span-2">
                  <select
                    value={task.assigneeId || ''}
                    onChange={(e) => updateTask(index, 'assigneeId', e.target.value)}
                    className="w-full bg-navy-700 border border-navy-600 text-white rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Unassigned</option>
                    {teamMembers.map(member => (
                      <option key={member.id} value={member.id}>
                        {member.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ))}
          {tasks.length === 0 && (
            <p className="text-gray-400 text-sm">No tasks added yet</p>
          )}
        </div>

        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Create Workflow
          </button>
          <button
            type="button"
            onClick={() => navigate('/workflows')}
            className="bg-navy-700 hover:bg-navy-600 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-navy-500"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
