import React, { useState } from 'react';
import { PlusCircle, Edit2, Trash2, Plus, GripVertical, Check, X } from 'lucide-react';
import { useCustomFieldsStore } from '../../store/customFieldsStore';
import type { CustomFieldType, CustomFieldDisplayLocation } from '../../types/customField';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableFieldItemProps {
  id: string;
  name: string;
  type: CustomFieldType;
  required: boolean;
  displayLocation: CustomFieldDisplayLocation;
  options?: string[];
  onDelete: (id: string) => void;
  onEdit: (id: string, data: any) => void;
}

const SortableFieldItem: React.FC<SortableFieldItemProps> = ({
  id,
  name,
  type,
  required,
  displayLocation,
  options,
  onDelete,
  onEdit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedField, setEditedField] = useState({
    name,
    type,
    required,
    displayLocation,
    options: options?.join(', ') || '',
  });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSave = () => {
    onEdit(id, {
      name: editedField.name,
      type: editedField.type,
      required: editedField.required,
      displayLocation: editedField.displayLocation,
      options: editedField.type === 'select' 
        ? editedField.options.split(',').map(o => o.trim()).filter(Boolean)
        : undefined,
    });
    setIsEditing(false);
  };

  return (
    <tr ref={setNodeRef} style={style} className="bg-navy-800/50 hover:bg-navy-700/50">
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div {...attributes} {...listeners} className="cursor-grab">
            <GripVertical className="w-4 h-4 text-gray-400 mr-2" />
          </div>
          {isEditing ? (
            <input
              type="text"
              value={editedField.name}
              onChange={(e) => setEditedField(prev => ({ ...prev, name: e.target.value }))}
              className="w-full rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          ) : (
            <span className="text-sm text-white">{name}</span>
          )}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {isEditing ? (
          <select
            value={editedField.type}
            onChange={(e) => setEditedField(prev => ({ ...prev, type: e.target.value as CustomFieldType }))}
            className="rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="text">Text</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
            <option value="select">Select</option>
            <option value="url">URL</option>
          </select>
        ) : (
          <span className="px-2 py-1 text-xs font-medium text-indigo-400 bg-indigo-500/10 rounded-full">
            {type}
          </span>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {isEditing ? (
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={editedField.required}
              onChange={(e) => setEditedField(prev => ({ ...prev, required: e.target.checked }))}
              className="rounded border-navy-700 text-blue-600 focus:ring-blue-500 bg-navy-900/50"
            />
            <span className="text-sm font-medium text-gray-400">Required</span>
          </label>
        ) : (
          <span className="text-sm text-white">
            {required ? 'Yes' : 'No'}
          </span>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {isEditing ? (
          <select
            value={editedField.displayLocation}
            onChange={(e) => setEditedField(prev => ({ ...prev, displayLocation: e.target.value as CustomFieldDisplayLocation }))}
            className="rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="general">General</option>
            <option value="company">Company Information</option>
            <option value="contact">Contact Information</option>
            <option value="deal">Deal Information</option>
          </select>
        ) : (
          <span className="text-sm text-white">
            {displayLocation}
          </span>
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
        <div className="flex space-x-3">
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                className="inline-flex items-center px-2 py-1 text-sm text-white bg-blue-600 rounded hover:bg-blue-700"
              >
                <Check className="w-4 h-4 mr-1" />
                Save
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="inline-flex items-center px-2 py-1 text-sm text-gray-400 bg-navy-700 rounded hover:bg-navy-600"
              >
                <X className="w-4 h-4 mr-1" />
                Cancel
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="text-indigo-400 hover:text-indigo-300"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={() => onDelete(id)}
                className="text-red-400 hover:text-red-300"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export const CustomFieldsManager = () => {
  const { fields, addField, deleteField, updateField, reorderFields } = useCustomFieldsStore();
  const [isAddingField, setIsAddingField] = useState(false);
  const [newField, setNewField] = useState({
    name: '',
    type: 'text' as CustomFieldType,
    required: false,
    displayLocation: 'general' as CustomFieldDisplayLocation,
    options: '',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);
      
      const newOrder = arrayMove(fields, oldIndex, newIndex);
      reorderFields(newOrder.map(field => field.id));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!newField.name.trim()) {
      alert('Field name is required');
      return;
    }

    try {
      const options = newField.type === 'select' 
        ? newField.options
            .split(',')
            .map(o => o.trim())
            .filter(o => o.length > 0)
        : undefined;

      await addField({
        name: newField.name,
        type: newField.type,
        required: newField.required,
        displayLocation: newField.displayLocation,
        options: options,
      });

      setNewField({ name: '', type: 'text', required: false, displayLocation: 'general', options: '' });
      setIsAddingField(false);
    } catch (err: any) {
      console.error('Error adding custom field:', err);
      alert(err.message || 'Failed to add custom field. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-6">
        <button
          onClick={() => setIsAddingField(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusCircle className="w-4 h-4 mr-2" />
          Add Custom Field
        </button>
      </div>

      {isAddingField && (
        <form onSubmit={handleSubmit} className="mb-6 p-4 border rounded-md">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Field Name
              </label>
              <input
                type="text"
                required
                value={newField.name}
                onChange={(e) => setNewField(prev => ({ ...prev, name: e.target.value }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Field Type
              </label>
              <select
                value={newField.type}
                onChange={(e) => setNewField(prev => ({ 
                  ...prev, 
                  type: e.target.value as CustomFieldType,
                  options: e.target.value === 'select' ? '' : ''
                }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="select">Select</option>
                <option value="url">URL</option>
              </select>
            </div>

            {newField.type === 'select' && (
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-1">
                  Options (comma-separated)
                </label>
                <input
                  type="text"
                  value={newField.options}
                  onChange={(e) => setNewField(prev => ({ ...prev, options: e.target.value }))}
                  placeholder="Option 1, Option 2, Option 3"
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                Display Location
              </label>
              <select
                value={newField.displayLocation}
                onChange={(e) => setNewField(prev => ({ 
                  ...prev, 
                  displayLocation: e.target.value as CustomFieldDisplayLocation 
                }))}
                className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="general">General</option>
                <option value="company">Company Information</option>
                <option value="contact">Contact Information</option>
                <option value="deal">Deal Information</option>
              </select>
            </div>

            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={newField.required}
                  onChange={(e) => setNewField(prev => ({ ...prev, required: e.target.checked }))}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="text-sm font-medium text-gray-400">Required</span>
              </label>
            </div>

            <div className="flex gap-2">
              <button
                type="submit"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Field
              </button>
              <button
                type="button"
                onClick={() => setIsAddingField(false)}
                className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="bg-navy-800/50 rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-navy-700">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Field Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Required
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Display Location
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-navy-700">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={fields.map(field => field.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {fields.map((field) => (
                    <SortableFieldItem
                      key={field.id}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      required={field.required}
                      displayLocation={field.displayLocation}
                      options={field.options}
                      onDelete={deleteField}
                      onEdit={updateField}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustomFieldsManager;