import React from 'react';
import { Link } from 'react-router-dom';
import { PublicLayout } from '../../components/layout/PublicLayout';

const PrivacyPolicy: React.FC = () => {
  return (
    <PublicLayout>
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-navy-800/50 backdrop-blur-sm rounded-lg shadow-lg border border-white/10 p-8">
          <h1 className="text-3xl font-bold mb-8 text-white">Privacy Policy</h1>
          
          <div className="space-y-6 text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-white mb-4">1. Information We Collect</h2>
              <p>NextClient CRM, operated by JCE Media, collects information that you provide directly to us, including:</p>
              <ul className="list-disc pl-6 mt-2 text-gray-300">
                <li>Account information (name, email, password)</li>
                <li>Business information</li>
                <li>Customer data you input</li>
                <li>Usage information and analytics</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">2. How We Use Your Information</h2>
              <p>We use the collected information to:</p>
              <ul className="list-disc pl-6 mt-2 text-gray-300">
                <li>Provide and maintain our service</li>
                <li>Improve and personalize user experience</li>
                <li>Send important notices and updates</li>
                <li>Respond to your requests and support needs</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">3. Data Security</h2>
              <p>We implement appropriate security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">4. Data Sharing</h2>
              <p>We do not sell your personal information. We may share your information with:</p>
              <ul className="list-disc pl-6 mt-2 text-gray-300">
                <li>Service providers who assist in operating our service</li>
                <li>Law enforcement when required by law</li>
                <li>Third parties with your explicit consent</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">5. Your Rights</h2>
              <p>You have the right to:</p>
              <ul className="list-disc pl-6 mt-2 text-gray-300">
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to data processing</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">6. Cookies</h2>
              <p>We use cookies and similar tracking technologies to improve user experience and analyze service usage.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">7. Changes to Privacy Policy</h2>
              <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">8. Contact Us</h2>
              <p>If you have questions about this Privacy Policy, please contact JCE Media.</p>
            </section>
          </div>

          <div className="mt-8 pt-8 border-t border-white/10">
            <Link to="/terms-of-service" className="text-indigo-400 hover:text-indigo-300 transition-colors">
              View Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export { PrivacyPolicy };
