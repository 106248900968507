import React from 'react';
import { usePipelineStore } from '../../store/pipelineStore';
import { formatDistanceToNow } from 'date-fns';
import { Clock, Check } from 'lucide-react';
import type { Deal } from '../../types/deal';

interface DealProgressBarProps {
  deal: Deal;
  onStageChange?: (stageId: string) => void;
}

export const DealProgressBar: React.FC<DealProgressBarProps> = ({ deal, onStageChange }) => {
  const { stages } = usePipelineStore();
  const currentStageIndex = stages.findIndex(s => s.id === deal.stageId);
  const lastStageChange = deal.stageHistory?.[deal.stageHistory.length - 1];
  const timeInCurrentStage = lastStageChange ? formatDistanceToNow(new Date(lastStageChange.timestamp)) : 'N/A';

  return (
    <div className="bg-navy-900/50 rounded-lg border border-navy-700">
      <div className="flex items-center justify-between text-sm p-4">
        <div className="flex items-center text-gray-400">
          <Clock className="w-4 h-4 mr-1.5" />
          <span>Stage {currentStageIndex + 1} of {stages.length}</span>
        </div>
        <div className="text-gray-400">
          {timeInCurrentStage}
        </div>
      </div>

      <div className="relative px-6 pb-4">
        {/* Progress Line */}
        <div className="absolute top-3 left-8 right-8 h-0.5">
          <div className="h-full bg-navy-700 absolute inset-0" />
          <div 
            className="h-full bg-indigo-500 absolute left-0 transition-all duration-300"
            style={{ 
              width: `${(currentStageIndex / (stages.length - 1)) * 100}%`
            }}
          />
        </div>

        {/* Stages */}
        <div className="relative flex justify-between">
          {stages.map((stage, index) => {
            const isCompleted = index < currentStageIndex;
            const isCurrent = index === currentStageIndex;
            const isClickable = onStageChange && Math.abs(index - currentStageIndex) <= 1;
            
            return (
              <div 
                key={stage.id} 
                className={`flex flex-col items-center relative ${isClickable ? 'cursor-pointer group' : ''}`}
                onClick={() => isClickable && onStageChange?.(stage.id)}
                title={stage.name}
              >
                {/* Stage Indicator */}
                <div className="relative mb-2">
                  {isCurrent && (
                    <div className="absolute -inset-1.5 border-2 border-indigo-500/20 rounded-full animate-pulse" />
                  )}
                  <div
                    className={`
                      w-6 h-6 rounded-full flex items-center justify-center transition-all duration-300
                      ${isCompleted 
                        ? 'bg-indigo-500 text-white' 
                        : isCurrent
                          ? 'bg-indigo-500/20 text-indigo-500 border-2 border-indigo-500'
                          : 'bg-navy-700 text-gray-500'
                      }
                      ${isClickable && !isCurrent ? 'group-hover:border-2 group-hover:border-indigo-500/50' : ''}
                    `}
                  >
                    {isCompleted ? (
                      <Check className="w-3 h-3" />
                    ) : (
                      <span className="text-xs">{index + 1}</span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};