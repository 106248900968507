import React, { useState, useEffect } from 'react';
import { useTeamStore } from '../../store/teamStore';
import { useAuthStore } from '../../store/authStore';
import { UserPlus, Users, Copy, Check } from 'lucide-react';

export const TeamSettings = () => {
  const { user } = useAuthStore();
  const { members, loading: membersLoading, error, subscribeToMembers, generateInviteCode } = useTeamStore();
  const [isGeneratingInvite, setIsGeneratingInvite] = useState(false);
  const [inviteCode, setInviteCode] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (user?.organizationId) {
      const unsubscribe = subscribeToMembers(user.organizationId);
      return () => unsubscribe();
    }
  }, [user?.organizationId, subscribeToMembers]);

  const handleGenerateInvite = async () => {
    setIsGeneratingInvite(true);
    setInviteCode(null); // Clear previous code while generating
    try {
      const code = await generateInviteCode();
      setInviteCode(code);
    } catch (error) {
      console.error('Error generating invite:', error);
    } finally {
      setIsGeneratingInvite(false);
    }
  };

  const copyToClipboard = async () => {
    if (inviteCode) {
      await navigator.clipboard.writeText(inviteCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  if (membersLoading && !members?.length) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-gray-400">Loading team members...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-red-400">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Users className="w-6 h-6 text-gray-400" />
          <div>
            <h1 className="text-2xl font-semibold text-white">Team Settings</h1>
            <p className="text-sm text-gray-400">Manage your team members and their access permissions</p>
          </div>
        </div>
        <button
          onClick={handleGenerateInvite}
          disabled={isGeneratingInvite}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isGeneratingInvite ? (
            <div className="w-4 h-4 mr-2">
              <div className="w-full h-full border-2 border-white border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : (
            <UserPlus className="w-4 h-4 mr-2" />
          )}
          Invite Team Member
        </button>
      </div>

      {/* Invitation Code Section - Only shown when code is generated */}
      {inviteCode && (
        <div className="mb-6 bg-navy-800/50 rounded-lg p-4 flex items-center justify-between animate-fade-in border border-blue-500/20">
          <div className="flex items-center gap-4">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <UserPlus className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-400">Invitation Code</p>
              <p className="text-lg font-mono font-bold text-blue-400">{inviteCode}</p>
            </div>
          </div>
          <button
            onClick={copyToClipboard}
            className="p-2 hover:bg-white/5 rounded-lg transition-colors"
            title="Copy to clipboard"
          >
            {copied ? (
              <Check className="w-5 h-5 text-green-400" />
            ) : (
              <Copy className="w-5 h-5 text-gray-400 hover:text-white" />
            )}
          </button>
        </div>
      )}

      {/* Team Members Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {members?.map((member) => (
          <div
            key={member.uid}
            className="bg-navy-800/50 rounded-lg p-4 flex items-center justify-between hover:bg-navy-700/50 transition-colors"
          >
            <div className="flex items-center space-x-3">
              <div className="h-10 w-10 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 flex items-center justify-center flex-shrink-0">
                <span className="text-sm font-medium text-white">
                  {member.email[0].toUpperCase()}
                </span>
              </div>
              <div className="min-w-0">
                <div className="text-sm font-medium text-white truncate">
                  {member.displayName || member.email}
                </div>
                {member.displayName && (
                  <div className="text-sm text-gray-400 truncate">{member.email}</div>
                )}
                {member.title && (
                  <div className="text-sm text-gray-500 truncate">{member.title}</div>
                )}
              </div>
            </div>
            <div className="ml-4 flex-shrink-0">
              {member.role === 'owner' ? (
                <span className="px-2 py-1 text-xs font-medium text-purple-300 bg-purple-500/20 rounded-full">
                  owner
                </span>
              ) : (
                <span className="px-2 py-1 text-xs font-medium text-emerald-300 bg-emerald-500/20 rounded-full">
                  member
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Loading and Error States */}
      {membersLoading && !members?.length && (
        <div className="flex items-center justify-center h-40 text-gray-400">
          Loading team members...
        </div>
      )}
      {error && (
        <div className="flex items-center justify-center h-40 text-red-400">
          Error: {error}
        </div>
      )}
    </div>
  );
};

export default TeamSettings;