import { create } from 'zustand';
import { db } from '../lib/firebase';
import { doc, getDoc, setDoc, onSnapshot, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthStore } from './authStore';
import { useKanbanStore } from './kanbanStore';
import type { Stage } from '../types/stage';

interface PipelineState {
  stages: Stage[];
  loading: boolean;
  error: string | null;
  deleteStage: (stageId: string, targetStageId?: string) => Promise<void>;
  updateStages: (stages: Stage[]) => Promise<void>;
  reorderStages: (stageIds: string[]) => Promise<void>;
  subscribeToPipeline: () => () => void;
}

const defaultStages: Stage[] = [
  { id: '1', name: 'Lead', color: '#818CF8' },
  { id: '2', name: 'Contact Made', color: '#60A5FA' },
  { id: '3', name: 'Proposal Sent', color: '#34D399' },
  { id: '4', name: 'Negotiation', color: '#FBBF24' },
  { id: '5', name: 'Closed Won', color: '#10B981' },
  { id: '6', name: 'Closed Lost', color: '#EF4444' },
];

export const usePipelineStore = create<PipelineState>((set, get) => ({
  stages: [],
  loading: true,
  error: null,

  deleteStage: async (stageId: string, targetStageId?: string) => {
    try {
      set({ loading: true, error: null });
      const user = useAuthStore.getState().user;
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      // Get current stages
      const currentStages = get().stages;
      const stageToDelete = currentStages.find(s => s.id === stageId);
      if (!stageToDelete) {
        throw new Error('Stage not found');
      }

      // If there are deals in this stage and no target stage is specified, throw error
      const dealsRef = collection(db, 'deals');
      const dealsQuery = query(
        dealsRef,
        where('organizationId', '==', user.organizationId),
        where('stage', '==', stageToDelete.name)
      );
      const dealsSnapshot = await getDocs(dealsQuery);

      if (!dealsSnapshot.empty && !targetStageId) {
        throw new Error('DEALS_EXIST');
      }

      // If target stage is specified, move all deals to that stage
      if (targetStageId) {
        const targetStage = currentStages.find(s => s.id === targetStageId);
        if (!targetStage) {
          throw new Error('Target stage not found');
        }

        // Move all deals to target stage
        const updatePromises = dealsSnapshot.docs.map(dealDoc => {
          return useKanbanStore.getState().moveDeal(
            dealDoc.id,
            stageToDelete.name,
            targetStage.name
          );
        });
        await Promise.all(updatePromises);
      }

      // Remove the stage from the pipeline
      const updatedStages = currentStages.filter(s => s.id !== stageId);
      
      // Update the pipeline
      const pipelineRef = doc(db, 'pipelines', user.organizationId);
      await setDoc(pipelineRef, { stages: updatedStages }, { merge: true });
      
      set({ stages: updatedStages, loading: false });
    } catch (error: any) {
      console.error('Error deleting pipeline stage:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  reorderStages: async (stageIds: string[]) => {
    try {
      set({ loading: true, error: null });
      const user = useAuthStore.getState().user;
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      // Get current stages and reorder them based on the new order
      const currentStages = get().stages;
      const reorderedStages = stageIds.map(id => 
        currentStages.find(s => s.id === id)
      ).filter((s): s is Stage => s !== undefined);

      // Update the pipeline
      const pipelineRef = doc(db, 'pipelines', user.organizationId);
      await setDoc(pipelineRef, { stages: reorderedStages }, { merge: true });
      
      set({ stages: reorderedStages, loading: false });
    } catch (error: any) {
      console.error('Error reordering pipeline stages:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  subscribeToPipeline: () => {
    const user = useAuthStore.getState().user;
    if (!user?.organizationId) {
      set({ loading: false, error: 'No user or organization found' });
      return () => {};
    }

    const pipelineRef = doc(db, 'pipelines', user.organizationId);
    
    // First, try to get the existing pipeline
    getDoc(pipelineRef).then(async (doc) => {
      if (!doc.exists()) {
        // If no pipeline exists, create one with default stages
        await setDoc(pipelineRef, { stages: defaultStages });
        set({ stages: defaultStages, loading: false });
      }
    });

    // Subscribe to changes
    const unsubscribe = onSnapshot(
      pipelineRef,
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          set({ stages: data.stages || defaultStages, loading: false });
        }
      },
      (error) => {
        set({ error: error.message, loading: false });
      }
    );

    return unsubscribe;
  },

  updateStages: async (stages: Stage[]) => {
    try {
      set({ loading: true, error: null });
      const user = useAuthStore.getState().user;
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      const pipelineRef = doc(db, 'pipelines', user.organizationId);
      await setDoc(pipelineRef, { stages }, { merge: true });
      
      set({ stages, loading: false });
    } catch (error: any) {
      console.error('Error updating pipeline stages:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },
}));