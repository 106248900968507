import React, { useState, useMemo, useEffect } from 'react';
import { useKanbanStore } from '../../store/kanbanStore';
import { useTeamStore } from '../../store/teamStore';
import { format, subDays, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { BarChart3, TrendingUp, DollarSign, Target, Filter, X } from 'lucide-react';
import { TeamMemberStats } from './TeamMemberStats';
import { PipelineChart } from './PipelineChart';
import { TopDeals } from './TopDeals';
import { Button } from '../../components/ui';
import { useAuthStore } from '../../store/authStore';

type DateRange = '7' | '30' | '90' | 'all';

export const PerformanceReport = () => {
  const [dateRange, setDateRange] = useState<DateRange>('30');
  const [selectedMemberId, setSelectedMemberId] = useState<string>('all');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { deals, subscribeToDeals } = useKanbanStore();
  const { user } = useAuthStore();
  const { members, subscribeToMembers } = useTeamStore();

  // Subscribe to deals and members
  useEffect(() => {
    console.log('Setting up subscriptions...');
    const unsubscribeDeals = subscribeToDeals();
    const unsubscribeMembers = subscribeToMembers();

    return () => {
      unsubscribeDeals();
      unsubscribeMembers();
    };
  }, [subscribeToDeals, subscribeToMembers]);

  // Debug logging
  useEffect(() => {
    console.log('Current User:', {
      uid: user?.uid,
      email: user?.email,
      organizationId: user?.organizationId
    });
    console.log('Team Members:', members);
    console.log('All Deals:', deals);
  }, [user, members, deals]);

  // Filter out members who are not in the current organization
  const organizationMembers = useMemo(() => {
    if (!user?.organizationId) {
      console.log('No organization ID for member filtering');
      return [];
    }
    
    const filtered = members.filter(member => {
      const matches = member.organizationId === user.organizationId;
      if (!matches) {
        console.log('Member filtered out:', {
          memberId: member.uid,
          memberOrg: member.organizationId,
          currentOrg: user.organizationId,
          memberEmail: member.email
        });
      }
      return matches;
    });
    
    console.log('Organization members:', filtered.map(m => ({
      uid: m.uid,
      email: m.email,
      displayName: m.displayName,
      role: m.role
    })));
    return filtered;
  }, [members, user?.organizationId]);

  const filteredDeals = useMemo(() => {
    if (!user?.organizationId) {
      console.log('No organization ID found');
      return [];
    }

    console.log('Filtering deals...');
    // First filter by organization
    let filtered = deals.filter(deal => {
      const matches = deal.organizationId === user.organizationId;
      if (!matches) {
        console.log('Deal filtered out - wrong org:', {
          dealId: deal.id,
          dealOrg: deal.organizationId,
          currentOrg: user.organizationId
        });
      }
      return matches;
    });

    console.log('Deals after org filter:', filtered.length);

    // Filter by date range
    if (dateRange !== 'all') {
      const days = parseInt(dateRange);
      const startDate = startOfDay(subDays(new Date(), days));
      const endDate = endOfDay(new Date());
      
      filtered = filtered.filter(deal => {
        const dealDate = new Date(deal.createdAt);
        const isInRange = isWithinInterval(dealDate, { start: startDate, end: endDate });
        if (!isInRange) {
          console.log('Deal filtered out - date range:', {
            dealId: deal.id,
            dealDate,
            startDate,
            endDate
          });
        }
        return isInRange;
      });
    }

    console.log('Deals after date filter:', filtered.length);

    // Filter by team member
    if (selectedMemberId !== 'all') {
      filtered = filtered.filter(deal => {
        const matches = deal.ownerId === selectedMemberId;
        if (!matches) {
          console.log('Deal filtered out - wrong owner:', {
            dealId: deal.id,
            dealTitle: deal.title,
            dealOwner: deal.ownerId,
            dealOwnerName: deal.ownerName,
            selectedMember: selectedMemberId,
            selectedMemberName: organizationMembers.find(m => m.uid === selectedMemberId)?.displayName
          });
        }
        return matches;
      });
      console.log('Deals after member filter:', filtered.length);
    }

    console.log('Final filtered deals:', filtered);
    return filtered;
  }, [deals, dateRange, selectedMemberId, user?.organizationId, organizationMembers]);

  const activeDeals = useMemo(() => {
    const active = filteredDeals.filter(deal => 
      deal.stage !== 'Closed Won' && deal.stage !== 'Closed Lost' && !deal.archived
    );
    console.log('Active deals:', active.length);
    return active;
  }, [filteredDeals]);

  const stats = useMemo(() => {
    const totalValue = activeDeals.reduce((sum, deal) => {
      const value = Number(deal.value) || 0;
      console.log('Deal:', deal.title, 'Value:', value);
      return sum + value;
    }, 0);
    
    const wonDeals = filteredDeals.filter(d => d.stage === 'Closed Won');
    const lostDeals = filteredDeals.filter(d => d.stage === 'Closed Lost');
    const wonValue = wonDeals.reduce((sum, deal) => sum + (Number(deal.value) || 0), 0);
    const winRate = wonDeals.length + lostDeals.length > 0
      ? (wonDeals.length / (wonDeals.length + lostDeals.length)) * 100
      : 0;

    const calculatedStats = {
      totalValue,
      wonValue,
      totalDeals: activeDeals.length,
      wonDeals: wonDeals.length,
      lostDeals: lostDeals.length,
      winRate
    };

    console.log('Calculated stats:', calculatedStats);
    return calculatedStats;
  }, [filteredDeals, activeDeals]);

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-white">Performance Report</h2>
        <Button 
          variant="outline" 
          size="sm"
          className="flex items-center gap-2 bg-navy-800 border-navy-600 text-white hover:bg-navy-700"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          {isFilterOpen ? <X className="h-4 w-4" /> : <Filter className="h-4 w-4" />}
          Filters
        </Button>
      </div>

      {/* Collapsible Filters */}
      {isFilterOpen && (
        <div className="bg-navy-800 border border-navy-600 rounded-lg p-4 mb-4 space-y-4">
          {/* Date Range Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Date Range</label>
            <div className="flex gap-2 flex-wrap">
              {(['7', '30', '90', 'all'] as DateRange[]).map(range => (
                <Button
                  key={range}
                  variant={dateRange === range ? 'default' : 'outline'}
                  size="sm"
                  className={dateRange === range 
                    ? 'bg-blue-500 hover:bg-blue-600 text-white' 
                    : 'border-navy-600 text-gray-300 hover:bg-navy-700'}
                  onClick={() => setDateRange(range)}
                >
                  {range === 'all' ? 'All Time' : `Last ${range} Days`}
                </Button>
              ))}
            </div>
          </div>

          {/* Sales Person Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">Sales Person</label>
            <div className="flex gap-2 flex-wrap">
              <Button
                variant={selectedMemberId === 'all' ? 'default' : 'outline'}
                size="sm"
                className={selectedMemberId === 'all'
                  ? 'bg-blue-500 hover:bg-blue-600 text-white'
                  : 'border-navy-600 text-gray-300 hover:bg-navy-700'}
                onClick={() => setSelectedMemberId('all')}
              >
                All Members
              </Button>
              {organizationMembers.map(member => (
                <Button
                  key={member.uid}
                  variant={selectedMemberId === member.uid ? 'default' : 'outline'}
                  size="sm"
                  className={selectedMemberId === member.uid
                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                    : 'border-navy-600 text-gray-300 hover:bg-navy-700'}
                  onClick={() => setSelectedMemberId(member.uid)}
                >
                  {member.displayName || member.email}
                </Button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-400">Total Pipeline</p>
              <h3 className="text-2xl font-bold text-white mt-1">
                ${stats.totalValue.toLocaleString()}
              </h3>
            </div>
            <div className="p-3 bg-blue-500/20 rounded-full">
              <BarChart3 className="w-6 h-6 text-blue-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-400">
            <span>{stats.totalDeals} active deals</span>
          </div>
        </div>

        <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-400">Won Deals Value</p>
              <h3 className="text-2xl font-bold text-white mt-1">
                ${stats.wonValue.toLocaleString()}
              </h3>
            </div>
            <div className="p-3 bg-green-500/20 rounded-full">
              <TrendingUp className="w-6 h-6 text-green-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-400">
            <span>{stats.wonDeals} won deals</span>
          </div>
        </div>

        <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-400">Average Deal Size</p>
              <h3 className="text-2xl font-bold text-white mt-1">
                ${stats.totalDeals > 0 
                  ? Math.round(stats.totalValue / stats.totalDeals).toLocaleString()
                  : '0'}
              </h3>
            </div>
            <div className="p-3 bg-purple-500/20 rounded-full">
              <DollarSign className="w-6 h-6 text-purple-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-400">
            <span>Per deal average</span>
          </div>
        </div>

        <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-400">Win Rate</p>
              <h3 className="text-2xl font-bold text-white mt-1">
                {stats.winRate.toFixed(1)}%
              </h3>
            </div>
            <div className="p-3 bg-yellow-500/20 rounded-full">
              <Target className="w-6 h-6 text-yellow-400" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-400">
            <span>{stats.wonDeals} won / {stats.lostDeals} lost</span>
          </div>
        </div>
      </div>

      {/* Pipeline Chart */}
      <div className="mb-8">
        <PipelineChart deals={filteredDeals} />
      </div>

      {/* Team Performance */}
      <div className="mb-8">
        <TeamMemberStats 
          deals={filteredDeals}
          members={organizationMembers}
          dateRange={dateRange}
        />
      </div>

      {/* Top Deals */}
      <div>
        <TopDeals deals={filteredDeals} />
      </div>
    </div>
  );
};