import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, query, where, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { DealActivity } from '../../types/deal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuthStore } from '../../store/authStore';
import { Plus, Trash2, Check, Edit2, Clock } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import { usePipelineStore } from '../../store/pipelineStore';
import { format } from 'date-fns';

interface ActivitiesProps {
  dealId: string;
  currentStage: string;
  onStageChange?: (newStage: string) => void;
}

export const Activities: React.FC<ActivitiesProps> = ({ dealId, currentStage, onStageChange }) => {
  const { user } = useAuthStore();
  const { stages } = usePipelineStore();
  const [activities, setActivities] = useState<DealActivity[]>([]);
  const [newActivity, setNewActivity] = useState<DealActivity>({
    title: '',
    description: '',
    actionedDate: new Date().toISOString(),
    status: 'Completed'
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editingActivity, setEditingActivity] = useState<DealActivity | null>(null);
  const [selectedStage, setSelectedStage] = useState<string>(currentStage);

  useEffect(() => {
    if (user?.organizationId && dealId) {
      fetchActivities();
    }
  }, [dealId, user?.organizationId]);

  useEffect(() => {
    setSelectedStage(currentStage);
  }, [currentStage]);

  const fetchActivities = async () => {
    if (!user?.organizationId || !dealId) {
      console.error('Missing required fields for fetch:', { organizationId: user?.organizationId, dealId });
      return;
    }

    try {
      const activitiesRef = collection(db, 'activities');
      const q = query(
        activitiesRef, 
        where('dealId', '==', dealId),
        where('organizationId', '==', user.organizationId)
      );
      const querySnapshot = await getDocs(q);
      
      const fetchedActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as DealActivity));

      setActivities(fetchedActivities.sort((a, b) => 
        new Date(b.actionedDate).getTime() - new Date(a.actionedDate).getTime()
      ));
    } catch (error) {
      console.error('Error fetching activities:', error);
    }
  };

  const handleAddActivity = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.organizationId || !dealId) return;

    try {
      const activitiesRef = collection(db, 'activities');
      await addDoc(activitiesRef, {
        ...newActivity,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        organizationId: user.organizationId,
        dealId
      });

      setNewActivity({
        title: '',
        description: '',
        actionedDate: new Date().toISOString(),
        status: 'Completed'
      });
      setIsAddModalOpen(false);
      fetchActivities();
    } catch (error) {
      console.error('Error adding activity:', error);
    }
  };

  const handleUpdateActivity = async (activityId: string, updatedData: Partial<DealActivity>) => {
    if (!user?.organizationId || !dealId) return;

    try {
      const activityRef = doc(db, 'activities', activityId);
      await updateDoc(activityRef, updatedData);
      
      // Update deal stage if changed
      if (updatedData.stage && updatedData.stage !== currentStage && onStageChange) {
        onStageChange(updatedData.stage);
      }

      setEditingActivity(null);
      fetchActivities();
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };

  const handleDeleteActivity = async (activityId: string) => {
    if (!user?.organizationId || !dealId) return;

    try {
      const activityRef = doc(db, 'activities', activityId);
      await deleteDoc(activityRef);
      fetchActivities();
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Invalid Date';
      }
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  const renderActivity = (activity: DealActivity) => {
    const date = activity.actionedDate || activity.createdAt || activity.date;
    
    return (
      <div
        key={activity.id}
        className="flex items-start justify-between p-4 bg-navy-800 rounded-lg mb-2"
      >
        <div className="flex-1">
          <h4 className="text-white text-sm font-medium">{activity.title}</h4>
          {activity.description && (
            <p className="text-gray-400 text-sm mt-1">{activity.description}</p>
          )}
          <div className="flex items-center mt-2">
            <Clock className="h-4 w-4 text-gray-400 mr-1" />
            <span className="text-gray-400 text-sm">
              {formatDate(date)}
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2 ml-4">
          {editingActivity?.id === activity.id ? (
            <>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleUpdateActivity(activity.id, editingActivity)}
                className="text-gray-400 hover:text-white"
              >
                <Check className="h-4 w-4" />
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setEditingActivity(activity)}
                className="text-gray-400 hover:text-white"
              >
                <Edit2 className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleDeleteActivity(activity.id)}
                className="text-gray-400 hover:text-white"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-white">Activities</h2>
        <Button
          onClick={() => setIsAddModalOpen(true)}
          className="flex items-center gap-2"
        >
          <Plus className="h-4 w-4" />
          Add Activity
        </Button>
      </div>

      <div className="space-y-2">
        {activities.map(renderActivity)}
        {activities.length === 0 && (
          <div className="text-center py-8">
            <p className="text-gray-400">No activities yet</p>
          </div>
        )}
      </div>

      {/* Add Activity Modal */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
          <div className="bg-navy-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold text-white mb-4">Add Activity</h3>
            <form onSubmit={handleAddActivity} className="space-y-4">
              <div>
                <label className="text-sm text-gray-400">Title</label>
                <input
                  type="text"
                  value={newActivity.title}
                  onChange={(e) => setNewActivity({ ...newActivity, title: e.target.value })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                  required
                />
              </div>
              <div>
                <label className="text-sm text-gray-400">Description</label>
                <textarea
                  value={newActivity.description}
                  onChange={(e) => setNewActivity({ ...newActivity, description: e.target.value })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                  rows={3}
                />
              </div>
              <div>
                <label className="text-sm text-gray-400">Date</label>
                <DatePicker
                  selected={new Date(newActivity.actionedDate)}
                  onChange={(date) => setNewActivity({ ...newActivity, actionedDate: date?.toISOString() || new Date().toISOString() })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                />
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => setIsAddModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  Add Activity
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Edit Activity Modal */}
      {editingActivity && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
          <div className="bg-navy-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold text-white mb-4">Edit Activity</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleUpdateActivity(editingActivity.id, editingActivity);
            }} className="space-y-4">
              <div>
                <label className="text-sm text-gray-400">Title</label>
                <input
                  type="text"
                  value={editingActivity.title}
                  onChange={(e) => setEditingActivity({ ...editingActivity, title: e.target.value })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                  required
                />
              </div>
              <div>
                <label className="text-sm text-gray-400">Description</label>
                <textarea
                  value={editingActivity.description}
                  onChange={(e) => setEditingActivity({ ...editingActivity, description: e.target.value })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                  rows={3}
                />
              </div>
              <div>
                <label className="text-sm text-gray-400">Date</label>
                <DatePicker
                  selected={new Date(editingActivity.actionedDate)}
                  onChange={(date) => setEditingActivity({ ...editingActivity, actionedDate: date?.toISOString() || new Date().toISOString() })}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white"
                />
              </div>
              <div>
                <label className="text-sm text-gray-400">Deal Stage</label>
                <select
                  value={selectedStage}
                  onChange={(e) => setSelectedStage(e.target.value)}
                  className="w-full bg-navy-900 border border-navy-700 rounded-lg px-3 py-2 text-white mt-1"
                >
                  {stages.map((stage) => (
                    <option key={stage.id} value={stage.id}>
                      {stage.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setSelectedStage(currentStage);
                    setEditingActivity(null);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  Update Activity
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
