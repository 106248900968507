import React, { useState, useEffect, useCallback } from 'react';
import { Search } from 'lucide-react';
import debounce from 'lodash/debounce';

export type TaskFilters = {
  search: string;
  priority: string;
  type: string;
  dueDate: string;
  assigneeId: string;
};

type TaskFiltersProps = {
  filters: TaskFilters;
  onFilterChange: (filters: TaskFilters) => void;
  assignees: Array<{ id: string; name: string; photoURL?: string }>;
};

export const TaskFilters: React.FC<TaskFiltersProps> = ({
  filters,
  onFilterChange,
  assignees,
}) => {
  const [localSearch, setLocalSearch] = useState(filters.search);

  // Create a debounced version of the filter change
  const debouncedSearchChange = useCallback(
    debounce((value: string) => {
      onFilterChange({ ...filters, search: value });
    }, 500),
    [filters, onFilterChange]
  );

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocalSearch(value);
    debouncedSearchChange(value);
  };

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel();
    };
  }, [debouncedSearchChange]);

  return (
    <div className="space-y-4 bg-navy-800 p-4 rounded-lg border border-navy-700">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
        <input
          type="text"
          placeholder="Search tasks..."
          value={localSearch}
          onChange={handleSearchChange}
          className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <select
          value={filters.priority}
          onChange={(e) => onFilterChange({ ...filters, priority: e.target.value })}
          className="bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Priorities</option>
          <option value="low">Low Priority</option>
          <option value="medium">Medium Priority</option>
          <option value="high">High Priority</option>
        </select>

        <select
          value={filters.type}
          onChange={(e) => onFilterChange({ ...filters, type: e.target.value })}
          className="bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Types</option>
          <option value="call">Call</option>
          <option value="email">Email</option>
          <option value="meeting">Meeting</option>
          <option value="task">Task</option>
        </select>

        <select
          value={filters.dueDate}
          onChange={(e) => onFilterChange({ ...filters, dueDate: e.target.value })}
          className="bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Due Dates</option>
          <option value="today">Today</option>
          <option value="overdue">Overdue</option>
          <option value="next7days">Next 7 Days</option>
        </select>

        <select
          value={filters.assigneeId}
          onChange={(e) => onFilterChange({ ...filters, assigneeId: e.target.value })}
          className="bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Assignees</option>
          {assignees && assignees.length > 0 ? (
            assignees.map((assignee) => (
              <option key={assignee.id} value={assignee.id}>
                {assignee.name || 'Unknown User'}
              </option>
            ))
          ) : (
            <option value="" disabled>Loading assignees...</option>
          )}
        </select>
      </div>
    </div>
  );
};
