import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import CountUp from 'react-countup';
import {
  LayoutDashboard,
  Bell,
  Users,
  BarChart3,
  Calendar,
  MessageSquare,
  ChevronRight,
  CheckSquare,
  Users2,
  LineChart,
  TrendingUp,
  DollarSign,
  Target,
  Phone,
  BarChart2,
} from 'lucide-react';
import { FAQSection } from './FAQSection';
import { PricingPage } from './PricingPage';

export const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(value);
  };

  const features = [
    {
      title: "Intelligent Pipeline Management",
      description: "Visualize and optimize your entire sales process with customizable stages, drag-and-drop functionality, and real-time analytics. Track conversion rates and identify bottlenecks instantly.",
      icon: <Users className="w-8 h-8 text-indigo-400" />,
      stats: "30% faster deal closure"
    },
    {
      title: "Advanced Deal Tracking",
      description: "Get a 360° view of your deals with smart tracking, automated updates, and predictive insights. Never miss a follow-up with intelligent reminders and deal scoring.",
      icon: <BarChart3 className="w-8 h-8 text-indigo-400" />,
      stats: "45% improved forecast accuracy"
    },
    {
      title: "Smart Task Management",
      description: "Automate your workflow with AI-powered task prioritization, team assignments, and deadline tracking. Integrate with your calendar for seamless scheduling.",
      icon: <CheckSquare className="w-8 h-8 text-indigo-400" />,
      stats: "25% increase in productivity"
    },
    {
      title: "Real-time Team Collaboration",
      description: "Foster teamwork with shared workspaces, real-time updates, and integrated communication tools. Keep everyone aligned with smart activity feeds and @mentions.",
      icon: <Users2 className="w-8 h-8 text-indigo-400" />,
      stats: "40% better team coordination"
    },
    {
      title: "Actionable Analytics",
      description: "Make data-driven decisions with comprehensive performance reports, activity insights, and customizable dashboards. Track KPIs that matter to your business.",
      icon: <LineChart className="w-8 h-8 text-indigo-400" />,
      stats: "50% better decision making"
    },
    {
      title: "Smart Notifications",
      description: "Stay informed with context-aware notifications, priority alerts, and customizable triggers. Get updates on deals, tasks, and team activities that matter most.",
      icon: <Bell className="w-8 h-8 text-indigo-400" />,
      stats: "20% fewer missed opportunities"
    }
  ];

  const stats = [
    { label: 'Total Revenue', value: 628522 },
    { label: 'Active Users', value: 1182 },
    { label: 'Deals Closed', value: 4890 },
    { label: 'Avg. Deal Size', value: 4877 },
  ];

  return (
    <div className="min-h-screen bg-navy-900 overflow-hidden">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-navy-900/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <svg className="w-8 h-8 text-indigo-500 animate-float" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 9L12 4L21 9L12 14L3 9Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 14L12 19L21 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span className="ml-2 text-xl font-bold text-white">NextClient CRM</span>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => navigate('/signin')}
                className="text-sm font-medium text-gray-300 hover:text-white transition-colors"
              >
                Sign in
              </button>
              <button
                onClick={() => navigate('/signup')}
                className="text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-lg transition-colors"
              >
                Get a demo
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative min-h-[calc(100vh-5rem)] bg-navy-900 flex items-center py-16">
        <div className="mx-auto max-w-7xl w-full px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 items-center">
            {/* Left Content */}
            <div className="lg:col-span-6 mb-12 lg:mb-0">
              <div className="max-w-xl">
                <div className="mb-8 inline-flex items-center rounded-full bg-navy-800/50 p-1.5 pr-4 backdrop-blur-sm border border-indigo-500/20">
                  <span className="rounded-full bg-indigo-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">New</span>
                  <span className="ml-3 text-sm font-medium text-gray-300">AI-Powered Sales Insights</span>
                </div>

                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6 leading-tight">
                  Turn More Conversations Into Closed Deals
                </h1>
                
                <p className="text-lg text-gray-400 mb-8 leading-relaxed">
                  Streamline your sales workflow, automate follow-ups, and close more deals with our intelligent CRM. Get actionable insights and predictive analytics to make data-driven decisions.
                </p>

                <div className="flex flex-col sm:flex-row gap-4">
                  <button
                    onClick={() => navigate('/signup')}
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors duration-200 whitespace-nowrap w-full sm:w-auto"
                  >
                    Start 14-day FREE trial
                  </button>
                  <button
                    onClick={() => navigate('/demo')}
                    className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-gray-300 bg-navy-800 hover:bg-navy-700 rounded-lg border border-navy-700 transition-colors duration-200 whitespace-nowrap w-full sm:w-auto"
                  >
                    Schedule Demo
                  </button>
                </div>
              </div>
            </div>

            {/* Right Content */}
            <div className="lg:col-span-6">
              <div className="relative bg-navy-800/30 backdrop-blur-sm rounded-2xl border border-white/10 p-6">
                <div className="absolute -top-6 -right-6 z-10 bg-navy-800/90 backdrop-blur-sm rounded-xl p-4 border border-white/10 shadow-xl transform hover:-translate-y-1 transition-transform duration-300">
                  <div className="text-xl font-bold text-white mb-1 whitespace-nowrap">
                    <CountUp
                      start={0}
                      end={312}
                      duration={2}
                      suffix="%"
                      delay={0}
                    />
                  </div>
                  <div className="text-sm text-gray-400 whitespace-nowrap">More Sales</div>
                </div>

                {/* Stats Grid */}
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
                  {/* Total Pipeline Value */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-indigo-500/20 flex items-center justify-center mr-3">
                        <DollarSign className="w-5 h-5 text-indigo-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={2547843}
                        duration={2}
                        separator=","
                        prefix="$"
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Total Pipeline Value</div>
                  </div>

                  {/* Won Deals */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-green-500/20 flex items-center justify-center mr-3">
                        <Target className="w-5 h-5 text-green-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={892450}
                        duration={2}
                        separator=","
                        prefix="$"
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Won Deals Value</div>
                  </div>

                  {/* Win Rate */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-blue-500/20 flex items-center justify-center mr-3">
                        <Target className="w-5 h-5 text-blue-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={78.5}
                        duration={2}
                        decimals={1}
                        suffix="%"
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Win Rate</div>
                  </div>

                  {/* Calls Booked */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-purple-500/20 flex items-center justify-center mr-3">
                        <Phone className="w-5 h-5 text-purple-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={342}
                        duration={2}
                        separator=","
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Calls Booked</div>
                  </div>

                  {/* Active Deals */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-yellow-500/20 flex items-center justify-center mr-3">
                        <BarChart2 className="w-5 h-5 text-yellow-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={156}
                        duration={2}
                        separator=","
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Active Deals</div>
                  </div>

                  {/* Follow-ups Scheduled */}
                  <div className="bg-navy-800/50 backdrop-blur-sm rounded-xl p-4 border border-white/10">
                    <div className="flex items-center mb-3">
                      <div className="w-8 h-8 rounded-lg bg-pink-500/20 flex items-center justify-center mr-3">
                        <Calendar className="w-5 h-5 text-pink-500" />
                      </div>
                    </div>
                    <div className="text-2xl font-bold text-white mb-1">
                      <CountUp
                        start={0}
                        end={89}
                        duration={2}
                        separator=","
                        delay={0}
                      />
                    </div>
                    <div className="text-sm text-gray-500">Follow-ups Scheduled</div>
                  </div>
                </div>
              </div>

              {/* Floating Stats Card */}
              
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="relative py-20 bg-navy-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4">
              Everything You Need to Scale Your Sales
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Powerful features designed to help you close more deals, collaborate effectively, and make data-driven decisions.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="group relative bg-navy-800/50 backdrop-blur-sm border border-navy-700 rounded-2xl p-8 hover:border-indigo-500 transition-all duration-300 hover:shadow-lg hover:shadow-indigo-500/10"
              >
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-indigo-500/10 rounded-lg mr-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-white group-hover:text-indigo-400 transition-colors">
                    {feature.title}
                  </h3>
                </div>
                <p className="text-gray-400 mb-4 text-sm leading-relaxed">
                  {feature.description}
                </p>
                <div className="text-indigo-400 text-sm font-medium">
                  {feature.stats}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <PricingPage />

      {/* Stats Section */}
      <div className="relative bg-navy-800/30 border-y border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {stats.map((stat, index) => (
              <div className="text-center animate-fade-in-up" key={index}>
                <div className="text-4xl font-bold text-white">
                  {stat.label === 'Total Revenue' || stat.label === 'Avg. Deal Size' ? '$' : ''}
                  {stat.value}
                </div>
                <div className="mt-2 text-sm text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <FAQSection />

      {/* CTA Section */}
      <div className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-6">
            Ready to transform your sales process?
          </h2>
          <p className="text-xl text-gray-300 mb-10 max-w-2xl mx-auto">
            Join thousands of engineering teams who have already improved their productivity with NextClient CRM. No credit card required.
          </p>
          <button
            onClick={() => navigate('/signup')}
            className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors"
          >
            Start 14-day FREE trial
            <ChevronRight className="ml-2 w-6 h-6" />
          </button>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-navy-800/50 backdrop-blur-sm border-t border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center text-gray-400">
            <p className="mb-4">&copy; 2024 NextClient CRM by <a href="https://jcemedia.com/" className="hover:text-white transition-colors">JCE Media</a>. All rights reserved.</p>
            <div className="space-x-4">
              <Link to="/terms-of-service" className="hover:text-white transition-colors">
                Terms of Service
              </Link>
              <span>·</span>
              <Link to="/privacy-policy" className="hover:text-white transition-colors">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
