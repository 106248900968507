import React from 'react';
import { Check, AlertCircle } from 'lucide-react';
import { Button } from '../ui';
import { useNavigate } from 'react-router-dom';

const features = {
  basic: [
    'Deal Pipeline Management',
    'Basic Task Management',
    'Contact Management',
    'Basic Reporting',
    'Email Support',
    '1 Team Member',
    '100 Deals',
    'Basic Activity Tracking',
  ],
  professional: [
    'Everything in Basic, plus:',
    'Advanced Pipeline Analytics',
    'Task Automation & Workflows',
    'Team Collaboration Tools',
    'Advanced Performance Reports',
    'Activity Insights',
    'Email & Chat Support',
    'Up to 10 Team Members',
    'Unlimited Deals',
    'Custom Fields',
    'File Attachments',
    'Task Analytics',
  ],
  enterprise: [
    'Everything in Professional, plus:',
    'Custom Workflow Automation',
    'Advanced Team Analytics',
    'Priority Support',
    'Dedicated Account Manager',
    'Custom Integrations',
    'Unlimited Team Members',
    'API Access',
    'Advanced Security Features',
    'Custom Training',
    'SLA Guarantees',
    'White-label Options',
  ],
};

const PricingTier: React.FC<{
  name: string;
  price: string;
  period: string;
  description: string;
  features: string[];
  popular?: boolean;
}> = ({ name, price, period, description, features, popular }) => (
  <div className={`relative flex flex-col p-6 bg-navy-800 rounded-2xl border ${
    popular ? 'border-blue-500' : 'border-navy-600'
  }`}>
    {popular && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
          Most Popular
        </span>
      </div>
    )}
    
    <div className="mb-4">
      <h3 className="text-xl font-bold text-white mb-2">{name}</h3>
      <p className="text-gray-400 text-sm">{description}</p>
    </div>

    <div className="mb-6">
      <span className="text-4xl font-bold text-white">{price}</span>
      <span className="text-gray-400 ml-2">{period}</span>
    </div>

    <div className="flex-grow">
      <ul className="space-y-3 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check className="w-5 h-5 text-blue-400 mr-2 mt-0.5 flex-shrink-0" />
            <span className="text-gray-300 text-sm">{feature}</span>
          </li>
        ))}
      </ul>
    </div>

    <Button
      variant={popular ? 'default' : 'outline'}
      size="lg"
      className="w-full"
      onClick={() => navigate('/signup')}
    >
      Start 14-day FREE trial
    </Button>
  </div>
);

const ValueProposition: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
}> = ({ title, description, icon }) => (
  <div className="flex flex-col items-center text-center p-6 bg-navy-800/50 rounded-xl border border-navy-600">
    <div className="mb-4 p-3 bg-blue-500/10 rounded-full">
      {icon}
    </div>
    <h3 className="text-lg font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

export const PricingPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-navy-900 text-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-20">
          <h1 className="text-4xl font-bold mb-4">
            Choose the Perfect Plan for Your Business
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mx-auto">
            Powerful CRM features to help you grow your business, manage deals, and boost team productivity
          </p>
        </div>

        {/* Value Propositions */}
        <div className="grid md:grid-cols-3 gap-8 mb-20">
          <ValueProposition
            title="Pipeline Management"
            description="Visualize and manage your sales pipeline with customizable stages, drag-and-drop functionality, and real-time updates."
            icon={<svg className="w-8 h-8 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"><path d="M3 3h18v18H3z"/><path d="M9 3v18M15 3v18M3 9h18M3 15h18"/></svg>}
          />
          <ValueProposition
            title="Task Automation"
            description="Automate repetitive tasks, set up workflows, and ensure nothing falls through the cracks with smart task management."
            icon={<svg className="w-8 h-8 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"><path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"/></svg>}
          />
          <ValueProposition
            title="Advanced Analytics"
            description="Gain deep insights into your sales performance, team productivity, and customer engagement with comprehensive analytics."
            icon={<svg className="w-8 h-8 text-blue-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"><path d="M21 21H3M21 21V3M21 16l-5-5-4 4-4-4-2 2"/></svg>}
          />
        </div>

        {/* Pricing Tiers */}
        <div className="grid md:grid-cols-3 gap-8">
          <PricingTier
            name="Basic"
            price="$29"
            period="/month"
            description="Perfect for small businesses and startups"
            features={features.basic}
          />
          <PricingTier
            name="Professional"
            price="$79"
            period="/month"
            description="Ideal for growing teams and businesses"
            features={features.professional}
            popular
          />
          <PricingTier
            name="Enterprise"
            price="Custom"
            period="/month"
            description="For large organizations with custom needs"
            features={features.enterprise}
          />
        </div>

        {/* FAQ Section */}
        <div className="mt-20">
          <h2 className="text-2xl font-bold text-center mb-10">
            Frequently Asked Questions
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
              <h3 className="text-lg font-semibold mb-3">
                Can I upgrade or downgrade my plan?
              </h3>
              <p className="text-gray-400">
                Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.
              </p>
            </div>
            <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
              <h3 className="text-lg font-semibold mb-3">
                Is there a free trial?
              </h3>
              <p className="text-gray-400">
                Yes, we offer a 14-day free trial on all plans. No credit card required.
              </p>
            </div>
            <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
              <h3 className="text-lg font-semibold mb-3">
                What kind of support do you offer?
              </h3>
              <p className="text-gray-400">
                We offer email support for Basic plans, and priority email & chat support for Professional and Enterprise plans.
              </p>
            </div>
            <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
              <h3 className="text-lg font-semibold mb-3">
                Can I cancel my subscription?
              </h3>
              <p className="text-gray-400">
                Yes, you can cancel your subscription at any time. We'll prorate any unused time and refund you accordingly.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-20 text-center">
          <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl p-8 md:p-12">
            <h2 className="text-3xl font-bold mb-4">
              Ready to Transform Your Sales Process?
            </h2>
            <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
              Join thousands of businesses using our platform to streamline their sales and boost productivity. No credit card required.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Button size="lg" className="px-8" onClick={() => navigate('/signup')}>
                Start 14-day FREE trial
              </Button>
              <Button size="lg" variant="outline" className="px-8" onClick={() => navigate('/contact')}>
                Schedule Demo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
