import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MessageSquare, Clock, Image as ImageIcon, X, ZoomIn, Upload } from 'lucide-react';
import { useNotesStore } from '../../store/notesStore';
import { useNotificationsStore } from '../../store/notificationsStore';
import { useAuthStore } from '../../store/authStore';
import { useTeamStore } from '../../store/teamStore';
import { format, formatDistanceToNow } from 'date-fns';
import type { Deal } from '../../types/deal';

interface DealNotesProps {
  deal: Deal;
}

interface ImagePreviewModalProps {
  imageUrl: string;
  onClose: () => void;
}

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ imageUrl, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div ref={modalRef} className="relative max-w-4xl max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-4 -right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
        >
          <X className="w-5 h-5" />
        </button>
        <img
          src={imageUrl}
          alt="Full size preview"
          className="max-w-full max-h-[85vh] rounded-lg"
        />
      </div>
    </div>
  );
};

export const DealNotes: React.FC<DealNotesProps> = ({ deal }) => {
  const [newNote, setNewNote] = useState('');
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [fullSizePreview, setFullSizePreview] = useState<string | null>(null);
  const [mentionSearch, setMentionSearch] = useState('');
  const [showMentionSuggestions, setShowMentionSuggestions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const mentionListRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notes, addNote, loading, error, uploadProgress, subscribeToNotes } = useNotesStore();
  const createNotification = useNotificationsStore(state => state.createNotification);
  const user = useAuthStore(state => state.user);
  const teamMembers = useTeamStore(state => state.members);

  // Filter team members based on mention search
  const filteredTeamMembers = useMemo(() => {
    if (!mentionSearch) return teamMembers;
    const searchLower = mentionSearch.toLowerCase();
    return teamMembers.filter(member => 
      member.displayName.toLowerCase().includes(searchLower) ||
      member.email.toLowerCase().includes(searchLower)
    );
  }, [teamMembers, mentionSearch]);

  useEffect(() => {
    const unsubscribe = subscribeToNotes(deal.id);
    return () => {
      unsubscribe();
      // Cleanup preview URLs
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [deal.id]);

  // Handle click outside to close mention suggestions
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (mentionListRef.current && !mentionListRef.current.contains(event.target as Node)) {
        setShowMentionSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;
    setNewNote(newValue);
    setCursorPosition(cursorPos);

    // Check if we should show mention suggestions
    const textBeforeCursor = newValue.slice(0, cursorPos);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');
    
    if (lastAtSymbol !== -1) {
      const textAfterAt = textBeforeCursor.slice(lastAtSymbol + 1);
      const hasSpaceAfterAt = /\s/.test(textAfterAt);
      
      if (!hasSpaceAfterAt) {
        setMentionSearch(textAfterAt);
        setShowMentionSuggestions(true);
        return;
      }
    }
    
    setShowMentionSuggestions(false);
  };

  const insertMention = (member: { displayName: string; email: string }) => {
    const textBeforeCursor = newNote.slice(0, cursorPosition);
    const textAfterCursor = newNote.slice(cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');
    
    if (lastAtSymbol !== -1) {
      const newText = 
        textBeforeCursor.slice(0, lastAtSymbol) + 
        `@${member.displayName} ` + 
        textAfterCursor;
      
      setNewNote(newText);
      setShowMentionSuggestions(false);
      
      // Set focus back to textarea
      if (textareaRef.current) {
        textareaRef.current.focus();
        const newCursorPos = lastAtSymbol + member.displayName.length + 2; // +2 for @ and space
        textareaRef.current.setSelectionRange(newCursorPos, newCursorPos);
        setCursorPosition(newCursorPos);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (showMentionSuggestions) {
      if (e.key === 'Escape') {
        setShowMentionSuggestions(false);
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault(); // Prevent cursor movement in textarea
      }
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setSelectedImages(prev => [...prev, ...files]);
    
    // Create preview URLs
    const newPreviewUrls = files.map(file => URL.createObjectURL(file));
    setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
  };

  const removeImage = (index: number) => {
    setSelectedImages(prev => prev.filter((_, i) => i !== index));
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!newNote.trim() && selectedImages.length === 0) return;

    try {
      // Extract mentions from the note using regex
      const mentionRegex = /@(\w+)/g;
      const mentionMatches = Array.from(newNote.matchAll(mentionRegex));
      
      // Find mentioned users by matching display names
      const mentionedUsers = mentionMatches
        .map(match => {
          const mentionedName = match[1].toLowerCase();
          return teamMembers.find(
            member => member.displayName.toLowerCase().includes(mentionedName) ||
                     member.email.toLowerCase().split('@')[0] === mentionedName
          );
        })
        .filter(user => user !== undefined)
        .map(user => user!.uid);

      // Create the note
      const noteData = {
        content: newNote,
        dealId: deal.id,
        type: 'note' as const,
        createdBy: user?.uid || '',
        createdByName: user?.displayName || user?.email?.split('@')[0] || 'Unknown User',
        organizationId: user?.organizationId || '',
        mentions: mentionedUsers
      };

      await addNote(noteData, selectedImages);

      // Create notifications for mentioned users
      for (const mentionedUserId of mentionedUsers) {
        const mentionedUser = teamMembers.find(m => m.uid === mentionedUserId);
        if (mentionedUser && mentionedUserId !== user?.uid) {
          await createNotification({
            type: 'mention',
            title: `${user?.displayName || 'Someone'} mentioned you in a note`,
            message: newNote,
            dealId: deal.id,
            mentionedUser: mentionedUserId,
            mentionedBy: user?.uid || '',
            sourceId: deal.id,
            sourceType: 'note'
          });
        }
      }

      // Clear the form
      setNewNote('');
      setSelectedImages([]);
      setPreviewUrls([]);
      
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handlePaste = async (e: React.ClipboardEvent) => {
    const items = e.clipboardData.items;
    const imageFiles: File[] = [];

    for (let i = 0; i <items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        if (file) {
          imageFiles.push(file);
        }
      }
    }

    if (imageFiles.length > 0) {
      setSelectedImages(prev => [...prev, ...imageFiles]);
      const newPreviewUrls = imageFiles.map(file => URL.createObjectURL(file));
      setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
    }
  };

  return (
    <div className="bg-navy-900/50 rounded-lg border border-navy-700">
      <div className="px-6 py-4 border-b border-navy-700">
        <h3 className="text-sm font-medium text-white">Notes & Activity</h3>
      </div>
      
      <div className="p-6 space-y-6">
        {/* Note Input */}
        <div className="border border-navy-700 bg-navy-800/50 rounded-lg overflow-hidden relative">
          <textarea
            ref={textareaRef}
            rows={3}
            value={newNote}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            placeholder="Add a note... (Use @ to mention team members)"
            className="block w-full px-4 py-3 bg-transparent border-0 text-white placeholder-gray-400 focus:ring-0 sm:text-sm [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded [&::-webkit-scrollbar-track]:bg-navy-900 [&::-webkit-scrollbar-thumb]:bg-navy-600 hover:[&::-webkit-scrollbar-thumb]:bg-navy-500"
          />

          {/* Mention suggestions dropdown */}
          {showMentionSuggestions && (
            <div 
              ref={mentionListRef}
              className="absolute z-10 mt-1 max-h-60 w-72 overflow-auto rounded-md bg-navy-800 border border-navy-600 py-1 shadow-lg"
            >
              {filteredTeamMembers.length > 0 ? (
                filteredTeamMembers.map((member) => (
                  <button
                    key={member.uid}
                    onClick={() => insertMention(member)}
                    className="w-full text-left px-4 py-2 hover:bg-navy-700 flex items-center space-x-2"
                  >
                    {member.photoURL ? (
                      <img src={member.photoURL} alt="" className="w-6 h-6 rounded-full" />
                    ) : (
                      <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center">
                        <span className="text-white text-sm">
                          {member.displayName.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                    <div>
                      <div className="text-sm text-white">{member.displayName}</div>
                      <div className="text-xs text-gray-400">{member.email}</div>
                    </div>
                  </button>
                ))
              ) : (
                <div className="px-4 py-2 text-sm text-gray-400">
                  No team members found
                </div>
              )}
            </div>
          )}

          {/* Image previews */}
          {previewUrls.length > 0 && (
            <div className="p-3 border-t border-navy-700">
              <div className="flex flex-wrap gap-2">
                {previewUrls.map((url, index) => (
                  <div key={url} className="relative group">
                    <img
                      src={url}
                      alt={`Preview ${index + 1}`}
                      className="h-20 w-20 object-cover rounded-md"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjxyZWN0IHg9IjMiIHk9IjMiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjIiIHJ5PSIyIj48L3JlY3Q+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSIxLjUiPjwvY2lyY2xlPjxwb2x5bGluZSBwb2ludHM9IjIxIDE1IDE2IDEwIDUgMjEiPjwvcG9seWxpbmU+PC9zdmc+';
                        target.classList.add('p-4', 'bg-navy-800');
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <X className="w-3 h-3" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex items-center justify-between px-3 py-2 border-t border-navy-700">
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="inline-flex items-center gap-2 px-3 py-1 text-sm text-gray-400 hover:text-white transition-colors"
            >
              <ImageIcon className="w-4 h-4" />
              Add Images
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageSelect}
              className="hidden"
            />
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!newNote.trim() && selectedImages.length === 0}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Add Note
            </button>
          </div>
        </div>

        {/* Notes List */}
        <div className="space-y-4">
          {notes.map((note) => (
            <div
              key={note.id}
              className="border border-navy-700 bg-navy-800/50 p-4 rounded-lg space-y-2"
            >
              <div className="flex items-start justify-between">
                <div className="flex items-center gap-2">
                  <div className="text-sm text-white">{note.createdByName}</div>
                  <div className="text-xs text-gray-500">
                    {formatDistanceToNow(new Date(note.createdAt), { addSuffix: true })}
                  </div>
                </div>
              </div>
              {note.content && (
                <div className="text-sm text-gray-300 whitespace-pre-wrap">
                  {note.content}
                </div>
              )}
              {note.images && note.images.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {note.images.map((imageUrl, index) => (
                    <img
                      key={index}
                      src={imageUrl}
                      alt={`Note image ${index + 1}`}
                      className="h-20 w-20 object-cover rounded-md cursor-pointer hover:opacity-90 transition-opacity"
                      onClick={() => setFullSizePreview(imageUrl)}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {fullSizePreview && (
        <ImagePreviewModal
          imageUrl={fullSizePreview}
          onClose={() => setFullSizePreview(null)}
        />
      )}
    </div>
  );
};