import React, { useState, useEffect } from 'react';
import { X, Calendar, Clock } from 'lucide-react';
import { collection, query as firestoreQuery, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuthStore } from '../../store/authStore';
import type { Task, TaskType, TaskPriority } from '../../types/task';
import type { Deal } from '../../types/deal';

interface TaskFormProps {
  task?: Task;
  onSubmit: (taskData: Partial<Task>) => Promise<void>;
  onClose: () => void;
}

export const TaskForm: React.FC<TaskFormProps> = ({ task, onSubmit, onClose }) => {
  const { user } = useAuthStore();
  const formatDate = (date: string | Date | any | null | undefined): string => {
    if (!date) return new Date().toISOString().split('.')[0];
    if (date instanceof Date) return date.toISOString().split('.')[0];
    if (typeof date === 'string') return date;
    // Handle Timestamp
    return new Date(date.toDate()).toISOString().split('.')[0];
  };

  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [type, setType] = useState<TaskType>(task?.type || 'call');
  const [priority, setPriority] = useState<TaskPriority>(task?.priority || 'medium');
  const [dueDate, setDueDate] = useState(formatDate(task?.dueDate));
  const [dealSearchQuery, setDealSearchQuery] = useState('');
  const [dealSearchResults, setDealSearchResults] = useState<Deal[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [assignees, setAssignees] = useState<Array<{ id: string; name: string; photoURL?: string }>>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<{ id: string; name: string; photoURL?: string } | null>(
    task?.assigneeId ? {
      id: task.assigneeId,
      name: task.assigneeName || '',
      photoURL: task.assigneePhotoURL
    } : {
      id: user?.uid || '',
      name: user?.displayName || user?.email || '',
      photoURL: user?.photoURL || ''
    }
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDescription(task.description || '');
      setType(task.type);
      setPriority(task.priority);
      setDueDate(formatDate(task.dueDate));
      
      // Set selected deal if it exists
      if (task.dealId && task.dealName) {
        setSelectedDeal({
          id: task.dealId,
          title: task.dealName,
          organizationId: user?.organizationId || ''
        });
      }

      // Set selected assignee if it exists
      if (task.assigneeId && task.assigneeName) {
        setSelectedAssignee({
          id: task.assigneeId,
          name: task.assigneeName,
          photoURL: task.assigneePhotoURL
        });
      }
    }
  }, [task]);

  useEffect(() => {
    if (task?.dealId) {
      const fetchDeal = async () => {
        try {
          const dealsRef = collection(db, 'deals');
          const q = firestoreQuery(dealsRef, where('id', '==', task.dealId));
          const dealDoc = await getDocs(q);
          if (!dealDoc.empty) {
            const dealData = dealDoc.docs[0].data() as Deal;
            setSelectedDeal(dealData);
            setDealSearchQuery(dealData.title);
          }
        } catch (error) {
          console.error('Error fetching deal:', error);
        }
      };
      fetchDeal();
    }
  }, [task?.dealId]);

  useEffect(() => {
    const fetchAssignees = async () => {
      if (!user?.organizationId) return;

      try {
        const usersRef = collection(db, 'users');
        const q = firestoreQuery(usersRef, where('organizationId', '==', user.organizationId));
        const querySnapshot = await getDocs(q);
        const fetchedAssignees = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().displayName || doc.data().email || 'Unknown User',
          photoURL: doc.data().photoURL
        }));
        setAssignees(fetchedAssignees);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };

    fetchAssignees();
  }, [user?.organizationId]);

  const handleDealSearch = async (searchQuery: string) => {
    if (!user?.organizationId || searchQuery.length < 2) {
      setDealSearchResults([]);
      return;
    }
    
    try {
      const dealsRef = collection(db, 'deals');
      const q = firestoreQuery(
        dealsRef,
        where('organizationId', '==', user.organizationId),
        orderBy('title')
      );
      
      const querySnapshot = await getDocs(q);
      const deals = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Deal[];
      
      // Client-side filtering for case-insensitive search
      const filteredDeals = deals.filter(deal => 
        deal.title.toLowerCase().includes(searchQuery.toLowerCase())
      ).slice(0, 5);
      
      setDealSearchResults(filteredDeals);
    } catch (error) {
      console.error('Error searching deals:', error);
      setDealSearchResults([]);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dealSearchQuery) {
        handleDealSearch(dealSearchQuery);
      } else {
        setDealSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [dealSearchQuery, user?.organizationId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const taskData: Partial<Task> = {
        title,
        description,
        type,
        priority,
        dueDate,
        organizationId: user?.organizationId || '',
        assigneeId: selectedAssignee?.id,
        assigneeName: selectedAssignee?.name,
        assigneePhotoURL: selectedAssignee?.photoURL
      };

      // Only add deal fields if a deal is selected
      if (selectedDeal?.id) {
        taskData.dealId = selectedDeal.id;
        taskData.dealName = selectedDeal.title;
      }

      await onSubmit(taskData);
      onClose();
    } catch (error) {
      console.error('Error submitting task:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black/50">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-navy-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              onClick={onClose}
              className="rounded-md text-gray-400 hover:text-gray-300"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300">
                Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                rows={3}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Type
                </label>
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value as TaskType)}
                  className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                >
                  <option value="call">Call</option>
                  <option value="email">Email</option>
                  <option value="demo">Demo</option>
                  <option value="meeting">Meeting</option>
                  <option value="follow_up">Follow Up</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Priority
                </label>
                <select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value as TaskPriority)}
                  className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Due Date
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={dueDate.split('T')[0]}
                    onChange={(e) => {
                      const [_, time] = dueDate.split('T');
                      setDueDate(`${e.target.value}T${time || '00:00'}`);
                    }}
                    className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                    required
                  />
                  <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-white pointer-events-none" />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">
                  Due Time
                </label>
                <div className="relative">
                  <input
                    type="time"
                    value={dueDate.split('T')[1]?.slice(0, 5) || '00:00'}
                    onChange={(e) => {
                      const [date] = dueDate.split('T');
                      setDueDate(`${date}T${e.target.value}`);
                    }}
                    className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                    required
                  />
                  <Clock className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-white pointer-events-none" />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Assignee
              </label>
              <select
                value={selectedAssignee?.id || user?.uid || ''}
                onChange={(e) => {
                  const assignee = assignees.find(a => a.id === e.target.value);
                  setSelectedAssignee(assignee || null);
                }}
                className="mt-1 block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
              >
                {[/* eslint-disable @typescript-eslint/no-unsafe-assignment */
                  { id: user?.uid || '', name: user?.displayName || user?.email || '', photoURL: user?.photoURL },
                  ...assignees.filter(a => a.id !== user?.uid)
                ].map((assignee) => (
                  <option key={assignee.id} value={assignee.id}>
                    {assignee.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300">
                Associated Deal
              </label>
              <div className="relative mt-1">
                <input
                  type="text"
                  value={dealSearchQuery}
                  onChange={(e) => setDealSearchQuery(e.target.value)}
                  placeholder="Search deals..."
                  className="block w-full rounded-md bg-navy-900 border border-navy-700 text-white px-3 py-2"
                />
                {dealSearchResults.length > 0 && (
                  <div className="absolute z-[9999] mt-1 w-full rounded-md bg-navy-900 border border-navy-700 shadow-lg max-h-60 overflow-y-auto" style={{ minWidth: '100%' }}>
                    {dealSearchResults.map((deal) => (
                      <button
                        key={deal.id}
                        type="button"
                        onClick={() => {
                          setSelectedDeal(deal);
                          setDealSearchQuery(deal.title);
                          setDealSearchResults([]);
                        }}
                        className="block w-full px-4 py-2 text-left text-sm hover:bg-navy-700 text-white"
                      >
                        {deal.title}
                      </button>
                    ))}
                  </div>
                )}
                {dealSearchQuery && dealSearchResults.length === 0 && (
                  <div className="absolute z-[9999] mt-1 w-full rounded-md bg-navy-900 border border-navy-700 shadow-lg p-4">
                    <p className="text-sm text-gray-400">No deals found</p>
                  </div>
                )}
              </div>
              {selectedDeal && (
                <div className="mt-2 flex items-center justify-between bg-navy-700/50 rounded px-3 py-2">
                  <span className="text-sm text-white">{selectedDeal.title}</span>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedDeal(null);
                      setDealSearchQuery('');
                    }}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              )}
            </div>

            <div className="mt-5 sm:mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex justify-center rounded-md border border-navy-700 bg-navy-900 px-4 py-2 text-sm font-medium text-gray-300 hover:bg-navy-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 disabled:opacity-50"
              >
                {loading ? 'Saving...' : task ? 'Update Task' : 'Create Task'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
