import React, { useState, useEffect } from 'react';
import { useNotificationsStore } from '../store/notificationsStore';
import { formatDistanceToNow, format } from 'date-fns';
import { Bell, AlertCircle, User, Calendar, Filter, ChevronDown } from 'lucide-react';
import { NotificationType } from '../types/notification';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

const NotificationsPage: React.FC = () => {
  const { notifications, markAsRead, markAllAsRead } = useNotificationsStore();
  const [selectedType, setSelectedType] = useState<NotificationType | 'all'>('all');
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest');
  const [dealTitles, setDealTitles] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDealTitles = async () => {
      const dealIds = notifications
        .filter(n => n.dealId)
        .map(n => n.dealId as string);

      const uniqueDealIds = [...new Set(dealIds)];
      
      const titles: Record<string, string> = {};
      await Promise.all(
        uniqueDealIds.map(async (dealId) => {
          const dealDoc = await getDoc(doc(db, 'deals', dealId));
          if (dealDoc.exists()) {
            titles[dealId] = dealDoc.data().title;
          }
        })
      );
      
      setDealTitles(titles);
    };

    fetchDealTitles();
  }, [notifications]);

  const getNotificationIcon = (type: NotificationType) => {
    switch (type) {
      case 'mention':
        return <User className="w-6 h-6 text-blue-500" />;
      case 'task_due':
        return <Calendar className="w-6 h-6 text-yellow-500" />;
      default:
        return <Bell className="w-6 h-6 text-gray-500" />;
    }
  };

  const filteredNotifications = notifications
    .filter(notification => selectedType === 'all' || notification.type === selectedType)
    .sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });

  const unreadCount = notifications.filter(n => !n.read).length;

  const handleDealClick = (dealId: string, notificationId: string) => {
    // Mark the notification as read when navigating to the deal
    markAsRead(notificationId);
    navigate(`/deals/${dealId}`);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold text-white mb-2">Notifications</h1>
          <p className="text-gray-400">
            You have {unreadCount} unread notification{unreadCount !== 1 ? 's' : ''}
          </p>
        </div>
        {unreadCount > 0 && (
          <button
            onClick={() => markAllAsRead()}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            Mark all as read
          </button>
        )}
      </div>

      <div className="bg-navy-800 rounded-lg shadow-lg border border-white/10">
        <div className="p-4 border-b border-white/10">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="relative">
                <button
                  className="px-4 py-2 bg-navy-700 rounded-md flex items-center space-x-2 hover:bg-navy-600 transition-colors"
                  onClick={() => setSelectedType(type => type === 'all' ? 'mention' : type === 'mention' ? 'task_due' : 'all')}
                >
                  <Filter className="w-4 h-4 text-gray-400" />
                  <span className="text-white capitalize">
                    {selectedType === 'all' ? 'All Types' : selectedType.replace('_', ' ')}
                  </span>
                </button>
              </div>
              <button
                className="px-4 py-2 bg-navy-700 rounded-md flex items-center space-x-2 hover:bg-navy-600 transition-colors"
                onClick={() => setSortOrder(order => order === 'newest' ? 'oldest' : 'newest')}
              >
                <ChevronDown className={`w-4 h-4 text-gray-400 transform transition-transform ${sortOrder === 'oldest' ? 'rotate-180' : ''}`} />
                <span className="text-white capitalize">{sortOrder === 'newest' ? 'Newest First' : 'Oldest First'}</span>
              </button>
            </div>
          </div>
        </div>

        <div className="divide-y divide-white/10">
          {filteredNotifications.length === 0 ? (
            <div className="p-8 text-center">
              <AlertCircle className="w-12 h-12 text-gray-500 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-white mb-2">No notifications found</h3>
              <p className="text-gray-400">
                {selectedType === 'all' 
                  ? "You don't have any notifications yet"
                  : `You don't have any ${selectedType.replace('_', ' ')} notifications`}
              </p>
            </div>
          ) : (
            filteredNotifications.map((notification) => (
              <div
                key={notification.id}
                className={`p-6 hover:bg-navy-700/50 transition-colors ${
                  !notification.read ? 'bg-navy-700/30' : ''
                }`}
              >
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    {getNotificationIcon(notification.type)}
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className={`text-lg ${!notification.read ? 'font-semibold text-white' : 'text-gray-300'}`}>
                          {notification.title}
                        </h3>
                        <p className="text-gray-400 mt-1">{notification.message}</p>
                      </div>
                      {!notification.read && (
                        <button
                          onClick={() => markAsRead(notification.id)}
                          className="px-2 py-1 text-xs bg-blue-500/20 text-blue-400 rounded hover:bg-blue-500/30 transition-colors"
                        >
                          Mark as read
                        </button>
                      )}
                    </div>
                    <div className="mt-2 flex items-center space-x-4">
                      <span className="text-sm text-gray-500">
                        {format(new Date(notification.createdAt), 'MMM d, yyyy h:mm a')}
                      </span>
                      <span className="text-sm text-gray-500">
                        ({formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })})
                      </span>
                    </div>
                    {notification.dealId && dealTitles[notification.dealId] && (
                      <div className="mt-2 flex items-center space-x-4">
                        <span className="text-sm px-3 py-1 bg-emerald-600 text-white rounded-full font-medium">
                          {dealTitles[notification.dealId]}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDealClick(notification.dealId!, notification.id);
                          }}
                          className="text-sm text-blue-400 hover:text-blue-300 cursor-pointer"
                        >
                          View Deal
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
