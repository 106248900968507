import React from 'react';
import { Link } from 'react-router-dom';
import { PublicLayout } from '../../components/layout/PublicLayout';

const TermsOfService: React.FC = () => {
  return (
    <PublicLayout>
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-navy-800/50 backdrop-blur-sm rounded-lg shadow-lg border border-white/10 p-8">
          <h1 className="text-3xl font-bold mb-8 text-white">Terms of Service</h1>
          
          <div className="space-y-6 text-gray-300">
            <section>
              <h2 className="text-xl font-semibold text-white mb-4">1. Introduction</h2>
              <p>Welcome to NextClient CRM ("we," "our," or "us"), a product of JCE Media. By accessing or using our service, you agree to be bound by these Terms of Service.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">2. Use of Service</h2>
              <p>NextClient CRM provides customer relationship management tools and services. You must be at least 18 years old to use this service. You are responsible for maintaining the security of your account credentials.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">3. User Data</h2>
              <p>You retain all rights to your data. We will not share or sell your information without your consent. You are responsible for the accuracy and legality of the data you input into the system.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">4. Acceptable Use</h2>
              <p>You agree not to use NextClient CRM for any unlawful purpose or in any way that could damage, disable, or impair our service.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">5. Intellectual Property</h2>
              <p>The NextClient CRM service, including all associated intellectual property rights, belongs to JCE Media. You may not copy, modify, or create derivative works of our service.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">6. Termination</h2>
              <p>We reserve the right to terminate or suspend access to our service immediately, without prior notice, for any violation of these Terms.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">7. Changes to Terms</h2>
              <p>We may modify these terms at any time. We will notify users of any material changes via email or through the service.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-white mb-4">8. Contact</h2>
              <p>For any questions about these Terms, please contact JCE Media.</p>
            </section>
          </div>

          <div className="mt-8 pt-8 border-t border-white/10">
            <Link to="/privacy-policy" className="text-indigo-400 hover:text-indigo-300 transition-colors">
              View Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export { TermsOfService };
