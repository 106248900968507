import React from 'react';
import clsx from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline' | 'ghost' | 'secondary' | 'danger';
  size?: 'default' | 'sm' | 'lg';
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'default',
  size = 'default',
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={clsx(
        'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 disabled:pointer-events-none disabled:opacity-50',
        {
          'bg-blue-500 text-white hover:bg-blue-600': variant === 'default',
          'border border-gray-600 bg-transparent hover:bg-gray-700/10': variant === 'outline',
          'hover:bg-gray-700/10': variant === 'ghost',
          'bg-navy-700/50 text-white hover:bg-navy-600/50': variant === 'secondary',
          'bg-red-500 text-white hover:bg-red-600': variant === 'danger',
          'h-10 px-4 py-2': size === 'default',
          'h-8 px-3 text-sm': size === 'sm',
          'h-12 px-8': size === 'lg',
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
