import React, { useState, useEffect } from 'react';
import { useKanbanStore } from '../../../store/kanbanStore';
import { useTeamStore } from '../../../store/teamStore';
import { DealInformationForm } from '../forms/DealInformationForm';
import { CompanyInformationForm } from '../forms/CompanyInformationForm';
import { ContactInformationForm } from '../forms/ContactInformationForm';
import { DealOwnerSelect } from '../DealOwnerSelect';
import { DealStatusUpdate } from '../DealStatusUpdate';
import type { Deal } from '../../../types/deal';
import type { CustomFieldValue } from '../../../types/customField';
import { usePipelineStore } from '../../../store/pipelineStore';

interface DealMainFormProps {
  deal: Deal;
  onUnsavedChanges: (hasChanges: boolean) => void;
  loading: boolean;
  onSave?: () => void;
}

export const DealMainForm: React.FC<DealMainFormProps> = ({ 
  deal, 
  onUnsavedChanges, 
  loading,
  onSave 
}) => {
  const [formData, setFormData] = useState({
    title: '',
    value: '',
    company: '',
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    notes: '',
    ownerId: '',
    stage: '',
  });
  const [customFields, setCustomFields] = useState<CustomFieldValue[]>([]);
  const [localUnsavedChanges, setLocalUnsavedChanges] = useState(false);
  const { updateDeal } = useKanbanStore();
  const { members } = useTeamStore();
  const { stages } = usePipelineStore();

  useEffect(() => {
    if (deal) {
      setFormData({
        title: deal.title,
        value: deal.value.toString(),
        company: deal.company,
        contact: deal.contact,
        notes: deal.notes,
        ownerId: deal.ownerId || '',
        stage: deal.stage || stages[0]?.name || '',
      });
      setCustomFields(deal.customFields || []);
      setLocalUnsavedChanges(false);
      onUnsavedChanges(false);
    }
  }, [deal, stages]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log('Stage change:', { name, value });
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    setLocalUnsavedChanges(true);
    onUnsavedChanges(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    try {
      // Convert value to number and ensure it's valid
      const dealValue = formData.value ? parseFloat(formData.value) : 0;
      console.log('DealMainForm value:', {
        formDataValue: formData.value,
        parsedValue: dealValue,
        type: typeof dealValue
      });

      const updateData: Partial<Deal> = {
        title: formData.title || '',
        company: formData.company || '',
        notes: formData.notes || '',
        ownerId: formData.ownerId || '',
        stage: formData.stage,  
        customFields: customFields || [],
        contact: {
          name: formData.contact?.name || '',
          email: formData.contact?.email || '',
          phone: formData.contact?.phone || ''
        },
        value: dealValue
      };

      console.log('Updating deal with stage:', formData.stage);
      await updateDeal(deal.id, updateData);
      setLocalUnsavedChanges(false);
      onUnsavedChanges(false);
      onSave?.();
    } catch (error) {
      console.error('Error updating deal:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Deal Stage */}
      <div>
        <label className="block text-sm text-gray-400 mb-1">Pipeline Stage</label>
        <select
          name="stage"
          value={formData.stage}
          onChange={handleChange}
          className="w-full bg-navy-800 border border-navy-600 rounded-lg px-3 py-2 text-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {stages.map((stage) => (
            <option key={stage.id} value={stage.name}>
              {stage.name}
            </option>
          ))}
        </select>
      </div>

      {/* Deal Information */}
      <DealInformationForm
        title={formData.title}
        value={formData.value}
        customFields={customFields}
        onChange={handleChange}
        onCustomFieldChange={(fieldId, value) => {
          setCustomFields(prev => {
            const existingField = prev.find(cf => cf.fieldId === fieldId);
            if (existingField) {
              return prev.map(cf => cf.fieldId === fieldId ? { ...cf, value } : cf);
            }
            return [...prev, { fieldId, value }];
          });
          setLocalUnsavedChanges(true);
          onUnsavedChanges(true);
        }}
      />

      {/* Company Information */}
      <CompanyInformationForm
        company={formData.company}
        onChange={handleChange}
        customFields={customFields}
        onCustomFieldChange={(fieldId, value) => {
          setCustomFields(prev => {
            const existingField = prev.find(cf => cf.fieldId === fieldId);
            if (existingField) {
              return prev.map(cf => cf.fieldId === fieldId ? { ...cf, value } : cf);
            }
            return [...prev, { fieldId, value }];
          });
          setLocalUnsavedChanges(true);
          onUnsavedChanges(true);
        }}
      />

      {/* Contact Information */}
      <ContactInformationForm
        contact={formData.contact}
        onChange={handleChange}
        onContactAction={(type) => {
          const contact = formData.contact;
          if (type === 'email' && contact.email) {
            window.location.href = `mailto:${contact.email}`;
          } else if (type === 'phone' && contact.phone) {
            window.location.href = `tel:${contact.phone}`;
          }
        }}
        customFields={customFields}
        onCustomFieldChange={(fieldId, value) => {
          setCustomFields(prev => {
            const existingField = prev.find(cf => cf.fieldId === fieldId);
            if (existingField) {
              return prev.map(cf => cf.fieldId === fieldId ? { ...cf, value } : cf);
            }
            return [...prev, { fieldId, value }];
          });
          setLocalUnsavedChanges(true);
          onUnsavedChanges(true);
        }}
      />

      {/* Deal Owner */}
      <div className="bg-navy-900/50 rounded-lg border border-navy-700">
        <div className="px-6 py-4 border-b border-navy-700">
          <h3 className="text-sm font-medium text-white">Deal Owner</h3>
        </div>
        <div className="p-6">
          <DealOwnerSelect
            currentOwnerId={formData.ownerId}
            onOwnerChange={(userId) => {
              setFormData(prev => ({ ...prev, ownerId: userId }));
              setLocalUnsavedChanges(true);
              onUnsavedChanges(true);
            }}
            disabled={loading}
          />
        </div>
      </div>

      {/* Notes */}
      <div>
        <label className="block text-sm text-gray-400 mb-1">Notes</label>
        <textarea
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          rows={4}
          className="w-full bg-navy-800 border border-navy-600 rounded-lg px-3 py-2 text-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Save Changes & Status Update */}
      <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-4 pt-6 border-t border-navy-700">
        <button
          type="submit"
          disabled={loading || !localUnsavedChanges}
          className={`px-6 py-2.5 text-sm font-medium ${
            loading || !localUnsavedChanges
              ? 'bg-navy-700 text-gray-400 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-400'
          }`}
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </button>
        <DealStatusUpdate
          onUpdateStatus={async (status) => {
            await updateDeal(deal.id, { stage: status });
          }}
          loading={loading}
        />
      </div>
    </form>
  );
};