import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { 
  DndContext, 
  DragOverlay,
  MouseSensor, 
  TouchSensor, 
  useSensor, 
  useSensors,
  pointerWithin,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { 
  arrayMove, 
  verticalListSortingStrategy 
} from '@dnd-kit/sortable';
import { KanbanColumn } from './KanbanColumn';
import { KanbanCard } from './KanbanCard';
import { ArchiveToggle } from './ArchiveToggle';
import { useKanbanStore } from '../../store/kanbanStore';
import { useCustomFieldsStore } from '../../store/customFieldsStore';
import { usePipelineStore } from '../../store/pipelineStore';
import { useTeamStore } from '../../store/teamStore';
import { useAuthStore } from '../../store/authStore';
import { Filter, X } from 'lucide-react';
import { Button } from '../ui';
import { Deal } from '../../types/deal';

export const KanbanBoard = () => {
  const { filteredDeals, moveDeal, subscribeToDeals, updateDeal } = useKanbanStore();
  const { subscribeToFields } = useCustomFieldsStore();
  const { stages, loading, subscribeToPipeline } = usePipelineStore();
  const { members, subscribeToMembers } = useTeamStore();
  const { user } = useAuthStore();
  
  const [activeId, setActiveId] = useState<string | null>(null);
  const [selectedMemberId, setSelectedMemberId] = useState<string>('all');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Memoized deals with member filtering
  const deals = useMemo(() => {
    let filteredList = filteredDeals();

    // Filter by team member
    if (selectedMemberId !== 'all') {
      filteredList = filteredList.filter(deal => deal.ownerId === selectedMemberId);
    }

    return filteredList;
  }, [filteredDeals(), selectedMemberId]);

  // Organization members filter
  const organizationMembers = useMemo(() => {
    if (!user?.organizationId) return [];
    return members.filter(member => member.organizationId === user.organizationId);
  }, [members, user?.organizationId]);

  // Subscription effect with proper cleanup
  useEffect(() => {
    const unsubscribeDeals = subscribeToDeals();
    const unsubscribeFields = subscribeToFields();
    const unsubscribePipeline = subscribeToPipeline();
    const unsubscribeMembers = user?.organizationId ? subscribeToMembers(user.organizationId) : () => {};
    
    return () => {
      unsubscribeDeals();
      unsubscribeFields();
      unsubscribePipeline();
      unsubscribeMembers();
    };
  }, [subscribeToDeals, subscribeToFields, subscribeToPipeline, subscribeToMembers, user?.organizationId]);

  // Memoized drag start handler
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  }, []);

  // Memoized drag end handler
  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over || !active) {
      setActiveId(null);
      return;
    }

    const activeStage = active.data.current?.stage as string;
    let overStage = over.data.current?.stage as string;

    // If dropping on a column directly (empty stage)
    if (over.data.current?.type === 'column') {
      overStage = over.id as string;
    }

    // Moving to a different stage
    if (activeStage !== overStage) {
      // Verify the target stage exists
      const targetStage = stages.find(s => s.id === overStage);
      if (!targetStage) {
        console.error('Target stage not found:', overStage);
        setActiveId(null);
        return;
      }
      
      // Find the active deal
      const activeDeal = deals.find(d => d.id === active.id);
      if (!activeDeal) {
        setActiveId(null);
        return;
      }

      // Optimistically update the local state through the store
      const updatedDeal = {
        ...activeDeal,
        stage: overStage,
        stageId: overStage,
        stageName: targetStage.name
      };
      
      // Update local state immediately
      useKanbanStore.setState(state => ({
        deals: state.deals.map(d => d.id === updatedDeal.id ? updatedDeal : d)
      }));

      // Then perform the actual update
      moveDeal(active.id as string, activeStage, overStage)
        .catch(error => {
          console.error('Error moving deal:', error);
          // Revert the optimistic update on error
          useKanbanStore.setState(state => ({
            deals: state.deals.map(d => d.id === activeDeal.id ? activeDeal : d)
          }));
        });
      
      setActiveId(null);
      return;
    }
    
    // Get deals for the current stage
    const stageDeals = deals
      .filter(deal => deal.stage === activeStage)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    
    // If dropping on an empty stage, place at the beginning
    if (stageDeals.length === 0) {
      const activeDeal = deals.find(d => d.id === active.id);
      if (!activeDeal) {
        setActiveId(null);
        return;
      }

      // Optimistically update the order
      const updatedDeal = { ...activeDeal, order: 0 };
      useKanbanStore.setState(state => ({
        deals: state.deals.map(d => d.id === updatedDeal.id ? updatedDeal : d)
      }));

      updateDeal(active.id as string, { 
        order: 0,
        stage: activeStage
      }).catch(error => {
        console.error('Error updating deal order:', error);
        // Revert on error
        useKanbanStore.setState(state => ({
          deals: state.deals.map(d => d.id === activeDeal.id ? activeDeal : d)
        }));
      });
      
      setActiveId(null);
      return;
    }
    
    // Reorder deals within the same stage
    const activeDeal = stageDeals.find(deal => deal.id === active.id);
    const overDeal = stageDeals.find(deal => deal.id === over.id);
    
    if (!activeDeal || !overDeal) {
      setActiveId(null);
      return;
    }

    // Find the indices of the active and over deals
    const oldIndex = stageDeals.findIndex(deal => deal.id === active.id);
    const newIndex = stageDeals.findIndex(deal => deal.id === over.id);

    // Reorder the array
    const reorderedDeals = arrayMove(stageDeals, oldIndex, newIndex);

    // Assign new orders to maintain spacing
    const updatedDeals = reorderedDeals.map((deal, index) => ({
      ...deal,
      order: index * 1000 // Use larger intervals to allow for future insertions
    }));

    // Apply optimistic update
    useKanbanStore.setState(state => ({
      deals: state.deals.map(d => {
        const updatedDeal = updatedDeals.find(ud => ud.id === d.id);
        return updatedDeal || d;
      })
    }));

    // Update the database
    Promise.all(
      updatedDeals.map(deal =>
        updateDeal(deal.id, { order: deal.order })
      )
    ).catch(error => {
      console.error('Error updating deal orders:', error);
      // Revert optimistic updates on error
      useKanbanStore.setState(state => ({
        deals: state.deals.map(d => {
          const originalDeal = stageDeals.find(od => od.id === d.id);
          return originalDeal || d;
        })
      }));
    });
    
    setActiveId(null);
  }, [deals, stages, updateDeal, moveDeal]);

  // Create sensors 
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 10,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  if (loading || stages.length === 0) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="animate-pulse flex space-x-4">
          <div className="h-4 w-24 bg-navy-700 rounded"></div>
          <div className="h-4 w-24 bg-navy-700 rounded"></div>
          <div className="h-4 w-24 bg-navy-700 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="px-4 py-2 bg-navy-900 sticky top-0 z-20 flex items-center justify-between">
        <div className="flex items-center space-x-2 text-sm text-gray-400">
          <span>{deals.length} deals</span>
          <span>•</span>
          <span>${deals.reduce((sum, deal) => sum + (deal.value || 0), 0).toLocaleString()}</span>
        </div>
        <div className="flex items-center space-x-2">
          <Button 
            variant="outline" 
            size="sm"
            className="flex items-center gap-2 bg-navy-800 border-navy-600 text-white hover:bg-navy-700"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            {isFilterOpen ? <X className="h-4 w-4" /> : <Filter className="h-4 w-4" />}
            Team Filter
          </Button>
          <ArchiveToggle />
        </div>
      </div>

      {/* Team Member Filter Dropdown */}
      {isFilterOpen && (
        <div className="px-4 py-2 bg-navy-800 border-b border-navy-600">
          <div className="flex flex-wrap gap-2">
            <Button 
              variant={selectedMemberId === 'all' ? 'default' : 'outline' }
              size="sm"
              onClick={() => setSelectedMemberId('all')}
              className="text-white"
            >
              All Deals
            </Button>
            {organizationMembers.map(member => (
              <Button 
                key={member.uid}
                variant={selectedMemberId === member.uid ? 'default' : 'outline'}
                size="sm"
                onClick={() => setSelectedMemberId(member.uid)}
                className="text-white whitespace-nowrap"
              >
                {member.displayName || member.email}
              </Button>
            ))}
          </div>
        </div>
      )}
      
      <div className="flex-1 p-4 overflow-x-auto overflow-y-auto">
        <DndContext
          collisionDetection={pointerWithin}
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <div className="flex gap-4 min-w-max">
            {stages.map((stage) => {
              const stageDeals = deals.filter((deal) => 
                deal.stageId === stage.id || // Check stageId first
                deal.stage === stage.id || // Fallback to stage field
                deal.stage === stage.name // Legacy support for stage name
              );
              
              console.log('Stage deals:', {
                stageId: stage.id,
                stageName: stage.name,
                dealsCount: stageDeals.length,
                deals: stageDeals.map(d => ({
                  id: d.id,
                  stageId: d.stageId,
                  stage: d.stage,
                  stageName: d.stageName
                }))
              });

              return (
                <KanbanColumn
                  key={stage.id}
                  stage={stage}
                  deals={stageDeals}
                />
              );
            })}
          </div>

          <DragOverlay>
            {activeId ? (
              <KanbanCard
                deal={deals.find((deal) => deal.id === activeId)!}
                stage=""
                index={0}
                isDragging
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
};