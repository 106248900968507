import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useTasksStore } from '../../store/tasksStore';
import { useAuthStore } from '../../store/authStore';
import { useKanbanStore } from '../../store/kanbanStore';
import { useTeamStore } from '../../store/teamStore';
import { Task } from '../../types/task';
import { TaskCard } from '../activity/components/TaskCard';
import { InlineTaskForm } from '../activity/components/InlineTaskForm';
import { TaskForm } from '../activity/TaskForm';
import toast from 'react-hot-toast';
import { NotificationToast } from '../notifications/NotificationToast';

interface DealTasksProps {
  dealId: string;
}

export const DealTasks: React.FC<DealTasksProps> = ({ dealId }) => {
  const { user } = useAuthStore();
  const { 
    tasks: storeTasks, 
    loading: storeLoading, 
    subscribeToAllTasks,
    updateTask,
    addTask,
    deleteTask,
  } = useTasksStore();
  const { deals } = useKanbanStore();
  const { members, subscribeToMembers } = useTeamStore();
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [showInlineForm, setShowInlineForm] = useState(false);
  const [localTasks, setLocalTasks] = useState<Task[]>([]);

  // Subscribe to tasks and team members when component mounts
  useEffect(() => {
    const unsubscribeTasks = subscribeToAllTasks();
    const unsubscribeMembers = subscribeToMembers();
    return () => {
      unsubscribeTasks();
      unsubscribeMembers();
    };
  }, [subscribeToAllTasks, subscribeToMembers]);

  // Update localTasks when storeTasks changes
  useEffect(() => {
    if (storeTasks) {
      const filteredTasks = storeTasks.filter(task => task.dealId === dealId);
      setLocalTasks(filteredTasks);
      setLoading(false);
    }
  }, [storeTasks, dealId]);

  const handleCreateTask = async (taskData: Partial<Task>) => {
    if (!user?.organizationId) return;
    try {
      // Get the deal name
      const deal = deals.find(d => d.id === dealId);
      const dealName = deal?.title;

      // Get the assignee details if assigneeId is present
      let assigneeName, assigneePhotoURL;
      if (taskData.assigneeId) {
        const assignee = members.find(a => a.uid === taskData.assigneeId);
        assigneeName = assignee?.displayName;
        assigneePhotoURL = assignee?.photoURL;
      }

      await addTask({
        ...taskData,
        dealId,
        dealName,
        assigneeName,
        assigneePhotoURL,
        createdBy: user.uid,
        organizationId: user.organizationId,
        status: 'not_started',
        createdAt: new Date().toISOString(),
      } as Task);

      toast.custom((t) => (
        <NotificationToast
          notificationType="task_completed"
          title="Task Created"
          message={`Task "${taskData.title}" has been created successfully`}
        />
      ), {
        duration: 5000,
        position: 'bottom-right'
      });
      
      setShowInlineForm(false);
    } catch (error) {
      console.error('Error creating task:', error);
      toast.error('Failed to create task');
    }
  };

  const handleTaskUpdate = async (taskId: string, updates: Partial<Task>, fromUndo: boolean = false) => {
    try {
      await updateTask(taskId, updates);
      
      // Show toast notification when task status changes
      if ('status' in updates) {
        const task = localTasks.find(t => t.id === taskId);
        const isCompleted = updates.status === 'completed';
        const toastId = toast.custom((t) => (
          <NotificationToast
            notificationType="task_completed"
            title={isCompleted ? "Task Completed" : "Task Reopened"}
            message={`Task "${task?.title}" has been marked as ${isCompleted ? 'complete' : 'incomplete'}`}
            onUndo={async () => {
              toast.dismiss(toastId);
              await handleTaskUpdate(taskId, { 
                status: isCompleted ? 'not_started' : 'completed' 
              }, true); // Pass true to indicate this is an undo action
            }}
          />
        ), {
          duration: 5000,
          position: 'bottom-right'
        });
      }

      // Only close the task form if we're editing task details and it's not an undo action
      if (!('status' in updates) && !fromUndo) {
        setSelectedTask(null);
      }

      // Update local state immediately for better UX
      setLocalTasks(prevTasks => 
        prevTasks.map(task => 
          task.id === taskId ? { ...task, ...updates } : task
        )
      );
    } catch (error) {
      console.error('Error updating task:', error);
      toast.error('Failed to update task');
    }
  };

  const handleTaskDelete = async (taskId: string) => {
    try {
      await deleteTask(taskId);
      toast.success('Task deleted successfully');
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Failed to delete task');
    }
  };

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-navy-900">
      {/* Header */}
      <div className="px-6 py-3 flex items-center justify-between border-b border-navy-800/80 bg-navy-900/50 backdrop-blur-sm">
        <button
          onClick={() => setShowInlineForm(true)}
          className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors"
        >
          <Plus className="w-4 h-4" />
          Add Task
        </button>
      </div>

      {/* Task List */}
      <div className="flex-1 min-h-0 overflow-y-auto">
        {/* Inline Task Form */}
        {showInlineForm && (
          <InlineTaskForm
            onSubmit={handleCreateTask}
            onCancel={() => setShowInlineForm(false)}
            assignees={members.map(m => ({ id: m.uid, name: m.displayName, photoURL: m.photoURL }))}
            defaultAssignee={user ? { id: user.uid, name: user.displayName || '', photoURL: user.photoURL || '' } : undefined}
            dealId={dealId}
            dealName={deals.find(d => d.id === dealId)?.title}
          />
        )}

        {/* Tasks */}
        {localTasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            onToggleComplete={updates => handleTaskUpdate(task.id, updates, false)}
            onSelect={setSelectedTask}
            onDelete={handleTaskDelete}
          />
        ))}
        
        {localTasks.length === 0 && !showInlineForm && (
          <div className="flex flex-col items-center justify-center h-32 text-slate-400">
            <p className="text-sm">No tasks yet</p>
          </div>
        )}
      </div>

      {/* Task Form Dialog */}
      {selectedTask && (
        <TaskForm
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onSubmit={selectedTask.id ? handleTaskUpdate : handleCreateTask}
          onDelete={handleTaskDelete}
          assignees={members.map(m => ({ id: m.uid, name: m.displayName, photoURL: m.photoURL }))}
        />
      )}
    </div>
  );
};
