import React from 'react';
import { Bell, CheckCircle, AlertCircle } from 'lucide-react';
import { NotificationType } from '../../types/notification';

interface NotificationToastProps {
  notificationType: NotificationType | 'task_completed';
  title: string;
  message: string;
  onUndo?: () => void;
}

export const NotificationToast: React.FC<NotificationToastProps> = ({
  notificationType,
  title,
  message,
  onUndo,
}) => {
  const getIcon = () => {
    switch (notificationType) {
      case 'mention':
        return <Bell className="w-5 h-5 text-blue-500" />;
      case 'task_due':
        return <AlertCircle className="w-5 h-5 text-yellow-500" />;
      case 'task_completed':
        return <CheckCircle className="w-5 h-5 text-emerald-500" />;
      case 'error':
        return <AlertCircle className="w-5 h-5 text-red-500" />;
      default:
        return <CheckCircle className="w-5 h-5 text-green-500" />;
    }
  };

  return (
    <div 
      data-toast="true"
      role="status"
      className="flex items-start space-x-3 p-2 bg-navy-800 rounded-lg shadow-lg border border-white/10"
    >
      <div className="flex-shrink-0">{getIcon()}</div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-white">{title}</p>
        <p className="text-sm text-gray-400 truncate">{message}</p>
      </div>
      {onUndo && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onUndo();
          }}
          className="ml-2 px-2 py-1 text-xs font-medium text-indigo-400 hover:text-indigo-300 transition-colors"
        >
          Undo
        </button>
      )}
    </div>
  );
};
