import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { GitBranch, User, Users, ListPlus, Link } from 'lucide-react';

interface SettingsLayoutProps {
  children?: React.ReactNode;
}

export const SettingsLayout: React.FC<SettingsLayoutProps> = ({ children }) => {
  const tabs = [
    { name: 'Profile', href: 'profile' },
    { name: 'Team', href: 'team' },
    { name: 'Pipeline', href: 'pipeline' },
    { name: 'Custom Fields', href: 'fields' },
    { name: 'Integrations', href: 'integrations' },
    { name: 'About', href: 'about' },
  ];

  return (
    <div className="h-full flex bg-navy-900">
      {/* Settings Sidebar */}
      <div className="w-64 bg-navy-800/50 border-r border-navy-700 p-4 flex-shrink-0">
        <h2 className="text-lg font-semibold text-white mb-4">Settings</h2>
        <nav className="space-y-1">
          {tabs.map((tab) => (
            <NavLink
              key={tab.href}
              to={`/settings/${tab.href}`}
              className={({ isActive }) =>
                `flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  isActive
                    ? 'bg-navy-700 text-blue-400'
                    : 'text-gray-300 hover:bg-navy-700/50'
                }`
              }
            >
              {tab.name === 'Profile' && <User className="w-5 h-5 mr-3" />}
              {tab.name === 'Team' && <Users className="w-5 h-5 mr-3" />}
              {tab.name === 'Pipeline' && <GitBranch className="w-5 h-5 mr-3" />}
              {tab.name === 'Custom Fields' && <ListPlus className="w-5 h-5 mr-3" />}
              {tab.name === 'Integrations' && <Link className="w-5 h-5 mr-3" />}
              {tab.name === 'About' && <Link className="w-5 h-5 mr-3" />}
              {tab.name}
            </NavLink>
          ))}
        </nav>
      </div>

      {/* Settings Content */}
      <div className="flex-1 p-8 bg-navy-900/50">
        {children || <Outlet />}
      </div>
    </div>
  );
};