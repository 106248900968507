import React, { useState, useRef, useEffect } from 'react';
import { Task } from '../../../types/task';
import { format, addDays, startOfToday, isToday } from 'date-fns';
import { CalendarIcon, Check, ChevronDown, Search, X } from 'lucide-react';
import { Avatar } from '../../common/Avatar';
import { cn } from '../../../lib/utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useKanbanStore } from '../../../store/kanbanStore';
import { createPortal } from 'react-dom';

// Interface for InlineTaskForm component props
interface InlineTaskFormProps {
  onSubmit: (task: Partial<Task>) => void;
  onCancel: () => void;
  assignees: Array<{ id: string; name: string; photoURL?: string }>;
  defaultAssignee?: { id: string; name: string; photoURL?: string };
  dealId?: string;
  dealName?: string;
}

// PriorityOption component for displaying priority options
const PriorityOption: React.FC<{
  priority: string;
  selected: boolean;
  onClick: () => void;
}> = ({ priority, selected, onClick }) => {
  // Define color scheme for priority levels
  const colors = {
    high: selected ? 'bg-red-500/20 text-red-400' : 'text-red-400 hover:bg-red-500/20',
    medium: selected ? 'bg-amber-500/20 text-amber-400' : 'text-amber-400 hover:bg-amber-500/20',
    low: selected ? 'bg-emerald-500/20 text-emerald-400' : 'text-emerald-400 hover:bg-emerald-500/20',
  };

  return (
    // Render priority option with corresponding color and label
    <div
      onClick={onClick}
      className={cn(
        'px-3 py-1.5 text-left text-sm cursor-pointer flex items-center gap-2',
        colors[priority as keyof typeof colors]
      )}
    >
      <span className="w-2 h-2 rounded-full bg-current" />
      <span>{priority.charAt(0).toUpperCase() + priority.slice(1)}</span>
    </div>
  );
};

// QuickDateOption component for displaying quick date options
const QuickDateOption: React.FC<{
  label: string;
  date: Date;
  selected?: boolean;
  onClick: (date: Date) => void;
}> = ({ label, date, selected, onClick }) => (
  // Render quick date option with corresponding label and styling
  <div
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(date);
    }}
    className={cn(
      "px-3 py-1.5 text-sm cursor-pointer flex items-center gap-2 hover:bg-navy-700/50",
      selected ? "bg-navy-700 text-indigo-400" : "text-white"
    )}
  >
    <CalendarIcon className={cn("w-4 h-4", selected ? "text-indigo-400" : "text-gray-400")} />
    <span>{label}</span>
  </div>
);

// InlineTaskForm component for rendering the task form
export const InlineTaskForm: React.FC<InlineTaskFormProps> = ({
  onSubmit,
  onCancel,
  assignees,
  defaultAssignee,
  dealId,
  dealName,
}) => {
  // Initialize task state with default values
  const [task, setTask] = useState<Partial<Task>>({
    title: '',
    priority: 'medium',
    dueDate: format(new Date(), 'yyyy-MM-dd'),
    assigneeId: defaultAssignee?.id || '',
    status: 'not_started',
    dealId: dealId,
    dealName: dealName,
  });

  // Refs for input focus and click detection
  const titleInputRef = useRef<HTMLInputElement>(null);
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDealSearch, setShowDealSearch] = useState(false);
  const [dealSearchQuery, setDealSearchQuery] = useState('');
  const dealSearchRef = useRef<HTMLDivElement>(null);
  const dealSearchDropdownRef = useRef<HTMLDivElement>(null);
  const datePickerRef = useRef<HTMLDivElement>(null);  // Reference to the trigger button
  const calendarRef = useRef<HTMLDivElement>(null);    // Reference to the calendar popup
  const priorityRef = useRef<HTMLDivElement>(null);
  const priorityDropdownRef = useRef<HTMLDivElement>(null);
  const assigneeRef = useRef<HTMLDivElement>(null);
  const assigneeDropdownRef = useRef<HTMLDivElement>(null);

  // Get deals from kanban store
  const { deals, subscribeToDeals } = useKanbanStore();

  // Subscribe to deals on mount and unsubscribe on unmount
  useEffect(() => {
    const unsubscribe = subscribeToDeals();
    return () => unsubscribe();
  }, [subscribeToDeals]);

  // Filter deals based on search query
  const filteredDeals = deals.filter(deal =>
    deal.title.toLowerCase().includes(dealSearchQuery.toLowerCase())
  );

  // Focus title input on mount
  useEffect(() => {
    titleInputRef.current?.focus();
  }, []);

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (task.title?.trim()) {
      onSubmit(task);
    }
  };

  // Handle key down events for escape and enter keys
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onCancel();
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit(e);
    }
  };

  // Get selected assignee and deal
  const selectedAssignee = assignees.find(a => a.id === task.assigneeId);
  const selectedDeal = deals.find(d => d.id === task.dealId);

  // Get today's date at the start of the day for consistent comparisons
  const today = startOfToday();

  // Handle clicks outside the date picker to close it
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Only close if clicking outside both the trigger and calendar
      if (
        datePickerRef.current && 
        calendarRef.current && 
        !datePickerRef.current.contains(event.target as Node) && 
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false);
      }
    }

    // Add and remove event listener to prevent memory leaks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dealSearchRef.current && 
        dealSearchDropdownRef.current && 
        !dealSearchRef.current.contains(event.target as Node) && 
        !dealSearchDropdownRef.current.contains(event.target as Node)
      ) {
        setShowDealSearch(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        priorityRef.current && 
        priorityDropdownRef.current && 
        !priorityRef.current.contains(event.target as Node) && 
        !priorityDropdownRef.current.contains(event.target as Node)
      ) {
        setShowPriorityDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        assigneeRef.current && 
        assigneeDropdownRef.current && 
        !assigneeRef.current.contains(event.target as Node) && 
        !assigneeDropdownRef.current.contains(event.target as Node)
      ) {
        setShowAssigneeDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    // Render task form with various input fields and dropdowns
    <form
      onSubmit={handleSubmit}
      className="group flex items-center h-12 px-4 gap-2 border-b border-navy-800/50 bg-navy-800/30"
    >
      {/* Status Checkbox - w-[40px] */}
      <div className="w-[40px] flex items-center justify-center">
        <div className="w-5 h-5 border border-navy-600 rounded" />
      </div>

      {/* Title Input - flex-1 */}
      <input
        ref={titleInputRef}
        type="text"
        value={task.title}
        onChange={(e) => setTask({ ...task, title: e.target.value })}
        onKeyDown={handleKeyDown}
        placeholder="Enter task title..."
        className="flex-1 bg-transparent border-none text-white placeholder-gray-400 text-sm focus:outline-none focus:ring-0"
      />

      {/* Date Picker Input Field */}
      <div className="w-[160px] relative">
        {/* Trigger Button */}
        <div
          ref={datePickerRef}
          onClick={() => setShowDatePicker(!showDatePicker)}
          className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-navy-700/50"
        >
          <CalendarIcon className="w-4 h-4 text-gray-400 flex-shrink-0" />
          <span className="text-sm text-white">
            {format(new Date(task.dueDate || today), 'MMM d, yyyy')}
          </span>
          <ChevronDown className="w-4 h-4 text-gray-400 ml-auto" />
        </div>
        
        {/* Calendar Popup - Rendered in a portal to avoid container constraints */}
        {showDatePicker && createPortal(
          <div 
            ref={calendarRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="fixed w-[220px] bg-navy-800 rounded-lg shadow-lg border border-navy-700" 
            style={{ 
              zIndex: 9999,  // High z-index to appear above other elements
              // Position the popup relative to the trigger button
              left: datePickerRef.current?.getBoundingClientRect().left ?? 0,
              top: (datePickerRef.current?.getBoundingClientRect().bottom ?? 0) + 4
            }}
          >
            {/* Quick Date Selection Options */}
            <div 
              className="flex gap-1 p-1 border-b border-navy-700" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <QuickDateOption 
                label="Today" 
                date={today} 
                selected={format(today, 'yyyy-MM-dd') === task.dueDate}
                onClick={(date) => {
                  setTask({ ...task, dueDate: format(date, 'yyyy-MM-dd') });
                  setShowDatePicker(false);
                }} 
              />
              <QuickDateOption 
                label="Tomorrow" 
                date={addDays(today, 1)}
                selected={format(addDays(today, 1), 'yyyy-MM-dd') === task.dueDate}
                onClick={(date) => {
                  setTask({ ...task, dueDate: format(date, 'yyyy-MM-dd') });
                  setShowDatePicker(false);
                }}
              />
            </div>

            {/* Calendar Component with Custom Styling */}
            <div className="p-1">
              {/* Custom styles to match the app theme and ensure proper sizing */}
              <style dangerouslySetInnerHTML={{ __html: `/* ... */`}} />
              {/* DatePicker Component */}
              <DatePicker
                selected={new Date(task.dueDate || today)}
                onChange={(date) => {
                  if (date) {
                    setTask({ ...task, dueDate: format(date, 'yyyy-MM-dd') });
                    setShowDatePicker(false);
                  }
                }}
                inline
                formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
              />
            </div>
          </div>,
          document.body
        )}
      </div>

      {/* Priority Dropdown - w-[120px] */}
      <div className="w-[120px] relative">
        <div
          ref={priorityRef}
          onClick={() => setShowPriorityDropdown(!showPriorityDropdown)}
          className={cn(
            'flex items-center gap-2 px-2 py-1 rounded cursor-pointer',
            {
              'text-red-400': task.priority === 'high',
              'text-amber-400': task.priority === 'medium',
              'text-emerald-400': task.priority === 'low',
            }
          )}
        >
          <span className="w-2 h-2 rounded-full bg-current" />
          <span className="text-sm">
            {task.priority?.charAt(0).toUpperCase() + task.priority?.slice(1)}
          </span>
          <ChevronDown className="w-4 h-4 ml-auto" />
        </div>
        
        {showPriorityDropdown && createPortal(
          <div 
            ref={priorityDropdownRef}
            className="fixed w-32 bg-navy-800 rounded-lg shadow-lg border border-navy-700 py-1" 
            style={{ 
              zIndex: 9999,
              left: priorityRef.current?.getBoundingClientRect().left ?? 0,
              top: (priorityRef.current?.getBoundingClientRect().bottom ?? 0) + 4
            }}
          >
            {['high', 'medium', 'low'].map((priority) => (
              <PriorityOption
                key={priority}
                priority={priority}
                selected={task.priority === priority}
                onClick={() => {
                  setTask({ ...task, priority });
                  setShowPriorityDropdown(false);
                }}
              />
            ))}
          </div>,
          document.body
        )}
      </div>

      {/* Deal Search - w-[160px] */}
      <div className="w-[160px] relative">
        <div
          ref={dealSearchRef}
          onClick={() => setShowDealSearch(!showDealSearch)}
          className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-navy-700/50"
        >
          {selectedDeal ? (
            <>
              <span className="text-sm text-white truncate">{selectedDeal.title}</span>
              <X 
                className="w-4 h-4 text-gray-400 ml-auto hover:text-red-400" 
                onClick={(e) => {
                  e.stopPropagation();
                  setTask({ ...task, dealId: undefined });
                }}
              />
            </>
          ) : (
            <>
              <Search className="w-4 h-4 text-gray-400" />
              <span className="text-sm text-gray-400">Link to deal</span>
              <ChevronDown className="w-4 h-4 text-gray-400 ml-auto" />
            </>
          )}
        </div>
        
        {showDealSearch && createPortal(
          <div 
            ref={dealSearchDropdownRef}
            className="fixed w-64 bg-navy-800 rounded-lg shadow-lg border border-navy-700 py-1" 
            style={{ 
              zIndex: 9999,
              left: dealSearchRef.current?.getBoundingClientRect().left ?? 0,
              top: (dealSearchRef.current?.getBoundingClientRect().bottom ?? 0) + 4
            }}
          >
            <div className="px-3 py-2 border-b border-navy-700">
              <div className="relative">
                <Search className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                <input
                  type="text"
                  value={dealSearchQuery}
                  onChange={(e) => setDealSearchQuery(e.target.value)}
                  placeholder="Search deals..."
                  className="w-full pl-8 pr-3 py-1.5 bg-navy-900 border border-navy-600 rounded text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  autoFocus
                />
              </div>
            </div>
            <div className="max-h-48 overflow-y-auto">
              {filteredDeals.map((deal) => (
                <div
                  key={deal.id}
                  onClick={() => {
                    setTask({ ...task, dealId: deal.id });
                    setShowDealSearch(false);
                    setDealSearchQuery('');
                  }}
                  className="px-3 py-1.5 text-sm text-white hover:bg-navy-700 cursor-pointer"
                >
                  {deal.title}
                </div>
              ))}
              {filteredDeals.length === 0 && (
                <div className="px-3 py-2 text-sm text-gray-400">
                  No deals found
                </div>
              )}
            </div>
          </div>,
          document.body
        )}
      </div>

      {/* Assignee Dropdown - w-[140px] */}
      <div className="w-[140px] relative">
        <div
          ref={assigneeRef}
          onClick={() => setShowAssigneeDropdown(!showAssigneeDropdown)}
          className="flex items-center gap-2 px-2 py-1 rounded cursor-pointer hover:bg-navy-700/50"
        >
          <Avatar
            src={selectedAssignee?.photoURL}
            alt={selectedAssignee?.name || 'Unassigned'}
            size="sm"
          />
          <span className="text-sm text-white truncate">
            {selectedAssignee?.name || 'Unassigned'}
          </span>
          <ChevronDown className="w-4 h-4 text-gray-400 ml-auto" />
        </div>
        
        {showAssigneeDropdown && createPortal(
          <div 
            ref={assigneeDropdownRef}
            className="fixed w-48 bg-navy-800 rounded-lg shadow-lg border border-navy-700 py-1" 
            style={{ 
              zIndex: 9999,
              left: assigneeRef.current?.getBoundingClientRect().left ?? 0,
              top: (assigneeRef.current?.getBoundingClientRect().bottom ?? 0) + 4
            }}
          >
            {assignees.map((assignee) => (
              <div
                key={assignee.id}
                onClick={() => {
                  setTask({ ...task, assigneeId: assignee.id });
                  setShowAssigneeDropdown(false);
                }}
                className="px-3 py-1.5 text-left text-sm text-white hover:bg-navy-700 flex items-center gap-2 cursor-pointer"
              >
                <Avatar src={assignee.photoURL} alt={assignee.name} size="sm" />
                <span>{assignee.name}</span>
              </div>
            ))}
          </div>,
          document.body
        )}
      </div>

      {/* Actions - w-[40px] */}
      <div className="w-[40px] flex items-center justify-center">
        <button
          type="submit"
          className="p-1.5 rounded-md text-emerald-400 hover:bg-emerald-500/20 transition-colors"
        >
          <Check className="w-4 h-4" />
        </button>
      </div>
    </form>
  );
};
