import React, { useState, useEffect } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useTasksStore } from '../../store/tasksStore';
import { Task } from '../../types/task';
import { TaskForm } from './TaskForm';
import { TaskFilters, type TaskFiltersType } from './TaskFilters';
import { TaskService } from './services/TaskService';
import { TaskUtils } from './utils/TaskUtils';
import { TaskListHeader } from './components/TaskListHeader';
import { TaskCard } from './components/TaskCard';
import { TaskCalendarView } from './components/TaskCalendarView';
import { TaskSidePanel } from './components/TaskSidePanel';
import { motion, AnimatePresence } from 'framer-motion';
import { TaskInsights } from './components/TaskInsights';
import { isAfter, isBefore, isToday, startOfDay, addDays, parseISO } from 'date-fns';
import { cn } from '../../lib/utils';
import { toast } from 'react-hot-toast';
import { NotificationToast } from '../notifications/NotificationToast';
import { Plus, Filter } from 'lucide-react';
import { Squares2X2Icon, CalendarIcon } from '@heroicons/react/24/outline';
import { InlineTaskForm } from './components/InlineTaskForm';
import { useKanbanStore } from '../../store/kanbanStore';

type QuickFilterType = 'my-tasks' | 'all' | 'overdue' | 'today' | 'upcoming' | 'completed' | 'no-due-date';
type SortType = 'dueDate-asc' | 'dueDate-desc' | 'priority-asc' | 'priority-desc' | 'title-asc' | 'title-desc' | 'noDueDate';

interface ActivityFeedProps {
  initialDealId?: string;
}

const priorityOrder = {
  high: 3,
  medium: 2,
  low: 1,
  undefined: 0
};

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ initialDealId }) => {
  const { user } = useAuthStore();
  const { tasks: storeTasks, loading: storeLoading, subscribeToAllTasks } = useTasksStore();
  const { deals, subscribeToDeals } = useKanbanStore();
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [viewMode, setViewMode] = useState<'list' | 'calendar'>('list');
  const [quickFilter, setQuickFilter] = useState<QuickFilterType>('my-tasks');
  const [sortBy, setSortBy] = useState<SortType>('dueDate-asc');
  const [assignees, setAssignees] = useState<Array<{ id: string; name: string; photoURL?: string }>>([]);
  const [filters, setFilters] = useState<TaskFiltersType>({
    search: '',
    priority: 'all',
    type: 'all',
    dueDate: 'all',
    assigneeId: 'all',
    category: 'all',
  });
  const [localTasks, setLocalTasks] = useState<Task[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showInlineForm, setShowInlineForm] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<string | undefined>(initialDealId);

  // Set selectedDealId when initialDealId changes
  useEffect(() => {
    if (initialDealId) {
      setSelectedDealId(initialDealId);
    }
  }, [initialDealId]);

  // Subscribe to tasks and deals when component mounts
  useEffect(() => {
    const unsubscribeTasks = subscribeToAllTasks();
    const unsubscribeDeals = subscribeToDeals();
    return () => {
      unsubscribeTasks();
      unsubscribeDeals();
    };
  }, [subscribeToAllTasks, subscribeToDeals]);

  // Update localTasks when storeTasks changes
  useEffect(() => {
    setLocalTasks(storeTasks);
    setLoading(storeLoading);
  }, [storeTasks, storeLoading]);

  // Update assigneeId filter when quickFilter changes
  useEffect(() => {
    if (quickFilter === 'my-tasks' && user?.uid) {
      setFilters(prev => ({ ...prev, assigneeId: user.uid }));
    }
  }, [quickFilter, user?.uid]);

  useEffect(() => {
    if (user?.organizationId) {
      TaskService.fetchAssignees(user.organizationId)
        .then(setAssignees)
        .catch(error => console.error('Error fetching assignees:', error));
    }
  }, [user?.organizationId]);

  const handleTaskUpdate = async (taskId: string, updates: Partial<Task>) => {
    if (!user?.organizationId) return;
    try {
      await TaskService.updateTask(taskId, updates);
      
      // Show toast notification when task status changes
      if ('status' in updates) {
        const task = localTasks.find(t => t.id === taskId);
        const isCompleted = updates.status === 'completed';
        const toastId = toast.custom((t) => (
          <NotificationToast
            notificationType="task_completed"
            title={isCompleted ? "Task Completed" : "Task Reopened"}
            message={`Task "${task?.title}" has been marked as ${isCompleted ? 'complete' : 'incomplete'}`}
            onUndo={async () => {
              toast.dismiss(toastId);
              await handleTaskUpdate(taskId, { 
                status: isCompleted ? 'not_started' : 'completed' 
              });
            }}
          />
        ), {
          duration: 5000, // Increased from default (usually 3000ms)
          position: 'bottom-right'
        });
      }
    } catch (error) {
      console.error('Error updating task:', error);
      toast.error('Failed to update task');
    }
  };

  const handleCreateTask = async (taskData: Partial<Task>) => {
    if (!user?.organizationId) return;
    try {
      // Get the deal name if dealId is present
      let dealName;
      const dealId = selectedDealId || taskData.dealId;
      if (!dealId) {
        toast.custom((t) => (
          <NotificationToast
            notificationType="error"
            title="Failed to Create Task"
            message="Please select a deal before creating a task"
          />
        ), {
          duration: 5000,
          position: 'bottom-right'
        });
        return;
      }
      
      const deal = deals.find(d => d.id === dealId);
      dealName = deal?.title;

      // Get the assignee details if assigneeId is present
      let assigneeName, assigneePhotoURL;
      if (taskData.assigneeId) {
        const assignee = assignees.find(a => a.id === taskData.assigneeId);
        assigneeName = assignee?.name;
        assigneePhotoURL = assignee?.photoURL;
      }

      await TaskService.createTask({
        ...taskData,
        dealName,
        assigneeName,
        assigneePhotoURL,
        createdBy: user.uid,
        organizationId: user.organizationId,
        status: 'not_started',
        createdAt: new Date().toISOString(),
      }, user.organizationId);
      setShowInlineForm(false);
      toast.custom((t) => (
        <NotificationToast
          notificationType="task_created"
          title="Task Created"
          message={`Task "${taskData.title}" has been created successfully`}
        />
      ), {
        duration: 5000,
        position: 'bottom-right'
      });
    } catch (error) {
      console.error('Error creating task:', error);
      toast.custom((t) => (
        <NotificationToast
          notificationType="error"
          title="Failed to Create Task"
          message="An error occurred while creating the task. Please try again."
        />
      ), {
        duration: 5000,
        position: 'bottom-right'
      });
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    if (!user?.organizationId) return;
    try {
      await TaskService.deleteTask(taskId);
      setSelectedTask(null);
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const filteredTasks = localTasks.filter(task => {
    const today = startOfDay(new Date());
    const nextWeek = addDays(today, 7);
    const dueDate = task.dueDate ? new Date(task.dueDate) : null;

    // Apply user-specific filter for all views except 'no-due-date'
    if (quickFilter !== 'no-due-date') {
      if (task.assigneeId !== user?.uid) return false;
    }

    switch (quickFilter) {
      case 'my-tasks':
        return (
          task.status !== 'completed' && 
          dueDate && 
          (isToday(dueDate) || 
           (isBefore(dueDate, nextWeek) && isAfter(dueDate, today)) ||
           isBefore(dueDate, today))
        );
      case 'all':
        // Show all tasks assigned to the user, including completed ones
        return true;
      case 'overdue':
        return dueDate && isBefore(dueDate, today) && task.status !== 'completed';
      case 'today':
        return dueDate && isToday(dueDate);
      case 'upcoming':
        return dueDate && isAfter(dueDate, today) && task.status !== 'completed';
      case 'completed':
        return task.status === 'completed';
      case 'no-due-date':
        return !task.dueDate;
      default:
        return task.status !== 'completed';
    }
  });

  const sortTasks = (tasksToSort: Task[]): Task[] => {
    return [...tasksToSort].sort((a, b) => {
      if (sortBy === 'noDueDate') {
        if (!a.dueDate && b.dueDate) return -1;
        if (a.dueDate && !b.dueDate) return 1;
        return 0;
      }

      const [field, direction] = sortBy.split('-');
      const multiplier = direction === 'asc' ? 1 : -1;

      switch (field) {
        case 'dueDate':
          if (!a.dueDate && !b.dueDate) return 0;
          if (!a.dueDate) return 1;
          if (!b.dueDate) return -1;
          return multiplier * (new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());

        case 'priority': {
          const priorityOrder = { high: 3, medium: 2, low: 1, undefined: 0 };
          const aPriority = (a.priority || '').toLowerCase();
          const bPriority = (b.priority || '').toLowerCase();
          return multiplier * ((priorityOrder[aPriority] || 0) - (priorityOrder[bPriority] || 0));
        }

        case 'title':
          return multiplier * (a.title || '').localeCompare(b.title || '');

        default:
          return 0;
      }
    });
  };

  const sortedAndFilteredTasks = sortTasks(filteredTasks);

  const getFilterButtonClass = (filter: QuickFilterType) => {
    return `px-4 py-2 rounded-lg text-sm font-medium transition-all ${
      quickFilter === filter
        ? 'bg-indigo-500/20 text-indigo-400 border border-indigo-500/30 shadow-[0_0_15px_rgba(99,102,241,0.1)]'
        : 'bg-navy-800/50 text-slate-400 hover:bg-navy-800 hover:text-indigo-300 border border-transparent'
    }`;
  };

  return (
    <div className="flex-1 flex flex-col min-h-0 bg-navy-900">
      {/* Combined Header with Quick Filters */}
      <div className="px-6 py-3 flex items-center justify-between border-b border-navy-800/80 bg-navy-900/50 backdrop-blur-sm">
        {/* Left side - Add Task + Quick Filters */}
        <div className="flex items-center gap-3">
          <button
            onClick={() => setShowInlineForm(true)}
            className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-indigo-500 text-white text-sm font-medium rounded-lg hover:bg-indigo-600 transition-colors"
          >
            <Plus className="w-4 h-4" />
            Add Task
          </button>

          <div className="h-6 w-px bg-navy-800/80" /> {/* Divider */}

          <div className="flex items-center gap-2">
            <button
              onClick={() => setQuickFilter('my-tasks')}
              className={getFilterButtonClass('my-tasks')}
            >
              My Tasks
            </button>
            <button
              onClick={() => setQuickFilter('today')}
              className={getFilterButtonClass('today')}
            >
              Today
            </button>
            <button
              onClick={() => setQuickFilter('upcoming')}
              className={getFilterButtonClass('upcoming')}
            >
              Upcoming
            </button>
            <button
              onClick={() => setQuickFilter('overdue')}
              className={getFilterButtonClass('overdue')}
            >
              Overdue
            </button>
            <button
              onClick={() => setQuickFilter('completed')}
              className={getFilterButtonClass('completed')}
            >
              Completed
            </button>
            <button
              onClick={() => setQuickFilter('all')}
              className={getFilterButtonClass('all')}
            >
              All My Tasks
            </button>
            <button
              onClick={() => setQuickFilter('no-due-date')}
              className={getFilterButtonClass('no-due-date')}
            >
              No Due Date
            </button>
          </div>
        </div>

        {/* Right side - View toggles and filter */}
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2 bg-navy-800/80 rounded-lg p-1">
            <button
              onClick={() => setViewMode('list')}
              className={`p-1.5 rounded-md transition-all ${
                viewMode === 'list'
                  ? 'bg-indigo-500/20 text-indigo-400 shadow-[0_0_15px_rgba(99,102,241,0.1)]'
                  : 'text-slate-400 hover:text-indigo-300'
              }`}
            >
              <Squares2X2Icon className="w-5 h-5" />
            </button>
            <button
              onClick={() => setViewMode('calendar')}
              className={`p-1.5 rounded-md transition-all ${
                viewMode === 'calendar'
                  ? 'bg-indigo-500/20 text-indigo-400 shadow-[0_0_15px_rgba(99,102,241,0.1)]'
                  : 'text-slate-400 hover:text-indigo-300'
              }`}
            >
              <CalendarIcon className="w-5 h-5" />
            </button>
          </div>

          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`p-1.5 rounded-md transition-all ${
              showFilters
                ? 'bg-indigo-500/20 text-indigo-400 shadow-[0_0_15px_rgba(99,102,241,0.1)]'
                : 'text-slate-400 hover:text-indigo-300'
            }`}
          >
            <Filter className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Filter Panel */}
      {showFilters && (
        <div className="px-6 py-3 border-b border-navy-800/80 bg-navy-900/50">
          <TaskFilters
            filters={filters}
            onFilterChange={setFilters}
            assignees={assignees}
          />
        </div>
      )}

      {/* Task List with Header */}
      {viewMode === 'list' && (
        <>
          <TaskListHeader
            sortBy={sortBy}
            onSortChange={setSortBy}
          />
          <div className="flex-1 min-h-0 overflow-y-auto">
            {/* Inline Task Form */}
            {showInlineForm && (
              <InlineTaskForm
                onSubmit={handleCreateTask}
                onCancel={() => setShowInlineForm(false)}
                assignees={assignees}
                defaultAssignee={assignees.find(a => a.id === user?.uid)}
              />
            )}

            {/* Task List */}
            {sortedAndFilteredTasks.map((task) => (
              <TaskCard
                key={task.id}
                task={task}
                onToggleComplete={(updates) => handleTaskUpdate(updates.id, { status: updates.status })}
                onSelect={setSelectedTask}
                onDelete={handleDeleteTask}
              />
            ))}
            
            {sortedAndFilteredTasks.length === 0 && (
              <div className="flex flex-col items-center justify-center h-full text-slate-400">
                <p className="text-sm">No tasks found</p>
              </div>
            )}
          </div>
        </>
      )}

      {/* Calendar View */}
      {viewMode === 'calendar' && (
        <TaskCalendarView
          tasks={sortedAndFilteredTasks}
          onTaskSelect={setSelectedTask}
          onTaskUpdate={handleTaskUpdate}
          onTaskDelete={handleDeleteTask}
        />
      )}

      {/* Task Form Dialog */}
      {selectedTask && (
        <TaskForm
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onSubmit={selectedTask.id ? handleTaskUpdate : handleCreateTask}
          onDelete={handleDeleteTask}
          assignees={assignees}
        />
      )}
    </div>
  );
};
