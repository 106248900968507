import React, { useState, useEffect, useMemo } from 'react';
import { useTeamStore } from '../../store/teamStore';
import { useTasksStore } from '../../store/tasksStore';
import { useAuthStore } from '../../store/authStore';
import { format, subDays, isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import { CheckCircle2, Clock, AlertCircle, UserCheck } from 'lucide-react';
import { Button } from '../ui';

type DateRange = '7' | '30' | '90' | 'all';

interface TaskMetrics {
  total: number;
  completed: number;
  overdue: number;
  completionRate: number;
}

interface UserTaskMetrics extends TaskMetrics {
  userId: string;
  userName: string;
  userEmail: string;
  recentTasks: Array<{
    id: string;
    title: string;
    dueDate: string;
    status: string;
  }>;
}

export const TaskAnalytics = () => {
  const [dateRange, setDateRange] = useState<DateRange>('30');
  const [selectedUserId, setSelectedUserId] = useState<string>('all');
  const { user } = useAuthStore();
  const { members } = useTeamStore();
  const { tasks, subscribeToAllTasks } = useTasksStore();

  useEffect(() => {
    const unsubscribe = subscribeToAllTasks();
    return () => unsubscribe();
  }, [subscribeToAllTasks]);

  const filteredTasks = useMemo(() => {
    if (!user?.organizationId) return [];

    let filtered = tasks.filter(task => task.organizationId === user.organizationId);

    if (selectedUserId !== 'all') {
      filtered = filtered.filter(task => task.assigneeId === selectedUserId);
    }

    if (dateRange !== 'all') {
      const days = parseInt(dateRange);
      const startDate = startOfDay(subDays(new Date(), days));
      const endDate = endOfDay(new Date());

      filtered = filtered.filter(task => {
        const taskDate = new Date(task.createdAt);
        return isWithinInterval(taskDate, { start: startDate, end: endDate });
      });
    }

    return filtered;
  }, [tasks, user?.organizationId, selectedUserId, dateRange]);

  const userMetrics = useMemo(() => {
    const metrics: UserTaskMetrics[] = members.map(member => {
      const userTasks = filteredTasks.filter(task => task.assigneeId === member.uid);
      const completed = userTasks.filter(task => task.status === 'completed').length;
      const overdue = userTasks.filter(task => {
        const dueDate = new Date(task.dueDate);
        return task.status !== 'completed' && dueDate < new Date();
      }).length;

      return {
        userId: member.uid,
        userName: member.displayName || member.email,
        userEmail: member.email,
        total: userTasks.length,
        completed,
        overdue,
        completionRate: userTasks.length > 0 ? (completed / userTasks.length) * 100 : 0,
        recentTasks: userTasks
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .slice(0, 5)
          .map(task => ({
            id: task.id,
            title: task.title,
            dueDate: task.dueDate,
            status: task.status === 'completed' ? 'Completed' : 'Pending'
          }))
      };
    });

    return metrics.sort((a, b) => b.completionRate - a.completionRate);
  }, [members, filteredTasks]);

  const overallMetrics = useMemo(() => {
    const completed = filteredTasks.filter(task => task.status === 'completed').length;
    return {
      total: filteredTasks.length,
      completed,
      overdue: filteredTasks.filter(task => {
        const dueDate = new Date(task.dueDate);
        return task.status !== 'completed' && dueDate < new Date();
      }).length,
      completionRate: filteredTasks.length > 0 ? (completed / filteredTasks.length) * 100 : 0
    };
  }, [filteredTasks]);

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Task Analytics</h1>
        <p className="text-gray-400">Track task completion and team productivity</p>
      </div>

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-8">
        <select
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value as DateRange)}
          className="bg-navy-700 text-white border border-navy-600 rounded-md px-4 py-2"
        >
          <option value="7">Last 7 Days</option>
          <option value="30">Last 30 Days</option>
          <option value="90">Last 90 Days</option>
          <option value="all">All Time</option>
        </select>

        <select
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
          className="bg-navy-700 text-white border border-navy-600 rounded-md px-4 py-2"
        >
          <option value="all">All Team Members</option>
          {members.map(member => (
            <option key={member.uid} value={member.uid}>
              {member.displayName || member.email}
            </option>
          ))}
        </select>
      </div>

      {/* Overall Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <CheckCircle2 className="w-5 h-5 text-blue-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Total Tasks</h3>
          </div>
          <p className="text-3xl font-bold text-white">{overallMetrics.total}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <UserCheck className="w-5 h-5 text-green-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Completed</h3>
          </div>
          <p className="text-3xl font-bold text-white">{overallMetrics.completed}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <Clock className="w-5 h-5 text-orange-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Overdue</h3>
          </div>
          <p className="text-3xl font-bold text-white">{overallMetrics.overdue}</p>
        </div>

        <div className="bg-navy-800 rounded-lg p-6 border border-navy-600">
          <div className="flex items-center mb-4">
            <AlertCircle className="w-5 h-5 text-purple-500 mr-2" />
            <h3 className="text-lg font-semibold text-white">Completion Rate</h3>
          </div>
          <p className="text-3xl font-bold text-white">
            {overallMetrics.completionRate.toFixed(1)}%
          </p>
        </div>
      </div>

      {/* User Metrics */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {userMetrics.map(metrics => (
          <div key={metrics.userId} className="bg-navy-800 rounded-lg p-6 border border-navy-600">
            <div className="flex justify-between items-start mb-6">
              <div>
                <h3 className="text-lg font-semibold text-white mb-1">{metrics.userName}</h3>
                <p className="text-gray-400 text-sm">{metrics.userEmail}</p>
              </div>
              <div className="text-right">
                <p className="text-2xl font-bold text-white mb-1">
                  {metrics.completionRate.toFixed(1)}%
                </p>
                <p className="text-gray-400 text-sm">Completion Rate</p>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 mb-6">
              <div>
                <p className="text-sm text-gray-400">Total Tasks</p>
                <p className="text-lg font-semibold text-white">{metrics.total}</p>
              </div>
              <div>
                <p className="text-sm text-gray-400">Completed</p>
                <p className="text-lg font-semibold text-green-500">{metrics.completed}</p>
              </div>
              <div>
                <p className="text-sm text-gray-400">Overdue</p>
                <p className="text-lg font-semibold text-orange-500">{metrics.overdue}</p>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold text-gray-400 mb-3">Recent Tasks</h4>
              <div className="space-y-2">
                {metrics.recentTasks.map(task => (
                  <div key={task.id} className="flex justify-between items-center">
                    <p className="text-white truncate flex-1">{task.title}</p>
                    <span className={`text-sm px-2 py-1 rounded ${
                      task.status === 'Completed'
                        ? 'bg-green-500/20 text-green-500'
                        : 'bg-orange-500/20 text-orange-500'
                    }`}>
                      {task.status}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
