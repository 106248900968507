import React from 'react';
import { ArrowUp, ArrowDown } from 'lucide-react';
import { cn } from '../../../lib/utils';

type SortType = 'dueDate-asc' | 'dueDate-desc' | 'priority-asc' | 'priority-desc' | 'title-asc' | 'title-desc' | 'noDueDate';

interface Column {
  key: string;
  label: string;
  width: string;
  sortable?: boolean;
}

const columns: Column[] = [
  { key: 'status', label: '', width: 'w-[40px]' },
  { key: 'title', label: 'Title', width: 'flex-1', sortable: true },
  { key: 'dueDate', label: 'Due Date', width: 'w-[140px]', sortable: true },
  { key: 'priority', label: 'Priority', width: 'w-[100px]', sortable: true },
  { key: 'deal', label: 'Deal', width: 'w-[160px]' },
  { key: 'assignee', label: 'Assignee', width: 'w-[140px]' },
  { key: 'actions', label: '', width: 'w-[40px]' },
];

interface TaskListHeaderProps {
  sortBy: SortType;
  onSortChange: (sort: SortType) => void;
}

export const TaskListHeader: React.FC<TaskListHeaderProps> = ({
  sortBy,
  onSortChange,
}) => {
  const getSortIcon = (field: string) => {
    if (!sortBy.startsWith(field)) return null;
    return sortBy.endsWith('asc') ? (
      <ArrowUp className="w-3.5 h-3.5" />
    ) : (
      <ArrowDown className="w-3.5 h-3.5" />
    );
  };

  const handleSort = (field: string) => {
    if (field === 'dueDate') {
      const nextSort = (() => {
        switch (sortBy) {
          case 'dueDate-asc': return 'dueDate-desc';
          case 'dueDate-desc': return 'noDueDate';
          case 'noDueDate': return 'dueDate-asc';
          default: return 'dueDate-desc';
        }
      })();
      onSortChange(nextSort as SortType);
      return;
    }

    if (sortBy.startsWith(field)) {
      onSortChange(sortBy.endsWith('asc')
        ? `${field}-desc` as SortType
        : `${field}-asc` as SortType
      );
    } else {
      onSortChange(`${field}-asc` as SortType);
    }
  };

  return (
    <div className="sticky top-0 z-10 bg-navy-900 border-b border-navy-700">
      <div className="flex items-center h-10 px-4 gap-2 text-sm font-medium text-slate-400">
        {columns.map((column) => (
          <div
            key={column.key}
            className={cn(
              "flex items-center gap-1",
              column.width,
              column.sortable && 'cursor-pointer hover:text-slate-300'
            )}
            onClick={() => column.sortable && handleSort(column.key)}
          >
            {column.label}
            {column.sortable && getSortIcon(column.key)}
          </div>
        ))}
      </div>
    </div>
  );
};
