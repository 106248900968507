import React, { useState } from 'react';
import { usePipelineStore } from '../../store/pipelineStore';
import { Plus, GripVertical, Edit2, Trash2, Check, X, HelpCircle } from 'lucide-react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '../../components/ui';

interface StageToDelete {
  id: string;
  name: string;
}

interface SortableStageItemProps {
  id: string;
  name: string;
  onDelete: (id: string, name: string) => void;
  onEdit: (id: string, name: string) => void;
}

const SortableStageItem: React.FC<SortableStageItemProps> = ({
  id,
  name,
  onDelete,
  onEdit,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSave = () => {
    if (editedName.trim()) {
      onEdit(id, editedName.trim());
      setIsEditing(false);
    }
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="group bg-navy-800/50 hover:bg-navy-700/50 rounded-lg mb-2 last:mb-0"
    >
      <div className="flex items-center justify-between p-3">
        <div className="flex items-center flex-1 min-w-0">
          <div {...attributes} {...listeners} className="cursor-grab p-1 hover:bg-navy-700/50 rounded">
            <GripVertical className="w-4 h-4 text-gray-400" />
          </div>
          {isEditing ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              className="ml-3 flex-1 rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-1 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Stage name"
              autoFocus
            />
          ) : (
            <span className="ml-3 text-sm text-white truncate">{name}</span>
          )}
        </div>
        <div className="flex items-center gap-2 ml-4">
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                className="p-1 text-emerald-400 hover:text-emerald-300 hover:bg-navy-700/50 rounded"
                title="Save"
              >
                <Check className="w-4 h-4" />
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className="p-1 text-gray-400 hover:text-gray-300 hover:bg-navy-700/50 rounded"
                title="Cancel"
              >
                <X className="w-4 h-4" />
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => setIsEditing(true)}
                className="p-1 text-indigo-400 hover:text-indigo-300 hover:bg-navy-700/50 rounded opacity-0 group-hover:opacity-100 transition-opacity"
                title="Edit"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={() => onDelete(id, name)}
                className="p-1 text-red-400 hover:text-red-300 hover:bg-navy-700/50 rounded opacity-0 group-hover:opacity-100 transition-opacity"
                title="Delete"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const PipelineSettings = () => {
  const { stages, deleteStage, updateStages, reorderStages } = usePipelineStore();
  const [isAddingStage, setIsAddingStage] = useState(false);
  const [newStageName, setNewStageName] = useState('');
  const [stageToDelete, setStageToDelete] = useState<StageToDelete | null>(null);
  const [targetStageId, setTargetStageId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = stages.findIndex((stage) => stage.id === active.id);
      const newIndex = stages.findIndex((stage) => stage.id === over.id);

      const newOrder = arrayMove(stages, oldIndex, newIndex);
      try {
        await reorderStages(newOrder.map(stage => stage.id));
      } catch (err: any) {
        console.error('Error reordering stages:', err);
        setError('Failed to reorder stages. Please try again.');
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!newStageName.trim()) return;

    const newStage = {
      id: Math.random().toString(36).substr(2, 9),
      name: newStageName.trim(),
      color: '#818CF8'
    };

    updateStages([...stages, newStage]);
    setNewStageName('');
    setIsAddingStage(false);
  };

  const handleDeleteStage = async (id: string, name: string) => {
    setStageToDelete({ id, name });
    setTargetStageId('');
    setError(null);
  };

  const confirmDeleteStage = async () => {
    if (!stageToDelete) return;

    try {
      await deleteStage(stageToDelete.id, targetStageId || undefined);
      setStageToDelete(null);
      setTargetStageId('');
      setError(null);
    } catch (err: any) {
      if (err.message === 'DEALS_EXIST') {
        setError('Please select a stage to move existing deals to before deleting this stage.');
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-semibold text-white">Pipeline Stages</h2>
              <div className="group relative">
                <button className="p-1 hover:bg-navy-700/50 rounded-full transition-colors">
                  <HelpCircle className="w-5 h-5 text-gray-400 group-hover:text-white transition-colors" />
                </button>
                <div className="absolute left-0 top-full mt-2 w-96 bg-navy-800 rounded-lg p-4 shadow-lg invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 z-50">
                  <div className="text-sm text-gray-300">
                    <p className="font-medium text-white mb-2">Customize your sales pipeline stages:</p>
                    <ul className="space-y-2 list-disc ml-4 mb-3">
                      <li>Drag and drop stages to reorder them</li>
                      <li>Hover over a stage to edit or delete it</li>
                      <li>Keep stage names short and clear (max 20 characters recommended)</li>
                    </ul>
                    <div className="bg-navy-700/50 p-3 rounded-md">
                      <p className="font-medium text-white mb-2">Example Stage Names:</p>
                      <div className="grid grid-cols-2 gap-2 text-xs">
                        <div>
                          <p className="text-emerald-400">✓ Good Examples:</p>
                          <ul className="ml-3 space-y-1 text-gray-300">
                            <li>• Lead In</li>
                            <li>• Negotiation</li>
                            <li>• Proposal Sent</li>
                            <li>• Closed Won</li>
                          </ul>
                        </div>
                        <div>
                          <p className="text-red-400">✗ Avoid:</p>
                          <ul className="ml-3 space-y-1 text-gray-300">
                            <li>• Very Long Stage Name That Is Hard To Read</li>
                            <li>• Stage 1</li>
                            <li>• ???</li>
                            <li>• TBD</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-sm text-gray-400">Drag and drop to reorder stages</p>
          </div>
        </div>
        <Button
          onClick={() => setIsAddingStage(true)}
          className="flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Stage
        </Button>
      </div>

      {/* Add Stage Form - Shown at the top when active */}
      {isAddingStage && (
        <div className="mb-6 bg-navy-800/50 rounded-lg p-4 border border-indigo-500/20">
          <form onSubmit={handleSubmit} className="space-y-3">
            <div>
              <div className="flex items-center justify-between mb-1">
                <label htmlFor="stageName" className="text-sm font-medium text-gray-300">Stage Name</label>
                <span className="text-xs text-gray-400">{newStageName.length}/20 characters</span>
              </div>
              <div className="flex gap-3">
                <input
                  id="stageName"
                  type="text"
                  value={newStageName}
                  onChange={(e) => setNewStageName(e.target.value)}
                  className="flex-1 rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="e.g., Lead In, Negotiation"
                  maxLength={30}
                  autoFocus
                />
                <Button type="submit" disabled={!newStageName.trim() || newStageName.length > 20}>Add Stage</Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsAddingStage(false);
                    setNewStageName('');
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="mt-2 flex items-start gap-2">
                <HelpCircle className="w-4 h-4 text-gray-400 mt-0.5 flex-shrink-0" />
                <p className="text-xs text-gray-400">
                  Use clear, concise names that describe the stage in your sales process. 
                  Keep it under 20 characters for better visibility in the kanban board.
                </p>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* Stage List */}
      <div className="bg-navy-900/50 rounded-lg p-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={stages.map(stage => stage.id)}
            strategy={verticalListSortingStrategy}
          >
            {stages.length === 0 ? (
              <div className="text-center py-8 text-gray-400">
                No stages yet. Click "Add Stage" to create your first pipeline stage.
              </div>
            ) : (
              stages.map((stage) => (
                <SortableStageItem
                  key={stage.id}
                  id={stage.id}
                  name={stage.name}
                  onDelete={(id, name) => handleDeleteStage(id, name)}
                  onEdit={(id, name) => {
                    const updatedStages = stages.map(s =>
                      s.id === id ? { ...s, name } : s
                    );
                    updateStages(updatedStages);
                  }}
                />
              ))
            )}
          </SortableContext>
        </DndContext>
      </div>

      {/* Delete Stage Modal */}
      {stageToDelete && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-navy-800 rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold text-white mb-2">Delete Stage</h3>
            <p className="text-gray-300 mb-4">
              Are you sure you want to delete the stage "{stageToDelete.name}"?
            </p>

            {error && (
              <div className="mb-4 p-3 bg-red-900/50 border border-red-700 rounded text-red-200 text-sm">
                {error}
              </div>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Move existing deals to:
              </label>
              <select
                value={targetStageId}
                onChange={(e) => setTargetStageId(e.target.value)}
                className="w-full rounded-md bg-navy-900/50 border border-navy-700 text-white px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select a stage</option>
                {stages
                  .filter(s => s.id !== stageToDelete.id)
                  .map(stage => (
                    <option key={stage.id} value={stage.id}>
                      {stage.name}
                    </option>
                  ))
                }
              </select>
            </div>

            <div className="flex justify-end gap-3">
              <Button
                variant="secondary"
                onClick={() => {
                  setStageToDelete(null);
                  setTargetStageId('');
                  setError(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={confirmDeleteStage}
              >
                Delete Stage
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PipelineSettings;