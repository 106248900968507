import React, { useMemo, useState } from 'react';
import type { Deal } from '../../types/deal';
import { DollarSign } from 'lucide-react';
import { DealDetailsModal } from '../deals/modals/DealDetailsModal';

interface TopDealsProps {
  deals: Deal[];
}

export const TopDeals: React.FC<TopDealsProps> = ({ deals }) => {
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);

  const topDeals = useMemo(() => {
    return deals
      .filter(deal => deal.stage !== 'Closed Lost' && !deal.archived)
      .sort((a, b) => b.value - a.value)
      .slice(0, 5); // Get top 5 deals
  }, [deals]);

  const handleDealClick = (deal: Deal) => {
    setSelectedDeal(deal);
  };

  if (topDeals.length === 0) {
    return (
      <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
        <h3 className="text-lg font-semibold text-white mb-4">Top Deals</h3>
        <p className="text-gray-400 text-center py-4">No active deals found</p>
      </div>
    );
  }

  return (
    <>
      <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
        <h3 className="text-lg font-semibold text-white mb-6">Top Deals</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {topDeals.map((deal, index) => (
            <div 
              key={deal.id}
              onClick={() => handleDealClick(deal)}
              className="flex items-center justify-between p-4 bg-navy-800 rounded-lg border border-navy-600 hover:border-indigo-500 hover:bg-navy-700/50 transition-colors cursor-pointer"
            >
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium text-indigo-400">#{index + 1}</span>
                  <h4 className="text-sm font-medium text-white truncate">{deal.title}</h4>
                </div>
                <div className="mt-1 flex items-center gap-2">
                  <span className="text-xs text-gray-400">{deal.company}</span>
                  <span className="text-xs text-gray-500">•</span>
                  <span className="text-xs text-gray-400">{deal.stage}</span>
                </div>
              </div>
              <div className="flex items-center gap-1 text-white font-medium">
                <DollarSign className="w-4 h-4 text-gray-400" />
                {deal.value.toLocaleString()}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Deal Details Modal */}
      {selectedDeal && (
        <DealDetailsModal
          isOpen={!!selectedDeal}
          onClose={() => setSelectedDeal(null)}
          deal={selectedDeal}
        />
      )}
    </>
  );
};
