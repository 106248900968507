import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { PipelineDocument, PipelineStage } from '../types/Workflow';
import { useAuthStore } from '../store/authStore';

// Default stages in case of any fetch errors
const DEFAULT_STAGES: PipelineStage[] = [
  { id: 'stage1', name: 'Prospecting', order: 1, color: '#3B82F6' },
  { id: 'stage2', name: 'Qualification', order: 2, color: '#10B981' },
  { id: 'stage3', name: 'Proposal', order: 3, color: '#F59E0B' },
  { id: 'stage4', name: 'Negotiation', order: 4, color: '#6366F1' },
  { id: 'stage5', name: 'Closed Won', order: 5, color: '#22C55E' },
  { id: 'stage6', name: 'Closed Lost', order: 6, color: '#EF4444' }
];

export const usePipeline = () => {
  const [pipeline, setPipeline] = useState<PipelineDocument | null>(null);
  const [stages, setStages] = useState<PipelineStage[]>(DEFAULT_STAGES);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuthStore();

  useEffect(() => {
    const fetchPipeline = async () => {
      if (!user) {
        console.error('No user found when fetching pipeline');
        setError('No user found');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching pipeline for organization:', user.organizationId);

        // Get the pipeline document directly using the organization ID
        const pipelineDoc = doc(db, 'pipelines', user.organizationId);
        const pipelineSnap = await getDoc(pipelineDoc);

        console.log('Pipeline fetch result:', {
          exists: pipelineSnap.exists(),
          id: pipelineSnap.id
        });

        if (pipelineSnap.exists()) {
          const pipelineData = pipelineSnap.data() as PipelineDocument;
          console.log('Found pipeline data:', pipelineData);
          
          setPipeline({
            id: pipelineSnap.id,
            ...pipelineData
          });
          
          // Use pipeline stages or fallback to default
          if (pipelineData.stages?.length) {
            console.log('Using pipeline stages:', pipelineData.stages);
            setStages(pipelineData.stages);
          } else {
            console.log('No stages found in pipeline, using defaults');
            setStages(DEFAULT_STAGES);
          }
        } else {
          console.warn('No pipeline found for organization. Using hardcoded default stages.');
          setStages(DEFAULT_STAGES);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching pipeline:', err);
        const errorMessage = err instanceof Error ? err.message : String(err);
        setError(`Failed to fetch pipeline: ${errorMessage}`);
        
        console.log('Using default stages due to error');
        setStages(DEFAULT_STAGES);
        setLoading(false);
      }
    };

    fetchPipeline();
  }, [user]);

  return {
    pipeline,
    stages,
    loading,
    error,
    // Add a method to reset to default stages if needed
    resetToDefaultStages: () => setStages(DEFAULT_STAGES)
  };
};
