import { collection, query, where, orderBy, getDocs, doc, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { Task } from '../../../types/task';
import { startOfDay, endOfDay, addDays } from 'date-fns';
import { TaskFilters } from '../TaskFilters';

/**
 * Service class for handling all Firebase operations related to tasks
 */
export class TaskService {
  /**
   * Fetches tasks based on provided filters and organization ID
   */
  static async fetchTasks(organizationId: string, filters: TaskFilters) {
    const tasksRef = collection(db, 'tasks');
    let queryConstraints: any[] = [
      where('organizationId', '==', organizationId),
      orderBy('dueDate', 'asc')
    ];

    // Apply filters
    if (filters.priority !== 'all') {
      queryConstraints.push(where('priority', '==', filters.priority));
    }

    if (filters.type !== 'all') {
      queryConstraints.push(where('type', '==', filters.type));
    }

    if (filters.assigneeId !== 'all') {
      queryConstraints.push(where('assigneeId', '==', filters.assigneeId));
    }

    // Due date filter
    const now = new Date();
    if (filters.dueDate === 'today') {
      queryConstraints = [
        ...queryConstraints,
        where('dueDate', '>=', startOfDay(now).toISOString()),
        where('dueDate', '<=', endOfDay(now).toISOString())
      ];
    } else if (filters.dueDate === 'overdue') {
      queryConstraints.push(where('dueDate', '<', startOfDay(now).toISOString()));
    } else if (filters.dueDate === 'next7days') {
      queryConstraints = [
        ...queryConstraints,
        where('dueDate', '>=', now.toISOString()),
        where('dueDate', '<=', addDays(now, 7).toISOString())
      ];
    }

    const q = query(tasksRef, ...queryConstraints);
    const querySnapshot = await getDocs(q);
    let tasks = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Task[];

    // Apply search filter client-side
    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      tasks = tasks.filter(task => 
        task.title.toLowerCase().includes(searchLower) ||
        task.description?.toLowerCase().includes(searchLower) ||
        task.dealName?.toLowerCase().includes(searchLower)
      );
    }

    // Fetch deal names for tasks with dealId
    return await Promise.all(
      tasks.map(async task => {
        if (task.dealId) {
          try {
            const dealDoc = await getDoc(doc(db, 'deals', task.dealId));
            if (dealDoc.exists()) {
              const dealData = dealDoc.data();
              return {
                ...task,
                dealName: dealData.name || dealData.title || 'Unnamed Deal'
              };
            }
          } catch (error) {
            console.error(`Error fetching deal ${task.dealId}:`, error);
          }
        }
        return task;
      })
    );
  }

  /**
   * Creates a new task
   */
  static async createTask(taskData: Partial<Task>, organizationId: string) {
    const newTask = {
      ...taskData,
      status: 'pending',
      organizationId,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    return await addDoc(collection(db, 'tasks'), newTask);
  }

  /**
   * Updates an existing task
   */
  static async updateTask(taskId: string, taskData: Partial<Task>) {
    const taskRef = doc(db, 'tasks', taskId);
    const updateData = {
      ...taskData,
      updatedAt: serverTimestamp(),
    };

    // Remove any undefined values
    Object.keys(updateData).forEach(key => 
      updateData[key] === undefined && delete updateData[key]
    );

    await updateDoc(taskRef, updateData);
    const docSnap = await getDoc(taskRef);
    if (!docSnap.exists()) throw new Error('Task not found');
    return {
      id: docSnap.id,
      ...docSnap.data()
    } as Task;
  }

  /**
   * Deletes a task
   */
  static async deleteTask(taskId: string) {
    await deleteDoc(doc(db, 'tasks', taskId));
  }

  /**
   * Fetches assignees for the organization
   */
  static async fetchAssignees(organizationId: string) {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('organizationId', '==', organizationId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().displayName || doc.data().email || 'Unknown User',
      photoURL: doc.data().photoURL
    }));
  }
}
