import React from 'react';
import { format } from 'date-fns';
import { Task } from '../../../types/task';
import { Checkbox } from '../../ui/Checkbox';
import { Badge } from '../../ui/Badge';
import { MoreVertical, Trash2 } from 'lucide-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Avatar } from '../../common/Avatar';
import { cn } from '../../../lib/utils';

interface TaskCardProps {
  task: Task;
  onToggleComplete: (updates: Partial<Task>) => void;
  onSelect: (task: Task) => void;
  onDelete: (taskId: string) => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({
  task,
  onToggleComplete,
  onSelect,
  onDelete,
}) => {
  const getPriorityColor = (priority: string | undefined) => {
    if (!priority) return 'bg-gray-500/20 text-gray-400 border-gray-500';
    
    switch (priority.toLowerCase()) {
      case 'high':
        return 'bg-red-500/20 text-red-400 border-red-500';
      case 'medium':
        return 'bg-amber-500/20 text-amber-400 border-amber-500';
      case 'low':
        return 'bg-emerald-500/20 text-emerald-400 border-emerald-500';
      default:
        return 'bg-gray-500/20 text-gray-400 border-gray-500';
    }
  };

  const formatDueDate = (date: string | Date) => {
    try {
      return format(new Date(date), 'MMM d, yyyy');
    } catch {
      return 'No date';
    }
  };

  return (
    <div 
      className={cn(
        "group flex items-center h-12 px-4 gap-2 border-b border-navy-800/50 hover:bg-navy-800/50 transition-colors cursor-pointer",
        task.status === 'completed' && "bg-navy-900/30"
      )} 
      onClick={() => onSelect(task)}
    >
      {/* Status Checkbox */}
      <div className="w-[40px] flex items-center">
        <Checkbox
          checked={task.status === 'completed'}
          onCheckedChange={() => onToggleComplete({ 
            id: task.id,
            status: task.status === 'completed' ? 'not_started' : 'completed' 
          })}
          onClick={(e) => e.stopPropagation()}
          className="data-[state=checked]:bg-indigo-500 data-[state=checked]:border-indigo-500"
        />
      </div>

      {/* Title */}
      <div className="flex-1 flex items-center">
        <span className={cn(
          "text-sm truncate",
          task.status === 'completed' ? "line-through text-slate-500" : "text-slate-200"
        )}>
          {task.title}
        </span>
      </div>

      {/* Due Date */}
      <div className="w-[140px] flex items-center">
        <span className="text-sm text-slate-400">
          {task.dueDate ? formatDueDate(task.dueDate) : 'No due date'}
        </span>
      </div>

      {/* Priority */}
      <div className="w-[100px] flex items-center">
        <span className={`text-xs px-2 py-1 rounded-md border ${getPriorityColor(task.priority)}`}>
          {task.priority || 'None'}
        </span>
      </div>

      {/* Deal */}
      <div className="w-[160px] flex items-center">
        <span className="text-sm text-slate-400 truncate">
          {task.dealName || 'No Deal Associated'}
        </span>
      </div>

      {/* Assignee */}
      <div className="w-[140px] flex items-center gap-2">
        {task.assigneePhotoURL ? (
          <>
            <Avatar
              src={task.assigneePhotoURL}
              alt={task.assigneeName || ''}
              size="sm"
            />
            <span className="text-sm text-slate-400 truncate">
              {task.assigneeName}
            </span>
          </>
        ) : (
          <>
            <div className="w-6 h-6 rounded-full bg-navy-700 flex items-center justify-center">
              <span className="text-xs text-slate-400">
                {task.assigneeName?.charAt(0) || '?'}</span>
            </div>
            <span className="text-sm text-slate-400 truncate">
              {task.assigneeName || 'Unassigned'}
            </span>
          </>
        )}
      </div>

      {/* Actions */}
      <div className="w-[40px] flex items-center justify-end">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button
              className="p-1 rounded-md opacity-0 group-hover:opacity-100 hover:bg-navy-700 text-slate-400 hover:text-slate-300 transition-all"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical className="w-4 h-4" />
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            className="min-w-[160px] rounded-md bg-navy-800 border border-navy-700 shadow-lg p-1 z-[100]"
            sideOffset={5}
            align="end"
            onCloseAutoFocus={(e) => e.preventDefault()}
          >
            <DropdownMenu.Item
              className="flex items-center gap-2 px-2 py-1.5 text-sm text-red-500 hover:text-red-400 hover:bg-navy-700 rounded-sm outline-none cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(task.id);
              }}
            >
              <Trash2 className="w-4 h-4" />
              Delete
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};
