import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where 
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { WorkflowDocument } from '../types/Workflow';

// Utility to add days to a date
const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export interface DealChangeEvent {
  dealId: string;
  organizationId: string;
  fromStage: string;
  toStage: string;
  type: 'DEAL_STAGE_CHANGED';
  deal: any; // The full deal object
}

export async function triggerWorkflow(dealChangeEvent: DealChangeEvent) {
  try {
    console.log('Triggering workflows for deal change:', dealChangeEvent);

    // Find workflows for the organization
    const workflowsRef = collection(db, 'workflows');
    const q = query(
      workflowsRef, 
      where('organizationId', '==', dealChangeEvent.organizationId),
      where('isActive', '==', true)
    );

    console.log('Querying workflows for organization:', dealChangeEvent.organizationId);
    const workflowSnapshot = await getDocs(q);
    console.log('Found workflows:', workflowSnapshot.docs.length);

    // Filter workflows that match the stage change
    const matchingWorkflows = workflowSnapshot.docs.filter(doc => {
      const workflow = doc.data() as WorkflowDocument;
      console.log('Checking workflow:', {
        id: doc.id,
        name: workflow.name,
        triggers: workflow.triggers
      });
      
      // Check if any trigger matches this stage change
      return workflow.triggers?.some(trigger => {
        if (trigger.type !== 'deal_stage_change') return false;

        const matches = trigger.conditions?.some(condition => {
          console.log('Checking condition:', {
            sourcePipelineStageId: condition.sourcePipelineStageId,
            targetStage: dealChangeEvent.toStage,
            matches: condition.sourcePipelineStageId === dealChangeEvent.toStage
          });
          return condition.sourcePipelineStageId === dealChangeEvent.toStage;
        });

        console.log('Trigger matches:', matches);
        return matches;
      });
    });

    console.log('Matching workflows:', matchingWorkflows.length);

    // Create workflow executions for matching workflows
    const now = new Date().toISOString();
    const executions = await Promise.all(matchingWorkflows.map(async doc => {
      const workflow = doc.data() as WorkflowDocument;
      
      // Create tasks for each workflow task
      const tasks = workflow.tasks.map(task => ({
        ...task,
        dueDate: addDays(new Date(), task.relativeDay).toISOString(),
        dealId: dealChangeEvent.dealId,
        organizationId: dealChangeEvent.organizationId,
        status: 'pending' as const
      }));

      // Add workflow execution record
      const executionRef = await addDoc(collection(db, 'workflow_executions'), {
        workflowId: doc.id,
        dealId: dealChangeEvent.dealId,
        organizationId: dealChangeEvent.organizationId,
        startedAt: now,
        status: 'in_progress',
        tasks
      });

      console.log('Created workflow execution:', executionRef.id);
      return executionRef.id;
    }));

    console.log('Created workflow executions:', executions);
    return executions;
  } catch (error) {
    console.error('Error triggering workflows:', error);
    throw error;
  }
}

// Listener for deal stage changes
export function setupDealStageChangeListener() {
  // This would typically be set up in a React effect or separate service
  // Note: In a real implementation, you'd use onSnapshot for real-time updates
  // This is a placeholder for how you might implement the listener
  return {
    startListening: () => {
      console.log('Deal stage change listener started');
      // Implement real-time listener logic here
    },
    stopListening: () => {
      console.log('Deal stage change listener stopped');
      // Implement listener cleanup
    }
  };
}
