import React from 'react';
import { Archive } from 'lucide-react';
import { useKanbanStore } from '../../store/kanbanStore';

export const ArchiveToggle = () => {
  const { showArchived, setShowArchived } = useKanbanStore();

  return (
    <button
      onClick={() => setShowArchived(!showArchived)}
      className={`flex items-center justify-center px-3 py-1.5 rounded-md border ${
        showArchived 
          ? 'bg-indigo-600 text-white border-indigo-500'
          : 'bg-transparent text-gray-400 border-navy-600 hover:border-indigo-500 hover:text-indigo-400'
      } transition-colors whitespace-nowrap text-sm`}
    >
      <Archive className="h-4 w-4 mr-1.5" />
      {showArchived ? 'Hide Archived' : 'Show Archived'}
    </button>
  );
};
