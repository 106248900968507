import React from 'react';
import { cn } from '../../lib/utils';

interface AvatarProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'size'> {
  src?: string | null;
  alt: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const sizeClasses = {
  sm: 'w-6 h-6 text-xs',
  md: 'w-8 h-8 text-sm',
  lg: 'w-10 h-10 text-base'
};

export const Avatar: React.FC<AvatarProps> = ({ 
  src, 
  alt, 
  className = '', 
  size = 'md',
  ...props 
}) => {
  const initials = alt
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  const baseClasses = cn(
    'rounded-full',
    sizeClasses[size],
    className
  );

  if (!src) {
    return (
      <div
        className={cn(
          'flex items-center justify-center bg-purple-500 text-white',
          baseClasses
        )}
        {...props}
      >
        <span className="font-medium">{initials}</span>
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={cn('object-cover', baseClasses)}
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.style.display = 'none';
        const parent = target.parentElement;
        if (parent) {
          const fallback = document.createElement('div');
          fallback.className = cn(
            'flex items-center justify-center bg-purple-500 text-white',
            baseClasses
          );
          const span = document.createElement('span');
          span.className = 'font-medium';
          span.textContent = initials;
          fallback.appendChild(span);
          parent.appendChild(fallback);
        }
      }}
      {...props}
    />
  );
};
