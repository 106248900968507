class NotificationSoundService {
  private audio: HTMLAudioElement;

  constructor() {
    this.audio = new Audio('data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4Ljc2LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAAEAAABVgANTU1NTU1Q0NDQ0NDUFBQUFBQXl5eXl5ea2tra2tra3l5eXl5eYaGhoaGhpSUlJSUlKGhoaGhoaGvr6+vr6+8vLy8vLzKysrKysrX19fX19fX5eXl5eXl8vLy8vLy////////AAAAAExhdmM1OC4xMwAAAAAAAAAAAAAAACQCgAAAAAAAAAVY82AhbwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/+MYxAALACwAAP/AADwQKVE9YWDGPkQWpT66yk4+zIiYPoTUaT3tnU+NFRUWQKXjaEwdLqM1oi5kiLoGhcy/k8amP1JVmxSVPr/2/+MYxA8L0DU0AP+0ANkwmYaAb5UZ6lvNHFoS5gqwGyGnWeCnLg3iqP0uWqP+mla2j/70rv/6ulYWHhP+W/ri/48carX/ilrX/+MYxBoK4DVcAP8WAO95h4ZedNR5eH/tfP3OPnf//U/63///+wMt5/yv///9M/s0Wi5UrnGxUNOEZnYcDhc7CwsA/+MYxCkJ4DVcAP8eABABYOF0kJ2R/+T2SP/v/+fqdE0nv//6///+bPMzEVvX/+VGQev//3MPMu5GABgAYEMYYPgA/+MYxDwKKDVkAP8eAA4UAaLIAHKv/+v/3//+81p/+7vt/+78qQD5swYY8WgPMCnG4YXBGDAoYDQMKCwGL/+MYxE4KUDVsAP8WAIRAY8WQAAZBGCwYLf/rI///1/y7/9v+z//v/iU/s0AJB4YYEMm4YXCwYYDEBgoYAAoG/+MYxF8KCDVwAP8WAAA4YDQMQGDwYMAAoYDEBgoYAAoYDAAKGAxAYMAAoYDEBgoYAAoYDAAKGAwAYMBiAwYD/+MYxHEJyDV0AP7MAA4YAAoYDAAKGAwACBgMQGDAYgMGAwAYMBiAwYDAAYMBiAwYDEBgwGIDAAYM/+MYxH4KKDVwAP8WAAwYDEBgwGIDAAYMBiAwYDEBgwGIDAAYMBiAwYDEBgwGIDAAYMBiAwYDABgw/+MYxI4KCDVwAP8WABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxJYJaDV0AP7MAEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxKEJMDV0AP7MABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxK8JGDVwAP8WABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxL4I8DV0AP7MABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxMwJCDVwAP8WABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxN4I8DV0AP7MABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxPQJEDVwAP8WABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY/+MYxP8I2DVcAP8WABAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAYMEAY');
    this.audio.volume = 0.5;
  }

  play() {
    this.audio.play().catch(error => {
      console.warn('Could not play notification sound:', error);
    });
  }

  setVolume(volume: number) {
    this.audio.volume = Math.max(0, Math.min(1, volume));
  }
}

export const notificationSound = new NotificationSoundService();
