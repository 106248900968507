import React, { useState, useEffect } from 'react';
import { X, Trash2, Share2, Check, DollarSign, Building2, User2, Mail, Phone, Clock, CalendarDays, Link2, Pencil } from 'lucide-react';
import { useKanbanStore } from '../../../store/kanbanStore';
import { useAuthStore } from '../../../store/authStore';
import { 
  DealProgressBar, 
  MobileStageSelect, 
  DealHistory, 
  Activities,
  DealNotes,
  DealTasks
} from '..';
import { ActivityFeed } from '../../activity/ActivityFeed';
import { DealMainForm } from './DealMainForm';
import type { Deal, CustomField } from '../../../types/deal';
import { Button } from '../../../components/ui';
import { formatDistanceToNow } from 'date-fns';

interface DealDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  deal: Deal;
  customFields: CustomField[];
}

export const DealDetailsModal: React.FC<DealDetailsModalProps> = ({ 
  isOpen, 
  onClose, 
  deal, 
  customFields = [] 
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [isCopied, setIsCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { deleteDeal, updateDeal } = useKanbanStore();
  const { user } = useAuthStore();

  // Reset editing state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setIsEditing(false);
      setHasUnsavedChanges(false);
    }
  }, [isOpen]);

  // Handle tab change
  const handleTabChange = (tab: string) => {
    if (isEditing && hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        setIsEditing(false);
        setHasUnsavedChanges(false);
        setActiveTab(tab);
      }
    } else {
      if (isEditing) {
        setIsEditing(false);
      }
      setActiveTab(tab);
    }
  };

  // Handle modal close
  const handleClose = () => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
        setIsEditing(false);
        setHasUnsavedChanges(false);
        onClose();
      }
    } else {
      setIsEditing(false);
      onClose();
    }
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [hasUnsavedChanges]);

  // Handle clicking outside of modal
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const modalContent = document.querySelector('.modal-content');
      const isDatePickerClick = target.closest('.react-datepicker') || target.closest('.react-datepicker-popper');
      const isNotificationClick = target.closest('[data-toast]') || target.closest('[role="status"]');

      if (modalContent && !modalContent.contains(target) && !isDatePickerClick && !isNotificationClick) {
        if (hasUnsavedChanges) {
          if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
            onClose();
          }
        } else {
          onClose();
        }
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, hasUnsavedChanges, onClose]);

  const handleStageChange = async (stageId: string) => {
    if (user?.organizationId) {
      updateDeal(deal.id, { 
        stageId,
        stage: stageId,
        updatedAt: new Date().toISOString(),
        stageHistory: [
          ...(deal.stageHistory || []),
          {
            from: deal.stageId || '',
            to: stageId,
            timestamp: new Date().toISOString()
          }
        ]
      }).catch(error => {
        console.error('Error updating deal stage:', error);
        setError('Failed to update deal stage');
      });
    }
  };

  const handleShare = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const dealUrl = `${window.location.origin}/deals/${deal.id}`;
    
    try {
      await navigator.clipboard.writeText(dealUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  const handleContactAction = (type: 'email' | 'phone') => {
    const contact = deal.contact;
    if (type === 'email' && contact?.email) {
      window.location.href = `mailto:${contact.email}`;
    } else if (type === 'phone' && contact?.phone) {
      window.location.href = `tel:${contact.phone}`;
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 bg-black/50 z-50 transition-opacity duration-200 ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div className="h-screen overflow-y-auto">
        <div className="min-h-screen flex items-start justify-center pt-4 pb-6">
          <div className="modal-content bg-navy-900 w-full max-w-5xl rounded-lg shadow-xl mx-4">
            {/* Header with Edit Button */}
            <div className="flex items-center justify-between px-6 py-4 border-b border-navy-700">
              <h2 className="text-lg font-semibold text-white">{deal.title}</h2>
              <div className="flex items-center gap-2">
                {(user?.role === 'admin' || user?.uid === deal.ownerId) && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={async () => {
                      if (window.confirm('Are you sure you want to delete this deal? This action cannot be undone.')) {
                        try {
                          await deleteDeal(deal.id);
                          onClose();
                        } catch (err) {
                          console.error('Error deleting deal:', err);
                          alert('Failed to delete deal. Please try again.');
                        }
                      }
                    }}
                    className="text-red-500 hover:text-red-400"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                )}
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsEditing(!isEditing)}
                  className="text-gray-400 hover:text-white"
                >
                  <Pencil className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={handleClose}
                  className="text-gray-400 hover:text-white"
                >
                  <X className="h-5 w-5" />
                </Button>
              </div>
            </div>

            {/* Tab Navigation */}
            <div className="flex space-x-4 border-b border-navy-700">
              <button
                onClick={() => handleTabChange('overview')}
                className={`px-4 py-2 text-sm font-medium border-b-2 ${
                  activeTab === 'overview'
                    ? 'border-blue-500 text-blue-500'
                    : 'border-transparent text-gray-400 hover:text-gray-300'
                }`}
              >
                Overview
              </button>
              <button
                onClick={() => handleTabChange('tasks')}
                className={`px-4 py-2 text-sm font-medium border-b-2 ${
                  activeTab === 'tasks'
                    ? 'border-blue-500 text-blue-500'
                    : 'border-transparent text-gray-400 hover:text-gray-300'
                }`}
                disabled={isEditing}
              >
                Tasks
              </button>
              <button
                onClick={() => handleTabChange('activities')}
                className={`px-4 py-2 text-sm font-medium border-b-2 ${
                  activeTab === 'activities'
                    ? 'border-blue-500 text-blue-500'
                    : 'border-transparent text-gray-400 hover:text-gray-300'
                }`}
                disabled={isEditing}
              >
                Activities
              </button>
              <button
                onClick={() => handleTabChange('notes')}
                className={`px-4 py-2 text-sm font-medium border-b-2 ${
                  activeTab === 'notes'
                    ? 'border-blue-500 text-blue-500'
                    : 'border-transparent text-gray-400 hover:text-gray-300'
                }`}
                disabled={isEditing}
              >
                Notes
              </button>
            </div>

            {/* Progress Bar - Only show in overview tab */}
            {activeTab === 'overview' && (
              <div className="px-6 pt-4 mb-6">
                <DealProgressBar 
                  deal={deal} 
                  onStageChange={handleStageChange}
                />
              </div>
            )}
            {isEditing ? (
              <div className="p-6">
                <DealMainForm
                  deal={deal}
                  onUnsavedChanges={setHasUnsavedChanges}
                  loading={loading}
                  onSave={() => {
                    setIsEditing(false);
                    setHasUnsavedChanges(false);
                  }}
                />
              </div>
            ) : (
              <div>
                {activeTab === 'overview' && (
                  <div className="p-6 border-b border-navy-700 grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* Company Information */}
                    <div className="space-y-4">
                      <div className="bg-navy-900/50 rounded-lg p-4 border border-navy-700">
                        <div className="flex items-center gap-2 mb-3">
                          <Building2 className="h-5 w-5 text-blue-500" />
                          <h3 className="text-sm font-medium text-white">Company</h3>
                        </div>
                        <p className="text-lg text-white mb-4 break-words">{deal.company}</p>
                        
                        <div className="space-y-3">
                          {deal.website && (
                            <div>
                              <div className="text-sm text-gray-400 mb-1">Website</div>
                              <a
                                href={deal.website.startsWith('http') ? deal.website : `https://${deal.website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm text-indigo-400 hover:text-indigo-300 flex items-center gap-1 break-all"
                              >
                                <Link2 className="h-4 w-4 shrink-0" />
                                <span>{deal.website}</span>
                              </a>
                            </div>
                          )}
                          
  
                          <div>
                            <div className="text-sm text-gray-400 mb-1">Company Size</div>
                            <div className="text-sm text-white">{deal.companySize || 'Not specified'}</div>
                          </div>

                          {deal.linkedinUrl && (
                            <div>
                              <div className="text-sm text-gray-400 mb-1">LinkedIn</div>
                              <a
                                href={deal.linkedinUrl.startsWith('http') ? deal.linkedinUrl : `https://${deal.linkedinUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm text-indigo-400 hover:text-indigo-300 flex items-center gap-1 break-all"
                              >
                                <Link2 className="h-4 w-4 shrink-0" />
                                <span>{deal.linkedinUrl}</span>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div className="space-y-4">
                      <div className="bg-navy-900/50 rounded-lg p-4 border border-navy-700">
                        <div className="flex items-center gap-2 mb-3">
                          <User2 className="h-5 w-5 text-purple-500" />
                          <h3 className="text-sm font-medium text-white">Contact</h3>
                        </div>
                        
                        {deal.contact ? (
                          <div className="space-y-3">
                            <div>
                              <div className="text-sm text-gray-400 mb-1">Name</div>
                              <p className="text-lg text-white break-words">{deal.contact.name}</p>
                            </div>

                            {deal.contact.email && (
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Email</div>
                                <a
                                  href={`mailto:${deal.contact.email}`}
                                  className="text-sm text-indigo-400 hover:text-indigo-300 flex items-center gap-1 break-all"
                                >
                                  <Mail className="h-4 w-4 shrink-0" />
                                  <span>{deal.contact.email}</span>
                                </a>
                              </div>
                            )}

                            {deal.contact.phone && (
                              <div>
                                <div className="text-sm text-gray-400 mb-1">Phone</div>
                                <a
                                  href={`tel:${deal.contact.phone}`}
                                  className="text-sm text-indigo-400 hover:text-indigo-300 flex items-center gap-1"
                                >
                                  <Phone className="h-4 w-4 shrink-0" />
                                  <span>{deal.contact.phone}</span>
                                </a>
                              </div>
                            )}

                            {deal.contact.linkedinUrl && (
                              <div>
                                <div className="text-sm text-gray-400 mb-1">LinkedIn</div>
                                <a
                                  href={deal.contact.linkedinUrl.startsWith('http') ? deal.contact.linkedinUrl : `https://${deal.contact.linkedinUrl}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-sm text-indigo-400 hover:text-indigo-300 flex items-center gap-1 break-all"
                                >
                                  <Link2 className="h-4 w-4 shrink-0" />
                                  <span>{deal.contact.linkedinUrl}</span>
                                </a>
                              </div>
                            )}
                          </div>
                        ) : (
                          <p className="text-sm text-gray-400">No contact assigned</p>
                        )}
                      </div>
                    </div>

                    {/* Deal Value and Custom Fields */}
                    <div className="space-y-4">
                      <div className="bg-navy-900/50 rounded-lg p-4 border border-navy-700">
                        <div className="flex items-center justify-between mb-4">
                          <div className="flex items-center gap-2">
                            <DollarSign className="h-5 w-5 text-emerald-500" />
                            <span className="text-lg font-semibold text-white">
                              ${deal.value?.toLocaleString()}
                            </span>
                          </div>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => setIsEditing(!isEditing)}
                          >
                            {isEditing ? 'View' : 'Edit'}
                          </Button>
                        </div>

                        {/* Deal Owner */}
                        <div className="mb-4 pb-4 border-b border-navy-700">
                          <div className="text-sm text-gray-400 mb-2">Deal Owner</div>
                          <div className="flex items-center gap-2">
                            {deal.ownerPhotoURL ? (
                              <img 
                                src={deal.ownerPhotoURL} 
                                alt={deal.ownerName || 'Owner'} 
                                className="h-6 w-6 rounded-full"
                              />
                            ) : (
                              <User2 className="h-5 w-5 text-gray-400" />
                            )}
                            <span className="text-sm text-white">
                              {deal.ownerName || 'Unassigned'}
                            </span>
                          </div>
                        </div>

                        {/* Deal Custom Fields */}
                        {customFields
                          .filter(field => field.displayLocation === 'deal')
                          .sort((a, b) => a.order - b.order)
                          .map((field, index) => {
                            const fieldValue = deal.customFields?.find(f => f.fieldId === field.id)?.value;
                            if (!fieldValue) return null;
                            

                            return (
                              <div 
                                key={field.id} 
                                className={`${
                                  index === 0 ? 'mt-4 border-t border-navy-600 pt-4' : 'mt-3'
                                }`}
                              >
                                <div className="text-sm text-gray-400 mb-1">{field.name}</div>
                                {field.type === 'url' || (typeof fieldValue === 'string' && fieldValue.startsWith('http')) ? (
                                  <a
                                    href={fieldValue.toString().startsWith('http') ? fieldValue.toString() : `https://${fieldValue.toString()}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-indigo-400 hover:text-indigo-300 flex items-center gap-1 break-all"
                                  >
                                    <Link2 className="h-4 w-4 shrink-0" />
                                    <span className="text-sm">{fieldValue.toString()}</span>
                                  </a>
                                ) : (
                                  <div className="text-white text-sm break-words">
                                    {field.type === 'date' 
                                      ? new Date(fieldValue).toLocaleDateString()
                                      : fieldValue.toString()
                                    }
                                  </div>
                                )}
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'notes' && (
                  <div className="p-6">
                    <DealNotes deal={deal} />
                  </div>
                )}

                {activeTab === 'tasks' && (
                  <div className="p-6 relative" style={{ minHeight: '400px' }}>
                    <DealTasks dealId={deal.id} />
                  </div>
                )}

                {activeTab === 'activities' && (
                  <div className="p-6">
                    <Activities 
                      dealId={deal.id} 
                      currentStage={deal.stage} 
                      onStageChange={handleStageChange} 
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};