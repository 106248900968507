import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Share2 } from 'lucide-react';
import { Deal } from '../../types/deal';
import { DealDetailsModal } from '../deals/modals/DealDetailsModal';
import { useCustomFieldsStore } from '../../store/customFieldsStore';
import { getInitials, getInitialsColor } from '../../utils/colors';
import { RestoreDealButton } from '../deals/RestoreDealButton';

interface KanbanCardProps {
  deal: Deal;
  stage: string;
  index?: number;
  deals?: Deal[];
  isDragging?: boolean;
}

export const KanbanCard: React.FC<KanbanCardProps> = ({ 
  deal, 
  stage, 
  index, 
  deals = [], 
  isDragging 
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { fields } = useCustomFieldsStore();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: deal.id,
    data: { 
      stage: stage,
      type: 'card',
      index,
      deal
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const stageName = deal.stageName || stage;

  console.log('Card render:', {
    dealId: deal.id,
    stageId: stage,
    dealStageId: deal.stageId,
    dealStage: deal.stage,
    dealStageName: deal.stageName
  });

  const handleCopyLink = async (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/deals/${deal.id}`);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // Simple click handler - DndKit's sensors will handle drag detection
  const handleClick = (e: React.MouseEvent) => {
    // Don't open details modal if clicking the restore button
    if (!isDragging && !e.defaultPrevented) {
      setIsDetailsModalOpen(true);
    }
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        onClick={handleClick}
        className={`
          group relative min-h-[100px] p-3 bg-navy-700 rounded-lg 
          border border-navy-600 hover:border-indigo-500 
          cursor-grab active:cursor-grabbing 
          transition-all duration-200
          ${isDragging ? 'opacity-50' : ''}
        `}
      >
        {/* Drag indicator */}
        {isDragging && (
          <div className="absolute inset-0 border-2 border-indigo-500 rounded-lg pointer-events-none animate-pulse"></div>
        )}

        <div className="flex flex-col h-full">
          <div className="flex items-start justify-between gap-2">
            <div 
              title={deal.title} 
              className="text-sm font-medium text-white line-clamp-2 flex-1 truncate hover:whitespace-normal hover:text-clip"
            >
              {deal.title}
            </div>
            <div className="flex items-center gap-1 shrink-0">
              {deal.archived && <RestoreDealButton dealId={deal.id} />}
              <button
                onClick={handleCopyLink}
                className="opacity-0 group-hover:opacity-100 p-1 hover:bg-navy-600 rounded-md transition-opacity"
                title={isCopied ? 'Copied!' : 'Copy link'}
              >
                <Share2 className="w-3 h-3 text-gray-400" />
              </button>
            </div>
          </div>
          
          <div className="mt-auto pt-2">
            <div className="flex items-center justify-between text-xs text-gray-400 mb-1">
              <span className="truncate flex-1 mr-2">{deal.company}</span>
              <span className="shrink-0">${deal.value?.toLocaleString()}</span>
            </div>
            {deal.contact && (
              <div className="flex items-center gap-2">
                <div 
                  className={`w-6 h-6 rounded-full flex items-center justify-center text-xs text-white font-medium ${getInitialsColor(deal.contact.name)}`}
                >
                  {getInitials(deal.contact.name)}
                </div>
                <span className="text-xs text-gray-400 truncate flex-1">
                  {deal.contact.name}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <DealDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        deal={deal}
        customFields={fields}
      />
    </>
  );
};