import React, { useState, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventSourceInput } from '@fullcalendar/core';
import { Task } from '../../../types/task';
import './calendar.css';
import { Dialog, DialogContent, DialogHeader, DialogTitle, Button } from '../../ui';
import { Check, Pencil } from 'lucide-react';
import { TaskService } from '../services/TaskService';
import { useNavigate } from 'react-router-dom';

interface TaskCalendarViewProps {
  tasks: Task[];
  onTaskSelect: (task: Task) => void;
  onTaskUpdate: (taskId: string, updates: Partial<Task>) => Promise<void>;
}

const TaskCalendarView: React.FC<TaskCalendarViewProps> = ({ 
  tasks, 
  onTaskSelect,
  onTaskUpdate
}) => {
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const navigate = useNavigate();

  const getActivityTypeStyles = (type: string) => {
    switch (type.toLowerCase()) {
      case 'call':
        return 'bg-purple-500/80';
      case 'email':
        return 'bg-blue-500/80';
      case 'meeting':
        return 'bg-green-500/80';
      case 'task':
        return 'bg-orange-500/80';
      default:
        return 'bg-gray-500/80';
    }
  };

  const getPriorityStyles = (priority: string) => {
    switch (priority.toLowerCase()) {
      case 'high':
        return 'bg-red-500/20 text-red-400';
      case 'medium':
        return 'bg-yellow-500/20 text-yellow-400';
      case 'low':
        return 'bg-green-500/20 text-green-400';
      default:
        return 'bg-gray-500/20 text-gray-400';
    }
  };

  const events = useMemo(() => {
    return tasks.map(task => ({
      id: task.id,
      title: task.title,
      start: task.dueDate ? new Date(task.dueDate) : new Date(),
      allDay: true,
      className: `task-event ${task.status === 'completed' ? 'task-completed' : ''} ${getActivityTypeStyles(task.type || 'task')}`
    }));
  }, [tasks]);

  const handleEventClick = (clickInfo: any) => {
    const taskId = clickInfo.event.id;
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      onTaskSelect(task);
    }
  };

  const handleCompleteTask = async (task: Task) => {
    if (!task.id) return;
    
    try {
      await onTaskUpdate(task.id, {
        status: task.status === 'completed' ? 'pending' : 'completed',
        completedAt: task.status === 'completed' ? null : new Date().toISOString()
      });
      setSelectedTask(null);
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDealClick = (dealId: string) => {
    if (dealId) {
      navigate(`/deals/${dealId}`);
    }
  };

  return (
    <div className="bg-gray-800 rounded-xl overflow-hidden">
      <div className="p-4">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventClick={handleEventClick}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek'
          }}
          height="auto"
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          }}
          dayMaxEvents={3}
          moreLinkClick="popover"
          eventContent={renderEventContent}
        />
      </div>
    </div>
  );
};

const renderEventContent = (eventInfo: any) => {
  return (
    <div className="flex items-center gap-2 px-2 py-1">
      <div className="w-2 h-2 rounded-full bg-current" />
      <span className="text-sm font-medium truncate">
        {eventInfo.event.title}
      </span>
    </div>
  );
};

export { TaskCalendarView };
