import React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';
import type { CustomFieldValue, CustomField } from '../../types/customField';

interface CustomFieldsSectionProps {
  fields: CustomField[];
  values: CustomFieldValue[];
  onChange: (values: CustomFieldValue[]) => void;
  onFieldChange?: () => void;
  section?: 'deal' | 'company' | 'contact' | 'general';
}

const isValidUrl = (str: string) => {
  try {
    // Add protocol if missing
    const urlStr = str.match(/^https?:\/\//) ? str : `https://${str}`;
    new URL(urlStr);
    return true;
  } catch {
    return false;
  }
};

const formatUrl = (url: string) => {
  if (!url) return url;
  return url.match(/^https?:\/\//) ? url : `https://${url}`;
};

const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-z]{2,})/gi;

const renderTextWithUrls = (text: string) => {
  if (!text) return text;

  const parts = text.split(urlRegex);
  return parts.map((part, i) => {
    if (!part) return null;
    if (isValidUrl(part)) {
      const url = part.match(/^https?:\/\//) ? part : `https://${part}`;
      return (
        <a
          key={i}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-400 hover:text-indigo-300 hover:underline inline-flex items-center gap-1"
          onClick={(e) => e.stopPropagation()}
        >
          {part}
          <ExternalLink className="w-4 h-4" />
        </a>
      );
    }
    return part;
  });
};

export const CustomFieldsSection: React.FC<CustomFieldsSectionProps> = ({ 
  fields,
  values, 
  onChange, 
  onFieldChange,
  section
}) => {
  const handleFieldChange = (fieldId: string, value: string) => {
    const field = fields.find(f => f.id === fieldId);
    if (!field) return;

    console.log('Field change:', {
      fieldId,
      value,
      valueLength: value.length,
      trimmedLength: value.trim().length,
      currentValues: values
    });

    // Create a new array without the current field value
    const newValues = values.filter(v => v.fieldId !== fieldId);

    // Only add the value if it's not empty
    if (value && value.trim() !== '') {
      newValues.push({ fieldId, value: value.trim() });
    }

    console.log('Setting new values:', {
      fieldId,
      value,
      newValues
    });
    
    onChange(newValues);
    onFieldChange?.();
  };

  const getValue = (fieldId: string): string => {
    console.log('Getting value for field:', {
      fieldId,
      allValues: values,
      foundValue: values.find(v => v.fieldId === fieldId)?.value
    });
    return values.find(v => v.fieldId === fieldId)?.value?.toString() || '';
  };

  const renderField = (field: CustomField) => {
    const value = getValue(field.id);

    console.log('Rendering Field:', {
      fieldName: field.name,
      fieldType: field.type,
      value: value
    });

    switch (field.type) {
      case 'text':
      case 'url':
        return (
          <div className="relative group">
            <input
              type="text"
              value={value}
              onChange={(e) => {
                const newValue = e.target.value;
                console.log('Input change:', {
                  fieldName: field.name,
                  oldValue: value,
                  newValue: newValue,
                  newValueLength: newValue.length
                });
                handleFieldChange(field.id, newValue);
              }}
              className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              placeholder={`Enter ${field.name}`}
            />
            {value && isValidUrl(value) && (
              <a
                href={formatUrl(value)}
                target="_blank"
                rel="noopener noreferrer"
                className="absolute right-2 top-1/2 -translate-y-1/2 text-blue-400 hover:text-blue-300 opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <ExternalLink className="w-4 h-4" />
              </a>
            )}
          </div>
        );
      case 'number':
        return (
          <input
            type="number"
            value={value}
            onChange={(e) => handleFieldChange(field.id, e.target.value)}
            className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder={`Enter ${field.name}`}
          />
        );
      case 'select':
        return (
          <select
            value={value}
            onChange={(e) => handleFieldChange(field.id, e.target.value)}
            className="w-full bg-navy-800 border border-navy-600 rounded-md px-3 py-2 text-sm text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="">Select {field.name}</option>
            {field.options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      default:
        return null;
    }
  };

  // Filter fields based on the section prop if provided, otherwise show all fields
  const sectionFields = section 
    ? fields.filter(field => {
        const fieldLocation = field.displayLocation?.toLowerCase() || 'general';
        const requestedSection = section.toLowerCase();
        
        console.log('Field Section Check:', {
          fieldName: field.name,
          fieldLocation,
          requestedSection,
          isMatch: fieldLocation === requestedSection
        });

        return fieldLocation === requestedSection;
      })
    : fields;

  console.log(`Fields for section ${section}:`, sectionFields);

  // If no fields, return null
  if (sectionFields.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      {sectionFields.map((field) => (
        <div key={field.id} className="w-full">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            {field.name}
            {field.required && <span className="text-red-500 ml-1">*</span>}
          </label>
          {renderField(field)}
        </div>
      ))}
    </div>
  );
};