import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  User,
  Settings,
  LogOut,
  ChevronDown,
  Bell,
  HelpCircle
} from 'lucide-react';
import { useAuthStore } from '../../store/authStore';

interface ProfileMenuProps {
  onSignOut: () => void;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ onSignOut }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const user = useAuthStore(state => state.user);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Get user initials
  const getInitials = () => {
    if (!user?.displayName) return '?';
    return user.displayName
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-2 py-1 hover:bg-navy-700 rounded-md transition-colors"
      >
        <div className="w-7 h-7 rounded-full bg-indigo-600 flex items-center justify-center">
          {user?.photoURL ? (
            <img
              src={user.photoURL}
              alt={user.displayName || 'User'}
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <span className="text-sm font-medium text-white">{getInitials()}</span>
          )}
        </div>
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-64 bg-navy-800 rounded-md shadow-lg border border-white/10 py-2 z-50">
          {/* User Info */}
          <div className="px-4 py-2 border-b border-white/10">
            <div className="font-medium text-white">
              {user?.displayName || 'User'}
            </div>
            <div className="text-sm text-gray-400 truncate">
              {user?.email}
            </div>
          </div>

          {/* Menu Items */}
          <div className="py-1">
            <Link
              to="/settings/profile"
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-navy-700 hover:text-white"
              onClick={() => setIsOpen(false)}
            >
              <User className="w-4 h-4" />
              Profile Settings
            </Link>
            <Link
              to="/settings"
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-navy-700 hover:text-white"
              onClick={() => setIsOpen(false)}
            >
              <Settings className="w-4 h-4" />
              Account Settings
            </Link>
            <Link
              to="/notifications"
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-navy-700 hover:text-white"
              onClick={() => setIsOpen(false)}
            >
              <Bell className="w-4 h-4" />
              Notifications
            </Link>
            <Link
              to="/help"
              className="flex items-center gap-2 px-4 py-2 text-sm text-gray-300 hover:bg-navy-700 hover:text-white"
              onClick={() => setIsOpen(false)}
            >
              <HelpCircle className="w-4 h-4" />
              Help & Support
            </Link>
          </div>

          {/* Sign Out */}
          <div className="border-t border-white/10 pt-1">
            <button
              onClick={() => {
                setIsOpen(false);
                onSignOut();
              }}
              className="flex items-center gap-2 w-full px-4 py-2 text-sm text-red-400 hover:bg-navy-700"
            >
              <LogOut className="w-4 h-4" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
