import React, { useState, useEffect } from 'react';
import { useKanbanStore } from '../../../store/kanbanStore';
import { usePipelineStore } from '../../../store/pipelineStore';
import { Undo2, X } from 'lucide-react';

interface RestoreDealModalProps {
  isOpen: boolean;
  onClose: () => void;
  dealId: string;
}

export const RestoreDealModal: React.FC<RestoreDealModalProps> = ({ 
  isOpen, 
  onClose, 
  dealId 
}) => {
  const { stages } = usePipelineStore();
  const { restoreDeal, deals } = useKanbanStore();
  const [selectedStage, setSelectedStage] = useState('');
  const [isRestoring, setIsRestoring] = useState(false);

  const deal = deals.find(d => d.id === dealId);

  useEffect(() => {
    if (isOpen) {
      // Reset selected stage when modal opens
      setSelectedStage('');
      setIsRestoring(false);
    }
  }, [isOpen]);

  if (!isOpen || !deal) return null;

  const handleRestore = async () => {
    if (!selectedStage) return;

    try {
      setIsRestoring(true);
      await restoreDeal(dealId, selectedStage);
      onClose();
    } catch (error) {
      console.error('Error restoring deal:', error);
    } finally {
      setIsRestoring(false);
    }
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
      onClick={handleBackdropClick}
    >
      <div className="bg-navy-800 rounded-lg shadow-xl w-full max-w-md mx-4 animate-fadeIn">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-navy-700">
          <h2 className="text-lg font-semibold text-white flex items-center">
            <Undo2 className="w-5 h-5 mr-2 text-indigo-400" />
            Restore "{deal.title}"
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <div className="p-4">
          <p className="text-gray-300 mb-4">
            Select which stage you want to restore this deal to:
          </p>
          
          <div className="space-y-2 max-h-[300px] overflow-y-auto custom-scrollbar">
            {stages.map((stage) => (
              <button
                key={stage.name}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedStage(stage.name);
                }}
                className={`
                  w-full text-left px-4 py-3 rounded-md transition-all
                  flex items-center justify-between
                  ${selectedStage === stage.name 
                    ? 'bg-indigo-600 text-white ring-2 ring-indigo-400 ring-offset-2 ring-offset-navy-800' 
                    : 'bg-navy-700 text-gray-300 hover:bg-navy-600 hover:text-white'}
                `}
              >
                <span>{stage.name}</span>
                {selectedStage === stage.name && (
                  <div className="w-2 h-2 rounded-full bg-white" />
                )}
              </button>
            ))}
          </div>
        </div>
        
        <div className="p-4 border-t border-navy-700 flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white transition-colors"
            disabled={isRestoring}
          >
            Cancel
          </button>
          <button
            onClick={handleRestore}
            disabled={!selectedStage || isRestoring}
            className={`
              px-4 py-2 text-sm font-medium rounded-md transition-all
              ${!selectedStage 
                ? 'bg-navy-700 text-gray-400 cursor-not-allowed' 
                : 'bg-indigo-600 text-white hover:bg-indigo-700'}
              disabled:opacity-50 disabled:cursor-not-allowed
              flex items-center gap-2
            `}
          >
            {isRestoring ? (
              <>
                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                Restoring...
              </>
            ) : (
              'Restore Deal'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
