import React from 'react';
import { ListPlus } from 'lucide-react';
import { CustomFieldsManager } from './CustomFieldsManager';

const CustomFieldsSettings: React.FC = () => {
  return (
    <div className="bg-navy-800/50 rounded-lg">
      <div className="p-6 border-b border-navy-700">
        <div className="flex items-center gap-2">
          <ListPlus className="w-6 h-6 text-gray-400" />
          <h2 className="text-xl font-semibold text-white">Custom Fields</h2>
        </div>
        <p className="mt-1 text-sm text-gray-400">
          Add custom fields to track additional information for each deal
        </p>
      </div>
      <div className="p-6">
        <CustomFieldsManager />
      </div>
    </div>
  );
};

export default CustomFieldsSettings;