import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// Authentication Components
import { SignUpForm } from './components/auth/SignUpForm';
import { SignInForm } from './components/auth/SignInForm';

// Layout Components
import { DashboardLayout } from './components/layout/DashboardLayout';
import { LandingPage } from './components/landing/LandingPage';

// Settings Components
import { SettingsLayout } from './components/settings/SettingsLayout';
import { PipelineSettings } from './components/settings/PipelineSettings';
import CustomFieldsSettings from './components/settings/CustomFieldsSettings';
import { ProfileSettings } from './components/settings/ProfileSettings';
import { TeamSettings } from './components/settings/TeamSettings';
import { IntegrationsSettings } from './components/settings/IntegrationsSettings';
import ApiDocs from './components/settings/ApiDocs';
import AboutTab from './components/settings/AboutTab';

// Main Feature Components
import { KanbanBoard } from './components/kanban/KanbanBoard';
import { ActivityFeed } from './components/activity/ActivityFeed';
import { DealDetailsPage } from './components/deals/DealDetailsPage';

// Report Components
import { ReportsLayout } from './components/reports/ReportsLayout';
import { TaskAnalytics } from './components/reports/TaskAnalytics';
import { ActivityInsights } from './components/reports/ActivityInsights';
import { PerformanceReport } from './components/reports/PerformanceReport';

// Workflow Components
import { WorkflowList } from './pages/Workflows/WorkflowList';
import { WorkflowCreate } from './pages/Workflows/WorkflowCreate';
import { WorkflowEdit } from './pages/Workflows/WorkflowEdit';

// Legal Pages
import { TermsOfService } from './pages/legal/TermsOfService';
import { PrivacyPolicy } from './pages/legal/PrivacyPolicy';

// Store and Context
import { useAuthStore } from './store/authStore';

import NotificationsPage from './pages/NotificationsPage';

function App() {
  const { user, initialized } = useAuthStore();

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <Router>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            background: 'transparent',
            boxShadow: 'none',
            padding: 0,
          },
        }}
      />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Authentication Routes */}
        <Route
          path="/signup"
          element={!user ? <SignUpForm /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/signin"
          element={!user ? <SignInForm /> : <Navigate to="/dashboard" />}
        />

        {/* Workflow Routes */}
        <Route
          path="/workflows"
          element={
            user ? (
              <DashboardLayout>
                <WorkflowList />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
        <Route
          path="/workflows/create"
          element={
            user ? (
              <DashboardLayout>
                <WorkflowCreate />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
        <Route
          path="/workflows/edit/:id"
          element={
            user ? (
              <DashboardLayout>
                <WorkflowEdit />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        {/* Dashboard Route */}
        <Route
          path="/dashboard"
          element={
            user ? (
              <DashboardLayout>
                <KanbanBoard />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        {/* Notifications Route */}
        <Route
          path="/notifications"
          element={
            user ? (
              <DashboardLayout>
                <NotificationsPage />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        {/* Settings Routes */}
        <Route 
          path="/settings" 
          element={
            user ? (
              <DashboardLayout>
                <SettingsLayout>
                  <Outlet />
                </SettingsLayout>
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          }
        >
          <Route path="pipeline" element={<PipelineSettings />} />
          <Route path="fields" element={<CustomFieldsSettings />} />
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="team" element={<TeamSettings />} />
          <Route path="integrations" element={<IntegrationsSettings />} />
          <Route path="api-docs" element={<ApiDocs />} />
          <Route path="about" element={<AboutTab />} />
          <Route index element={<Navigate to="/settings/profile" replace />} />
        </Route>

        {/* Reports Routes */}
        <Route 
          path="/reports" 
          element={
            user ? (
              <DashboardLayout>
                <ReportsLayout />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          } 
        >
          <Route index element={<Navigate to="performance" replace />} />
          <Route path="performance" element={<PerformanceReport />} />
          <Route path="task-analytics" element={<TaskAnalytics />} />
          <Route path="activity-insights" element={<ActivityInsights />} />
        </Route>
        <Route 
          path="/activity" 
          element={
            user ? (
              <DashboardLayout>
                <ActivityFeed />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          } 
        />
        <Route 
          path="/deals/:dealId" 
          element={
            user ? (
              <DashboardLayout>
                <DealDetailsPage />
              </DashboardLayout>
            ) : (
              <Navigate to="/signin" />
            )
          } 
        />

        {/* Landing and Default Routes */}
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </Router>
  );
}

export default App;