import React, { useState, useEffect, useMemo } from 'react';
import { X, DollarSign, Building2, User2, Mail, Phone, Pencil, CalendarDays, BookText } from 'lucide-react';
import { useKanbanStore } from '../../store/kanbanStore';
import { useAuthStore } from '../../store/authStore';
import { useTeamStore } from '../../store/teamStore';
import { usePipelineStore } from '../../store/pipelineStore';
import { useCustomFieldsStore } from '../../store/customFieldsStore';
import { useNotesStore } from '../../store/notesStore';
import { DealOwnerSelect } from '../deals/DealOwnerSelect';
import { CustomFieldsSection } from '../deals/CustomFieldsSection';
import type { CustomFieldValue } from '../../types/customField';

interface AddDealModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialStage?: string;
}

export const AddDealModal: React.FC<AddDealModalProps> = ({ isOpen, onClose, initialStage }) => {
  const { user } = useAuthStore();
  const { addDeal, loading: dealLoading, error: dealError } = useKanbanStore();
  const { addNote, loading: noteLoading } = useNotesStore();
  const { members } = useTeamStore();
  const { fields, subscribeToFields } = useCustomFieldsStore();
  const { stages } = usePipelineStore();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize form data with empty values
  const [formData, setFormData] = useState({
    title: '',
    value: '',
    company: '',
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    stage: '',
    stageId: '',
    notes: '',
  });

  // Update stage when stages are loaded or initialStage changes
  useEffect(() => {
    if (stages.length > 0) {
      let stageId = '';
      
      // If initialStage is provided, find its ID
      if (initialStage) {
        const stage = stages.find(s => s.id === initialStage);
        if (stage) {
          stageId = stage.id;
        }
      }
      
      // If no initialStage or it wasn't found, use first stage
      if (!stageId) {
        stageId = stages[0].id;
      }

      setFormData(prev => ({
        ...prev,
        stage: stageId,
        stageId: stageId
      }));
    }
  }, [stages, initialStage]);

  // Debug log for stage values
  useEffect(() => {
    console.log('Current stage values:', {
      formDataStage: formData.stage,
      availableStages: stages.map(s => ({ id: s.id, name: s.name })),
      initialStage
    });
  }, [formData.stage, stages, initialStage]);

  const [customFields, setCustomFields] = useState<CustomFieldValue[]>([]);
  const [ownerId, setOwnerId] = useState(user?.uid || '');

  // Subscribe to custom fields when component mounts
  useEffect(() => {
    const unsubscribe = subscribeToFields();
    return () => unsubscribe();
  }, [subscribeToFields]);

  // Group custom fields by display location
  const customFieldSections = useMemo(() => {
    const sections: { [key: string]: typeof fields } = {};
    
    fields.forEach(field => {
      const location = field.displayLocation || 'general';
      if (!sections[location]) {
        sections[location] = [];
      }
      sections[location].push(field);
    });

    return sections;
  }, [fields]);

  // Get stage name for display
  const getStageName = (stageId: string) => {
    return stages.find(s => s.id === stageId)?.name || 'Unknown Stage';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setIsSubmitting(true);

      // Validate stage
      if (!formData.stage) {
        throw new Error('Please select a valid stage');
      }

      const stage = stages.find(s => s.id === formData.stage);
      if (!stage) {
        console.error('Stage not found:', { stageId: formData.stage, stages: stages.map(s => ({ id: s.id, name: s.name })) });
        throw new Error('Selected stage not found');
      }

      // Clean up custom fields by removing any undefined values
      const cleanCustomFields = customFields.filter(field => field.value !== undefined && field.value !== null);

      // Create the deal with clean data
      const dealData = {
        title: formData.title.trim(),
        value: parseFloat(formData.value) || 0,
        company: formData.company.trim(),
        contact: {
          name: formData.contact.name.trim(),
          email: formData.contact.email.trim(),
          phone: formData.contact.phone.trim()
        },
        stage: stage.id,
        stageId: stage.id,
        stageName: stage.name,
        ownerId: ownerId || user.uid,
        customFields: cleanCustomFields,
        createdBy: user.uid
      };

      console.log('Creating new deal with stage:', { 
        stageId: stage.id, 
        stageName: stage.name,
        allStages: stages.map(s => ({ id: s.id, name: s.name }))
      });

      const newDealId = await addDeal(dealData);

      // If there's a note, add it to the deal
      if (formData.notes.trim()) {
        await addNote({
          content: formData.notes.trim(),
          dealId: newDealId,
          mentions: [],
          images: []
        });
      }

      onClose();
    } catch (err) {
      console.error('Error creating deal:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name.startsWith('contact.')) {
      const contactField = name.split('.')[1];
      setFormData(prev => ({

        ...prev,
        contact: {
          ...prev.contact,
          [contactField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleCustomFieldChange = (newValues: CustomFieldValue[]) => {
    // Simply replace the entire array with the new values
    setCustomFields(newValues);
  };

  const handleStageChange = (stageId: string) => {
    setFormData(prev => ({
      ...prev,
      stage: stageId,
      stageId: stageId
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-navy-800 rounded-lg max-w-5xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="sticky top-0 z-10 bg-navy-800 border-b border-navy-700 rounded-t-lg">
          <div className="px-6 py-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white">Add New Deal</h2>
            <button 
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-white rounded-full hover:bg-navy-700/50 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {dealError && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-md">
              <p className="text-sm text-red-400">{dealError}</p>
            </div>
          )}

          {/* Main Information - Two Column Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column - Deal Information */}
            <div className="space-y-6">
              {/* Deal Information Group */}
              <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6 space-y-6">
                <h3 className="text-sm font-medium text-white flex items-center gap-2">
                  <Pencil className="w-4 h-4" />
                  Deal Information
                </h3>

                {/* Deal Title */}
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Deal Title *
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter deal title"
                  />
                </div>

                {/* Deal Owner */}
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
                    <User2 className="w-4 h-4" />
                    Deal Owner
                  </label>
                  <DealOwnerSelect
                    currentOwnerId={ownerId}
                    onOwnerChange={setOwnerId}
                    disabled={false}
                  />
                </div>

                {/* Stage */}
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
                    <CalendarDays className="w-4 h-4" />
                    Stage *
                  </label>
                  <select
                    name="stage"
                    value={formData.stage}
                    onChange={(e) => handleStageChange(e.target.value)}
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  >
                    <option value="">Select Stage</option>
                    {stages.map((stage) => (
                      <option key={stage.id} value={stage.id}>
                        {stage.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Deal Value */}
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
                    <DollarSign className="w-4 h-4" />
                    Deal Value
                  </label>
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                      $
                    </span>
                    <input
                      type="number"
                      name="value"
                      value={formData.value}
                      onChange={handleChange}
                      className="w-full pl-8 px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="0.00"
                    />
                  </div>
                </div>

                {/* Deal Custom Fields */}
                {customFieldSections['deal'] && customFieldSections['deal'].length > 0 && (
                  <div className="space-y-4">
                    <CustomFieldsSection
                      fields={customFieldSections['deal']}
                      values={customFields}
                      onChange={handleCustomFieldChange}
                      section="deal"
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              {/* Contact Information */}
              <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6 space-y-4">
                <h3 className="text-sm font-medium text-white flex items-center gap-2">
                  <User2 className="w-4 h-4" />
                  Contact Information
                </h3>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Contact Name
                  </label>
                  <input
                    type="text"
                    name="contact.name"
                    value={formData.contact.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter contact name"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
                    <Mail className="w-4 h-4" />
                    Contact Email
                  </label>
                  <input
                    type="email"
                    name="contact.email"
                    value={formData.contact.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter contact email"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
                    <Phone className="w-4 h-4" />
                    Contact Phone
                  </label>
                  <input
                    type="tel"
                    name="contact.phone"
                    value={formData.contact.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter contact phone"
                  />
                </div>
                {/* Contact Custom Fields */}
                {customFieldSections['contact'] && customFieldSections['contact'].length > 0 && (
                  <div className="mt-4">
                    <CustomFieldsSection
                      fields={customFieldSections['contact']}
                      values={customFields}
                      onChange={handleCustomFieldChange}
                      section="contact"
                    />
                  </div>
                )}
              </div>

              {/* Company Information */}
              <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6 space-y-4">
                <h3 className="text-sm font-medium text-white flex items-center gap-2">
                  <Building2 className="w-4 h-4" />
                  Company Information
                </h3>
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Company Name *
                  </label>
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter company name"
                  />
                </div>
                {/* Company Custom Fields */}
                {customFieldSections['company'] && customFieldSections['company'].length > 0 && (
                  <div className="mt-4">
                    <CustomFieldsSection
                      fields={customFieldSections['company']}
                      values={customFields}
                      onChange={handleCustomFieldChange}
                      section="company"
                    />
                  </div>
                )}
              </div>

              {/* General Custom Fields */}
              {customFieldSections['general'] && customFieldSections['general'].length > 0 && (
                <div className="bg-navy-900/50 rounded-lg border border-navy-700 p-6">
                  <h3 className="text-sm font-medium text-white mb-4">Additional Information</h3>
                  <CustomFieldsSection
                    fields={customFieldSections['general']}
                    values={customFields}
                    onChange={handleCustomFieldChange}
                    section="general"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Notes - Full Width */}
          <div className="mt-6 bg-navy-900/50 rounded-lg border border-navy-700 p-6">
            <label className="block text-sm font-medium text-gray-300 mb-2 flex items-center gap-2">
              <BookText className="w-4 h-4" />
              Notes
            </label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              rows={4}
              className="w-full px-4 py-2 bg-navy-900 border border-navy-600 rounded-md text-white placeholder-gray-500 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm resize-none"
              placeholder="Add any notes about this deal..."
            />
          </div>

          {/* Submit Buttons - Sticky Bottom */}
          <div className="sticky bottom-0 bg-navy-800 mt-6 -mx-6 -mb-6 px-6 py-4 border-t border-navy-700 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white bg-navy-700 rounded-md hover:bg-navy-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Creating...' : 'Add Deal'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};