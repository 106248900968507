import { create } from 'zustand';
import { db } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  onSnapshot, 
  query, 
  where, 
  getDoc,
  DocumentData
} from 'firebase/firestore';
import { useAuthStore } from './authStore';
import { usePipelineStore } from './pipelineStore';
import type { Deal, DealStageChange } from '../types/deal';
import { triggerWorkflow } from '../services/workflowTrigger';
import type { PipelineStage } from '../types/Workflow';
import type { UserProfile } from '../types/user';

interface KanbanState {
  deals: Deal[];
  searchQuery: string;
  showArchived: boolean;
  loading: boolean;
  error: string | null;
  setSearchQuery: (query: string) => void;
  setShowArchived: (show: boolean) => void;
  filteredDeals: () => Deal[];
  addDeal: (deal: Omit<Deal, 'id' | 'createdAt' | 'updatedAt' | 'organizationId' | 'stageHistory' | 'ownerId' | 'ownerName' | 'ownerPhotoURL' | 'archived'>) => Promise<string>;
  updateDeal: (dealId: string, deal: Partial<Deal>) => Promise<void>;
  deleteDeal: (dealId: string) => Promise<void>;
  moveDeal: (dealId: string, fromStage: string, toStage: string) => Promise<void>;
  subscribeToDeals: () => () => void;
  restoreDeal: (dealId: string, toStage: string) => Promise<void>;
}

export const useKanbanStore = create<KanbanState>((set, get) => ({
  deals: [],
  searchQuery: '',
  showArchived: false,
  loading: false,
  error: null,
  
  setSearchQuery: (query: string) => {
    set({ searchQuery: query });
  },

  setShowArchived: (show: boolean) => {
    set({ showArchived: show });
  },

  filteredDeals: () => {
    const { deals, searchQuery, showArchived } = get();
    let filtered = deals;

    if (!showArchived) {
      filtered = filtered.filter(deal => !deal.archived);
    }

    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(deal => 
        deal.title.toLowerCase().includes(query) ||
        deal.company.toLowerCase().includes(query) ||
        (deal.contact?.name || '').toLowerCase().includes(query) ||
        (deal.contact?.email || '').toLowerCase().includes(query) ||
        (deal.contact?.phone || '').toLowerCase().includes(query)
      );
    }

    return filtered;
  },
  
  subscribeToDeals: () => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) {
      console.log('No organization ID found in subscribeToDeals');
      return () => {};
    }

    console.log('Subscribing to deals for organization:', user.organizationId);
    set({ loading: true, error: null });

    const q = query(
      collection(db, 'deals'),
      where('organizationId', '==', user.organizationId)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const deals = await Promise.all(snapshot.docs.map(async docSnapshot => {
        const data = docSnapshot.data() as DocumentData;
        console.log('Raw deal data:', { id: docSnapshot.id, ...data });
        
        // If there's an ownerId, fetch the owner's details
        let ownerName = '';
        let ownerPhotoURL = '';
        if (data.ownerId) {
          try {
            const ownerDocRef = doc(db, 'users', data.ownerId);
            const ownerDoc = await getDoc(ownerDocRef);
            if (ownerDoc.exists()) {
              const ownerData = ownerDoc.data() as UserProfile;
              ownerName = ownerData.displayName || ownerData.email || 'Unknown User';
              ownerPhotoURL = ownerData.photoURL || '';
            }
          } catch (err) {
            console.error('Error fetching owner details:', err);
          }
        }
        
        const deal = {
          id: docSnapshot.id,
          ...data,
          value: Number(data.value) || 0,
          createdAt: data.createdAt || new Date().toISOString(),
          updatedAt: data.updatedAt || new Date().toISOString(),
          stageHistory: data.stageHistory || [],
          archived: data.archived || false,
          organizationId: data.organizationId || user.organizationId,
          contact: data.contact || { name: '', email: '', phone: '' },
          company: data.company || '',
          title: data.title || '',
          stage: data.stage || 'New',
          notes: data.notes || '',
          customFields: data.customFields || [],
          ownerId: data.ownerId || '',
          ownerName,
          ownerPhotoURL,
        } as Deal;
        return deal;
      }));

      console.log('Processed deals:', deals);
      set({ deals, loading: false, error: null });
    }, (error) => {
      console.error('Error in deals subscription:', error);
      set({ error: error.message, loading: false });
    });

    return unsubscribe;
  },
  
  addDeal: async (dealData) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      const { stages } = usePipelineStore.getState();
      
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      // Validate stage
      if (!dealData.stage || !dealData.stageId) {
        console.error('Missing stage data:', dealData);
        throw new Error('Stage ID is required');
      }

      const stage = stages.find(s => s.id === dealData.stageId);
      if (!stage) {
        console.error('Stage not found:', { stageId: dealData.stageId, availableStages: stages });
        throw new Error('Invalid stage ID');
      }
      
      const now = new Date().toISOString();
      
      // Clean up the deal data to ensure no undefined values
      const cleanDealData = {
        title: dealData.title || '',
        value: Number(dealData.value) || 0,
        company: dealData.company || '',
        contact: {
          name: dealData.contact?.name || '',
          email: dealData.contact?.email || '',
          phone: dealData.contact?.phone || ''
        },
        stage: stage.id,  // Stage ID
        stageId: stage.id, // Keep both for backward compatibility
        stageName: stage.name, // Store stage name for display
        ownerId: dealData.ownerId || user.uid || '',
        ownerName: user.email || '',
        ownerPhotoURL: user.photoURL || '',
        customFields: Array.isArray(dealData.customFields) ? dealData.customFields : [],
        organizationId: user.organizationId,
        createdBy: dealData.createdBy || user.uid,
        createdAt: now,
        updatedAt: now,
        stageHistory: [{
          from: '',
          to: stage.id,
          toName: stage.name,
          timestamp: now
        }],
        archived: false
      };

      console.log('Adding new deal with validated stage data:', { 
        stageId: cleanDealData.stageId,
        stageName: cleanDealData.stageName,
        stage: stage,
        allStages: stages.map(s => ({ id: s.id, name: s.name }))
      });
      
      const docRef = await addDoc(collection(db, 'deals'), cleanDealData);
      set({ loading: false });
      return docRef.id;
    } catch (error: any) {
      console.error('Error adding deal:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  updateDeal: async (dealId, dealData) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      const dealRef = doc(db, 'deals', dealId);
      const currentDeal = get().deals.find(d => d.id === dealId);
      
      if (!currentDeal) {
        throw new Error('Deal not found');
      }

      // Handle the value field specifically
      const dealValue = typeof dealData.value === 'number' ? dealData.value : 0;
      console.log('KanbanStore value:', {
        dealDataValue: dealData.value,
        processedValue: dealValue,
        type: typeof dealValue
      });

      const updates = {
        ...dealData,
        updatedAt: new Date().toISOString(),
        value: dealValue
      } as Partial<Deal>;

      // When ownerId is provided, fetch and include owner details
      if (dealData.ownerId) {
        try {
          const ownerDocRef = doc(db, 'users', dealData.ownerId);
          const ownerDoc = await getDoc(ownerDocRef);
          if (ownerDoc.exists()) {
            const ownerData = ownerDoc.data() as UserProfile;
            const ownerUpdates: Pick<Deal, 'ownerId' | 'ownerName' | 'ownerPhotoURL'> = {
              ownerId: dealData.ownerId,
              ownerName: ownerData.displayName || ownerData.email || 'Unknown User',
              ownerPhotoURL: ownerData.photoURL || ''
            };
            Object.assign(updates, ownerUpdates);
          }
        } catch (err) {
          console.error('Error fetching owner details:', err);
        }
      }

      // Track stage changes
      if (dealData.stage && dealData.stage !== currentDeal.stage) {
        const stageChange: DealStageChange = {
          from: currentDeal.stage,
          to: dealData.stage,
          timestamp: new Date().toISOString()
        };
        const stageHistory = [...(currentDeal.stageHistory || []), stageChange];
        Object.assign(updates, { stageHistory });

        // Trigger workflow on stage change
        await triggerWorkflow({
          type: 'DEAL_STAGE_CHANGED',
          dealId,
          organizationId: currentDeal.organizationId,
          fromStage: currentDeal.stage,
          toStage: dealData.stage,
          deal: currentDeal
        });
      }

      console.log('Updating deal:', { dealId, updates });
      await updateDoc(dealRef, updates);
      set({ loading: false });
    } catch (error: any) {
      console.error('Error updating deal:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  deleteDeal: async (dealId) => {
    try {
      set({ loading: true, error: null });
      console.log('Deleting deal:', dealId);
      await deleteDoc(doc(db, 'deals', dealId));
      set({ loading: false });
    } catch (error: any) {
      console.error('Error deleting deal:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  moveDeal: async (dealId, fromStage, toStage) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      // Verify the target stage exists in the pipeline
      const pipelineDoc = await getDoc(doc(db, 'pipelines', user.organizationId));
      if (!pipelineDoc.exists()) {
        throw new Error('Pipeline not found');
      }

      const stages = pipelineDoc.data().stages;
      const targetStage = stages.find(s => s.id === toStage);
      if (!targetStage) {
        throw new Error('Target stage not found in pipeline');
      }

      const dealRef = doc(db, 'deals', dealId);
      const currentDeal = get().deals.find(d => d.id === dealId);
      
      if (!currentDeal) {
        throw new Error('Deal not found');
      }

      // Get all deals in the target stage to calculate new order
      const targetStageDeals = get().deals
        .filter(d => d.stageId === toStage)
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

      // Calculate the new order (place at the end of the target stage)
      const maxOrder = targetStageDeals.length > 0 
        ? Math.max(...targetStageDeals.map(d => d.order ?? 0))
        : -1;
      const newOrder = maxOrder + 1;

      const now = new Date().toISOString();
      const stageChange: DealStageChange = {
        from: fromStage,
        to: toStage,
        timestamp: now
      };

      const updates: Partial<Deal> = {
        stageId: toStage,
        stage: toStage,
        updatedAt: now,
        order: newOrder,
        stageHistory: [...(currentDeal.stageHistory || []), stageChange]
      };

      // Auto-archive when moved to lost
      if (toStage === 'Closed Lost') {
        updates.archived = true;
      }

      // Trigger workflow for stage change
      try {
        await triggerWorkflow({
          type: 'DEAL_STAGE_CHANGED',
          dealId,
          organizationId: user.organizationId,
          fromStage: fromStage,
          toStage: toStage,
          deal: currentDeal
        });
      } catch (error) {
        console.error('Error triggering workflow:', error);
      }

      console.log('Moving deal:', dealId, 'from', fromStage, 'to', toStage, 'with order:', newOrder);
      await updateDoc(dealRef, updates);
      set({ loading: false });
    } catch (error: any) {
      console.error('Error moving deal:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  restoreDeal: async (dealId, toStage) => {
    try {
      set({ loading: true, error: null });
      const { user } = useAuthStore.getState();
      if (!user?.organizationId) {
        throw new Error('User organization not found');
      }

      const dealRef = doc(db, 'deals', dealId);
      const currentDeal = get().deals.find(d => d.id === dealId);
      
      if (!currentDeal) {
        throw new Error('Deal not found');
      }

      const now = new Date().toISOString();
      const updates: Partial<Deal> = {
        archived: false,
        updatedAt: now,
        stageId: toStage,
        stage: toStage
      };

      // Add a stage change to history
      const stageChange: DealStageChange = {
        from: currentDeal.stage,
        to: toStage,
        timestamp: now
      };

      updates.stageHistory = [...(currentDeal.stageHistory || []), stageChange];

      // Trigger workflow for stage change
      try {
        await triggerWorkflow({
          type: 'DEAL_RESTORED',
          dealId,
          organizationId: user.organizationId,
          fromStage: currentDeal.stage,
          toStage: toStage,
          deal: currentDeal
        });
      } catch (error) {
        console.error('Error triggering workflow:', error);
      }

      console.log('Restoring deal:', dealId, 'to stage:', toStage);
      await updateDoc(dealRef, updates);
      set({ loading: false });
    } catch (error: any) {
      console.error('Error restoring deal:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  }
}));