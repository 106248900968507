import { create } from 'zustand';
import { 
  collection, 
  addDoc, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where,
  onSnapshot,
  orderBy
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { DealActivity } from '../types/deal';
import { useAuthStore } from './authStore';

interface ActivitiesStore {
  activities: DealActivity[];
  loading: boolean;
  error: string | null;
  fetchActivities: (dealId: string) => Promise<void>;
  addActivity: (activity: Partial<DealActivity>) => Promise<void>;
  updateActivity: (activityId: string, updates: Partial<DealActivity>) => Promise<void>;
  deleteActivity: (activityId: string) => Promise<void>;
  subscribeToAllActivities: () => () => void;
}

export const useActivitiesStore = create<ActivitiesStore>((set, get) => ({
  activities: [],
  loading: false,
  error: null,

  subscribeToAllActivities: () => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return () => {};

    const activitiesRef = collection(db, 'activities');
    const q = query(
      activitiesRef,
      where('organizationId', '==', user.organizationId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      try {
        const activities = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as DealActivity));
        set({ activities, loading: false });
      } catch (error) {
        console.error('Error in activities subscription:', error);
        set({ error: 'Failed to sync activities', loading: false });
      }
    }, (error) => {
      console.error('Activities subscription error:', error);
      set({ error: 'Failed to subscribe to activities', loading: false });
    });

    return unsubscribe;
  },

  fetchActivities: async (dealId) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const activitiesRef = collection(db, 'activities');
      const q = query(
        activitiesRef, 
        where('dealId', '==', dealId),
        where('organizationId', '==', user.organizationId)
      );
      const querySnapshot = await getDocs(q);
      
      const activities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as DealActivity));

      set({ activities, loading: false });
    } catch (error) {
      console.error('Error fetching activities:', error);
      set({ error: 'Failed to fetch activities', loading: false });
    }
  },

  addActivity: async (activity) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const activitiesRef = collection(db, 'activities');
      const newActivity = {
        ...activity,
        organizationId: user.organizationId,
        createdAt: new Date().toISOString(),
        createdBy: user.uid
      };

      await addDoc(activitiesRef, newActivity);
      set({ loading: false });
    } catch (error) {
      console.error('Error adding activity:', error);
      set({ error: 'Failed to add activity', loading: false });
    }
  },

  updateActivity: async (activityId, updates) => {
    try {
      set({ loading: true, error: null });
      const activityRef = doc(db, 'activities', activityId);
      const updatedData = {
        ...updates,
        lastEditedAt: new Date().toISOString()
      };

      await updateDoc(activityRef, updatedData);
      set({ loading: false });
    } catch (error) {
      console.error('Error updating activity:', error);
      set({ error: 'Failed to update activity', loading: false });
    }
  },

  deleteActivity: async (activityId) => {
    try {
      set({ loading: true, error: null });
      const activityRef = doc(db, 'activities', activityId);
      await deleteDoc(activityRef);
      set({ loading: false });
    } catch (error) {
      console.error('Error deleting activity:', error);
      set({ error: 'Failed to delete activity', loading: false });
    }
  }
}));
