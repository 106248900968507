import { create } from 'zustand';
import { db, auth } from '../lib/firebase';
import { 
  collection, 
  query, 
  where, 
  onSnapshot,
  doc,
  setDoc,
  getDoc
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import type { UserProfile, UserRole } from '../types/user';

interface TeamState {
  members: UserProfile[];
  loading: boolean;
  error: string | null;
  generateInviteCode: () => Promise<string>;
  validateInviteCode: (code: string) => Promise<{ organizationId: string; role: UserRole } | null>;
  subscribeToMembers: (organizationId: string) => () => void;
}

export const useTeamStore = create<TeamState>((set, get) => ({
  members: [],
  loading: false,
  error: null,

  subscribeToMembers: (organizationId: string) => {
    if (!organizationId) {
      console.log('No organization ID provided');
      return () => {};
    }

    console.log('Subscribing to members for organization:', organizationId);
    set({ loading: true, error: null });

    const q = query(
      collection(db, 'users'),
      where('organizationId', '==', organizationId)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const members = snapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Raw member data:', { id: doc.id, ...data });
          return {
            uid: doc.id,
            ...data,
            email: data.email || '',
            organizationId: data.organizationId || organizationId,
            role: data.role || 'member',
            displayName: data.displayName || data.email || 'Unknown User',
            photoURL: data.photoURL || '',
            createdAt: data.createdAt || new Date().toISOString(),
            updatedAt: data.updatedAt || new Date().toISOString()
          } as UserProfile;
        });

        console.log('Loaded team members:', members.map(m => ({
          uid: m.uid,
          email: m.email,
          displayName: m.displayName,
          role: m.role
        })));
        
        set({ members, loading: false, error: null });
      },
      (error) => {
        console.error('Error in members subscription:', error);
        set({ error: error.message, loading: false });
      }
    );

    return unsubscribe;
  },

  generateInviteCode: async () => {
    try {
      console.log('Starting generateInviteCode');
      set({ loading: true, error: null });
      
      // Get the current authenticated user directly from Firebase
      const currentUser = auth.currentUser;
      console.log('Current User:', currentUser ? currentUser.uid : 'No user');
      
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }

      // Fetch user data from Firestore to get organizationId and role
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        console.error('User document not found for UID:', currentUser.uid);
        throw new Error('User document not found');
      }

      const userData = userDoc.data();
      console.log('User Data:', userData);

      const organizationId = userData.organizationId;
      const role = userData.role;

      console.log('Organization ID:', organizationId);
      console.log('User Role:', role);

      if (!organizationId || !role || !['owner', 'admin'].includes(role)) {
        throw new Error('Insufficient permissions');
      }

      const code = nanoid(8).toUpperCase();
      const inviteRef = doc(db, 'inviteCodes', code);
      
      await setDoc(inviteRef, {
        organizationId,
        role: 'member',
        createdAt: new Date().toISOString(),
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days
        createdBy: currentUser.uid
      });

      console.log('Generated invite code:', code);
      set({ loading: false, error: null });
      return code;
    } catch (error: any) {
      console.error('Error generating invite code:', error);
      set({ error: error.message, loading: false });
      throw error;
    }
  },

  validateInviteCode: async (code: string) => {
    try {
      const inviteRef = doc(db, 'inviteCodes', code);
      const inviteDoc = await getDoc(inviteRef);

      if (!inviteDoc.exists()) {
        return null;
      }

      const inviteData = inviteDoc.data();
      const expiresAt = new Date(inviteData.expiresAt);

      if (expiresAt < new Date()) {
        // Invite code has expired
        return null;
      }

      return {
        organizationId: inviteData.organizationId,
        role: inviteData.role
      };
    } catch (error) {
      console.error('Error validating invite code:', error);
      return null;
    }
  }
}));