import { create } from 'zustand';
import { DealTask } from '../types/deal';
import { db } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  query, 
  where, 
  getDocs,
  orderBy,
  onSnapshot
} from 'firebase/firestore';
import { useAuthStore } from './authStore';

interface TasksStore {
  tasks: DealTask[];
  loading: boolean;
  error: string | null;
  fetchTasks: (dealId?: string) => Promise<void>;
  addTask: (task: Omit<DealTask, 'id' | 'createdAt' | 'createdBy'>) => Promise<void>;
  updateTask: (taskId: string, updates: Partial<DealTask>) => Promise<void>;
  deleteTask: (taskId: string) => Promise<void>;
  subscribeToAllTasks: () => () => void;
}

export const useTasksStore = create<TasksStore>((set, get) => ({
  tasks: [],
  loading: false,
  error: null,

  fetchTasks: async (dealId) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const tasksRef = collection(db, 'tasks');
      const constraints = [
        where('organizationId', '==', user.organizationId),
        orderBy('dueDate', 'asc')
      ];
      
      // Only add dealId constraint if it's provided
      if (dealId) {
        constraints.push(where('dealId', '==', dealId));
      }

      const q = query(tasksRef, ...constraints);
      const querySnapshot = await getDocs(q);
      const fetchedTasks = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dueDate: data.dueDate || null
        } as DealTask;
      });

      set({ tasks: fetchedTasks, loading: false });
    } catch (error) {
      console.error('Error fetching tasks:', error);
      set({ error: 'Failed to fetch tasks', loading: false });
    }
  },

  subscribeToAllTasks: () => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return () => {};

    const tasksRef = collection(db, 'tasks');
    const q = query(
      tasksRef,
      where('organizationId', '==', user.organizationId),
      orderBy('dueDate', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      try {
        const fetchedTasks = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            dueDate: data.dueDate || null
          } as DealTask;
        });
        set({ tasks: fetchedTasks, loading: false });
      } catch (error) {
        console.error('Error in tasks subscription:', error);
        set({ error: 'Failed to sync tasks', loading: false });
      }
    }, (error) => {
      console.error('Tasks subscription error:', error);
      set({ error: 'Failed to subscribe to tasks', loading: false });
    });

    return unsubscribe;
  },

  addTask: async (taskData) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const tasksRef = collection(db, 'tasks');
      const newTask = {
        ...taskData,
        organizationId: user.organizationId,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        updatedAt: new Date().toISOString(),
        status: 'pending',
        dueDate: taskData.dueDate || new Date().toISOString(),
        completed: false
      };

      await addDoc(tasksRef, newTask);
      set({ loading: false, error: null });
    } catch (error) {
      console.error('Error adding task:', error);
      set({ error: 'Failed to add task', loading: false });
    }
  },

  updateTask: async (taskId, updates) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const taskRef = doc(db, 'tasks', taskId);
      const updatedData = {
        ...updates,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(taskRef, updatedData);
    } catch (error) {
      console.error('Error updating task:', error);
      set({ error: 'Failed to update task', loading: false });
    }
  },

  deleteTask: async (taskId) => {
    const { user } = useAuthStore.getState();
    if (!user?.organizationId) return;

    try {
      set({ loading: true, error: null });
      const taskRef = doc(db, 'tasks', taskId);
      await deleteDoc(taskRef);
    } catch (error) {
      console.error('Error deleting task:', error);
      set({ error: 'Failed to delete task', loading: false });
    }
  }
}));
